import React, { Component } from "react";
import "./carousell.css";
import Element from "./element";

export class carousell extends Component {
  state = {
    current: null,
    dishes: [...this.props.dishes],
  };

  componentDidMount() {
    // console.log(this.state.dishes);
    if (this.state.dishes.length >= 1) {
      let current = null; //I don't think we need to make a copy.
      let start = null;
      this.state.dishes.forEach((dish, i) => {
        // console.log("DISH", dish);
        let newElement = new Element(null, null, dish.photo);
        // console.log("NEW ELE", newElement);
        if (i === 0) {
          start = newElement;
        }
        if (current) {
          current.next = newElement;
        }
        newElement.prev = current;
        current = newElement;
      });
      //first last config
      current.next = start;
      start.prev = current;
      // console.log("TEST REFERENTIAL", start, current);
      this.setState({ current: start });
    } else {
      console.log(
        "There is an error. No dishes seem to be there for the chef? "
      );
    }
  }

  goLeft = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ current: this.state.current.prev });
  };

  goRight = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ current: this.state.current.next });
  };

  render() {
    if (this.state.current) {
      return (
        <div className="encompass">
          <img
            className="food_card_img_background image"
            src={this.state.current.photo}
          />
          <i className="fas fa-chevron-left" onClick={this.goLeft}></i>
          <i className="fas fa-chevron-right" onClick={this.goRight}></i>
        </div>
      );
    } else {
      return (
        <div
          className="food_card_img_background image"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/landing/chef.png)`,
            backgroundSize: "contain",
          }}
        ></div>
      );
    }
  }
}

export default carousell;
