import React, { Component } from "react";
import axios from "axios";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import randomColor from "randomcolor";
import "./analytics.css";

export class analytics extends Component {
  state = {
    allDishesR: [],
    dishR: {}, //id to array
    dish: this.props.user.dishes[0],
    datasets: [],
    dishName: "",
    chefR: [],
    totalCount: null,
  };

  async componentDidMount() {
    this.chefRatings();
    this.dishRatingsAll();
    this.axiosOrderCount();
    let arr = [];
    let idarr = [];
    // console.log("dishes", this.props.user.dishes);
    for (const dish in this.props.user.dishes) {
      // console.log("dishes", dish);
      arr.push(this.dishRatings(this.props.user.dishes[dish]));
      idarr.push(this.props.user.dishes[dish]);
    }
    let mapping = [];
    let responses = await Promise.all(arr).catch((err) => console.log(err));
    //   .then((response) => {
    //     // console.log("RESPONNNNSEE", response);
    //   })

    // console.log("FROM PROMISE.ALL", responses);
    if (responses) {
      responses.forEach((response, i) => {
        let obj = {
          data: response.data.dataA,
          label: response.data.name,
          fill: false,
          backgroundColor: randomColor({
            luminosity: "light",
          }),
          borderColor: "rgba(0, 0, 0, 0)",
        };
        mapping.push(obj);
        // console.log("OBJ label + data", obj);
      });
    }
    // console.log("DATASETS", mapping);
    this.setState({ datasets: mapping });
  }

  axiosOrderCount = () => {
    axios({
      method: "get",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/orders/orderCount`,
      withCredentials: true,
    })
      .then((response) => {
        this.setState({ totalCount: response.data.data });
      })
      .catch((err) => console.log(err));
  };

  dishRatingsAll = () => {
    axios({
      method: "get",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/analytics/dishRatingsAll`,
      withCredentials: true,
    })
      .then((response) => {
        this.setState({ allDishesR: response.data.data });
      })
      .catch((err) => console.log(err));
  };
  dishRatings = (dishId) => {
    return axios({
      method: "get",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/analytics/dishRatings/${dishId}`,
      withCredentials: true,
    });
    //   .then((response) => {
    //     this.setState({ dishR: response.data.dataA });
    //   })
    //   .catch((err) =>  console.log(err));
  };

  chefRatings = () => {
    axios({
      method: "get",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/analytics/overallRatings`,
      withCredentials: true,
    })
      .then((response) => {
        // console.log("error respinse", response);
        let datasets = {
          data: response.data.dataA,
          label: "Overall Rating",
          fill: false,
          backgroundColor: "#2f45c5",
          borderColor: "#2f45c5",
        };
        let chefR = [...this.state.chefR];
        chefR.push(datasets);
        this.setState({ chefR });
      })
      .catch((err) => console.log(err));
  };

  options = {
    // plugins: {
    //   legend: {
    //     labels: {
    //       // This more specific font property overrides the global property
    //       font: {
    //         family: "landingFontTwo",
    //         size: 14,
    //       },
    //     },
    //   },
    // },
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          //   count: 5,
          stepSize: 1,
        },
        max: 5,
        min: 0,
        title: {
          display: true,
          text: "Rating Value",
          font: "landingFontTwo",
        },
      },
    },
  };

  priceData = {
    labels: ["Good Value", "Expensive"],
    datasets: [
      {
        data: [50, 50], //count
        backgroundColor: ["rgb(88, 255, 79)", "rgb(255, 113, 5)"],
        hoverBackgroundColor: ["rgb(74, 232, 65)", "rgb(214, 94, 2)"],
        hoverOffset: 4,
      },
    ],
  };

  render() {
    let data = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: this.state.datasets,
    };

    let dataChef = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: this.state.chefR,
    };
    return (
      <div
        className="container_nfs special_dash_color"
        style={{ color: "#fbfcff" }}
      >
        <h1 className="analytics_header">
          <b>Analytics</b>
        </h1>
        <h2 className="analytics_subheader">
          Make data oriented decisions to guide your cooking!
        </h2>
        <div className="flex_cont flex_wrap space_evenly">
          <div className="graph_bubble">
            <h1 className="analytics_title">
              <b>Dish Quality Monitoring</b>
            </h1>
            <Line data={data} options={this.options} />
          </div>
          <div className="graph_bubble">
            <h1 className="analytics_title">
              <b>Overall Rating</b>
            </h1>
            <Line data={dataChef} options={this.options} />
          </div>
        </div>
        <div className="flex_cont flex_wrap space_evenly">
          <div className="dish_list_bubble graph_bubble">
            <h1 className="analytics_title">
              <b>Current Average Dish Ratings</b>
            </h1>

            <div className="flex_cont">
              <p className="analytics_subheader special_colllor">
                <b>Name</b>
              </p>
              <p className="analytics_subheader special_colllor">
                <b>Rating</b>
              </p>
            </div>
            <hr></hr>
            {this.state.allDishesR.map((dish) => {
              return (
                <div className="flex_cont">
                  <div className="flex_cont">
                    <img src={dish.photo} alt="" className="bubble_dish_img" />
                    <p className="analytics_subheader">
                      <b>{dish.name}</b>
                    </p>
                  </div>
                  <div className="ratings_average">
                    {dish.ratingsAverage > 3.5 ? (
                      <p
                        style={{
                          fontFamily: "landingFontTwo",
                          color: "yellowgreen",
                        }}
                      >
                        {dish.ratingsAverage} ({dish.ratingsQuantity})
                      </p>
                    ) : dish.ratingsAverage > 2.5 ? (
                      <p
                        style={{
                          fontFamily: "landingFontTwo",
                          color: "orangered",
                        }}
                      >
                        {dish.ratingsAverage} ({dish.ratingsQuantity})
                      </p>
                    ) : (
                      <p
                        style={{
                          fontFamily: "landingFontTwo",
                          color: "red",
                        }}
                      >
                        {dish.ratingsAverage} ({dish.ratingsQuantity})
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div>
            <div className="mini_bubble_oc flex_cont space_evenly">
              <i
                class="fab fa-slack-hash"
                style={{ color: "yellowgreen", fontSize: "45px" }}
              ></i>
              <p className="analytics_subheader" style={{ fontSize: "25px" }}>
                Total Orders: {this.state.totalCount}
              </p>
            </div>
            <div className="price_comp_bar mini_bubble_oc">
              <h1 className="analytics_title">Is the Price Right?</h1>
              <div style={{ width: "80%", height: "50%", minWidth: "251px" }}>
                <Doughnut //can use totalCount and get one field to see which one is g for price.
                  data={this.priceData}
                  options={{
                    width: "100",
                    height: "100",
                    responsive: true,
                    maintainAspectRatio: true,
                    plugins: {
                      legend: {
                        display: true,
                        position: "right",
                      },
                    },
                  }}
                />
              </div>
              <p className="note">
                {" "}
                <i>COMING SOON</i>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default analytics;
