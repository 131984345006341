import React, { Component } from "react";
import GoogleLogin from "react-google-login";
import { Redirect } from "react-router-dom";
import "./resetPassword.css";
import axios from "axios";
import { Helmet } from "react-helmet";

export class resetPassword extends Component {
  state = {
    newPassword: "",
    confirmPassword: "",
    loading: false,
  };

  setPassword = (e) => {
    this.setState({ newPassword: e.target.value });
  };

  setConfirmPassword = (e) => {
    this.setState({ confirmPassword: e.target.value });
  };

  checkPasswords = () => {
    return this.state.newPassword === this.state.confirmPassword;
  };

  createPassword = () => {
    //this.props.match.params.tokenId
    //POST REQUEST HERE FOR PROTECTION with paramId for changing password and password
    if (!this.checkPasswords) {
      alert("Your passwords don't match");
      return;
    }
    this.setState({ loading: true });
    axios({
      method: "patch",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/users/resetPassword/${this.props.match.params.tokenId}`,
      data: {
        password: this.state.newPassword,
        passwordConfirm: this.state.confirmPassword,
      },
    }).then((response) => {
      // console.log(response);
      if (response.data.status === "success") {
        this.props.setUser(response.data.data.user);
        //MIGHT WANT TO ADD THE DELAY AND ALL
        alert("You succesfully changed your password!");
        this.setState({ loading: false });
        // <Redirect to="/" />; //not necessary should automatically render!
      } else {
        alert(
          "There was an issue regarding your password reset. Plase redo the process. Sorry!"
        );
        this.setState({ loading: false });
      }
    });

    //if success
    // this.props.setUser(user)
  };

  render() {
    return (
      <div className="login_container">
        <Helmet>
          <title>tizaar - Reset Password</title>
          <meta
            name="description"
            content="Reset your password with tizaar to get back to seeing what homecooked meals your neighbors are cooking!"
          />
        </Helmet>
        <div className="left_login_cont">
          <div className="main_login_cont">
            <h1 style={{ color: "#6d6d6d", fontFamily: "landingFontTwo" }}>
              Create your new password
            </h1>
            <div className="password_bubble_div bubble_login_div">
              <p>
                <b>New Password</b>
              </p>
              <input
                type="password"
                className="Please enter your new password"
                onChange={this.setPassword}
              />
            </div>
            <div className="confirm_password_bubble_div bubble_login_div">
              <p>
                <b>Confirm Password</b>
              </p>
              <div className="password_wrapper">
                <input
                  type="password"
                  className="password_input input_bubble_login"
                  onChange={this.setConfirmPassword}
                />
              </div>
            </div>
            <div className="submit_pass_btn" onClick={this.createPassword}>
              {!this.state.loading ? (
                <b>Create Password and Sign in</b>
              ) : (
                <b>loading...</b>
              )}
            </div>
            {/* <h1 className="welcome">Welcome to tizaar</h1> */}
          </div>
        </div>
        <div className="wrapper_login">
          <div
            className="right_login_cont image"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/landing/chef.png)`,
            }}
          ></div>
        </div>
      </div>
    );
  }
}

export default resetPassword;
