import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap";
import "./modal.css";

export class modal extends Component {
  state = {
    remarks: null,
    deliveryFee: null,
    open: false,
    loading: false,
  };

  componentDidMount() {
    // if (this.props.deliveryError) {
    //   this.setState({ open: true });
    // }
  }
  render() {
    return (
      <div
        tabIndex="-1"
        style={{ position: "relative", zIndex: "1050", display: "block" }}
      >
        <Button
          color={this.props.status ? "primary" : "danger"}
          onClick={() => {
            // if (!this.props.deliveryError) this.props.confirm(this.props.index);
            // console.log("I AM HERE AT CLICK", this.props.status, this.props);
            this.setState({ loading: true });
            if (this.props.status) {
              this.props.confirm(this.props.index);
            }
            this.setState({ open: !this.state.open, loading: false });
          }}
          style={{ marginBottom: "5px" }}
        >
          {this.props.status
            ? this.state.loading
              ? "Loading"
              : "Confirm"
            : "Reject"}
        </Button>
        {/* {(this.props.deliveryError && this.props.status) || */}
        {!this.props.status ? (
          <Modal
            isOpen={this.state.open}
            toggle={() => {
              // if (this.props.deliveryError)
              // this.props.set("deliveryError", null); //on close
              this.setState({ open: !this.state.open });
            }}
            className="confirmed_modal"
          >
            <ModalHeader
              toggle={() => {
                // if (this.props.deliveryError)
                // this.props.set("deliveryError", null); //on close
                this.setState({ open: !this.state.open });
              }}
            >
              {this.props.status}
            </ModalHeader>
            <ModalBody>
              <Input
                type={this.props.status ? "number" : "textarea"}
                placeholder={
                  this.props.status
                    ? "There was an error in organising delivery. Please input the delivery fee."
                    : "Please write to the buyer about why you cannot complete this order"
                }
                rows={5}
                value={
                  this.props.status
                    ? this.state.deliveryFee
                    : this.state.remarks
                }
                onChange={(e) => {
                  if (this.props.status) {
                    this.setState({ deliveryFee: e.target.value });
                    this.props.set("deliveryFee", e.target.value);
                  } else {
                    this.setState({ remarks: e.target.value });
                    this.props.set("remarks", e.target.value);
                  }
                }}
                className="wrapword"
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => {
                  this.setState({ open: false });
                  // if (this.props.deliveryError)
                  //   this.props.set("deliveryError", null); //on close
                  // console.log("@reject btn");
                  this.props.status
                    ? this.props.confirm(this.props.index)
                    : this.props.reject(this.props.index, this.state.remarks);
                }}
              >
                {this.props.status ? "Confirm" : "Confirm Cancellation"}
              </Button>{" "}
              <Button
                color="red"
                onClick={() => {
                  // if (this.props.deliveryError)
                  //   this.props.set("deliveryError", null); //on close

                  this.setState({
                    open: false,
                  });
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

export default modal;
