import React, { Component } from "react";
import axios from "axios";

import "./login.css";

export class emailInput extends Component {
  state = {
    email: "",
    loading: false,
  };

  setEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  sendForgotEmail = () => {
    this.setState({ loading: true });
    axios({
      method: "post",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/users/forgotPassword`,
      data: {
        email: this.state.email,
      },
    }).then((response) => {
      if (response.data.status === "success") {
        alert(
          "A link to reset your password will arrive to your email shortly. Please do this ASAP for security purposes."
        );
        this.setState({ loading: false });
      } else {
        alert("There was an issue regarding your login!");
      }
    });
  };

  render() {
    return (
      <div className="login_container">
        <div className="left_login_cont">
          <div className="main_login_cont">
            <h1 style={{ color: "#6d6d6d", fontFamily: "landingFontTwo" }}>
              FORGOT PASSWORD
            </h1>
            <div className="username_bubble_div bubble_login_div">
              <p>
                <b>Email</b>
              </p>
              <input
                type="email"
                className="Please enter your email."
                onChange={this.setEmail}
              />
            </div>
            <div className="login_btn" onClick={this.sendForgotEmail}>
              <b>
                {this.state.loading
                  ? "Loading..."
                  : "Send Password Reset Email"}
              </b>
            </div>
            {/* <h1 className="welcome">Welcome to tizaar</h1> */}
          </div>
        </div>
        <div className="wrapper_login">
          <div
            className="right_login_cont image"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/landing/chef.png)`,
            }}
          ></div>
        </div>
      </div>
    );
  }
}

export default emailInput;
