const axios = require("axios");
class PayMongo {
  constructor(order) {
    if (order) {
      this.name = order.name ? order.name : "Not provided";
      this.phone = order.chef.phone;
      this.chef_id = order.chef._id;
      this.order_id = order._id;
      this.deliveryFee = order.deliveryFee
        ? Math.round(order.deliveryFee * 1.031)
        : 0;
    }
    // this.email = email;
  }

  headers() {
    // console.log("env chekc", process.env.REACT_APP_PUBLIC_KEY_PAYMONGO);
    let key =
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_PUBLIC_KEY_PAYMONGO
        : "pk_test_gYgYmkvV8JxNA2N9UhjDMeQB";
    return {
      "Content-Type": "application/json",
      Authorization: `Basic ${Buffer.from(key).toString("base64")}`,
    };
  }

  //CARDS
  async createPaymentMethod(card_number, exp_month, exp_year, cvc) {
    let caught = false;
    // console.log("DELIVERY FEE", this.deliveryFee);
    // console.log(card_number, typeof card_number);
    let response = await axios({
      method: "post",
      url: "https://api.paymongo.com/v1/payment_methods",
      data: {
        data: {
          attributes: {
            type: "card",
            details: {
              card_number,
              exp_month,
              exp_year,
              cvc,
            },
            billing: {
              address: {
                line2: this.deliveryFee,
              },
              name: this.name, //`${this.order_id}-${this.user_id}`?
              phone: this.phone,
              email: "masterchef@tizaar.com",
            },
          },
        },
      },
      headers: this.headers(),
    }).catch((err) => {
      console.log("error in createPaymentMethod", err.response.data.errors);
      caught = true;
    });
    if (caught) return null;
    // console.log("res;", response.data.data);
    return response.data.data;
  }

  async getPaymentMethod(id) {
    let caught = false;
    let paymentMethod = await axios({
      method: "get",
      url: `https://api.paymongo.com/v1/payment_methods/${id}`,
      headers: this.headers(),
    }).catch((err) => {
      console.log("error in getPaymentMethod", err.response.data.errors);
      caught = true;
    });
    if (caught) return null;
    return paymentMethod.data.data;
  }

  async createPaymentIntent(amount) {
    // console.log(amount);
    let caught = false;
    let response = await axios({
      url: "https://api.paymongo.com/v1/payment_intents",
      method: "post",
      data: {
        data: {
          attributes: {
            amount: amount * 100,
            payment_method_allowed: ["card"],
            description: `${this.chef_id}`,
            statement_descriptor: "tizaar",
            currency: "PHP",
            metadata: { order_id: this.order_id },
          },
        },
      },
      headers: this.headers(),
    }).catch((err) => {
      console.log("err in create intent", err.response.data.errors);
      caught = true;
    });
    if (caught) return null;
    // // console.log("res;", response.data.data);
    return response.data.data;
  }

  async retrievePaymentIntent(id, client_key) {
    //when would i use client_key, if key used is public key meaning?
    let caught = false;
    let paymentIntent = await axios({
      method: "get",
      url: client_key
        ? `https://api.paymongo.com/v1/payment_intents/${id}?client_key=${client_key}`
        : `https://api.paymongo.com/v1/payment_intents/${id}`,
      headers: this.headers(),
    }).catch((err) => {
      console.log("error in getPaymentMethod", err.response.data.errors);
      caught = true;
    });
    if (caught) return null;
    return paymentIntent.data.data;
  }

  async paymentIntentAttach(pi_id, pm_id, client_key) {
    //redirect?
    let caught = false;
    let error = null;
    let response = await axios({
      method: "post",
      url: `https://api.paymongo.com/v1/payment_intents/${pi_id}/attach`,
      data: client_key
        ? {
            data: {
              attributes: {
                payment_method: pm_id,
                client_key: client_key,
              },
            },
          }
        : {
            data: {
              attributes: {
                payment_method: pm_id,
              },
            },
          },
      headers: this.headers(),
    }).catch((err) => {
      console.log(
        "There was an error in the payment intent attachment",
        err.response.data.errors
      );
      caught = true;
      error = err.response.data.errors
        ? err.response.data.errors[0].detail
        : "There was an error in processing the payment";
    });
    // console.log("response", error, response);
    if (caught) {
      return { error: true, data: error };
    }
    return { error: false, data: response.data.data };
  }
  //CARDS END
  //E-WALLETS BEGIN
  async createSource(source, amount) {
    //I think url returned to on click we can redirect
    let caught = false;
    let error = null;
    let response = await axios({
      method: "post",
      url: "https://api.paymongo.com/v1/sources",
      data: {
        data: {
          attributes: {
            type: source,
            amount: amount * 100,
            currency: "PHP",
            redirect: {
              success: "https://tizaar.com/cart?payed=true",
              failed: `https://tizaar.com/pay/${this.order_id}?error=true`,
            },
            billing: {
              name: this.name,
              phone: this.phone,
              email: "masterchef@tizaar.com",
              address: {
                line1: this.order_id,
                line2: this.deliveryFee,
              },
            },
          },
        },
      },
      headers: this.headers(),
    }).catch((err) => {
      console.log(
        "There was an error in creating the source",
        err.response.data.errors
      );
      caught = true;
      error = err.response.data.errors
        ? err.response.data.errors[0].detail
        : "There was an error in processing the payment";
    });
    if (caught) return { error: true, data: error };
    return { error: false, data: response.data.data };
  }

  async retrieveSource(id) {
    let caught = false;
    let response = await axios({
      method: "get",
      url: `https://api.paymongo.com/v1/sources/${id}`,
      headers: this.headers(),
    }).catch((err) => {
      console.log("error in retrieving the source", err.response.data.errors);
      caught = true;
    });
    if (caught) return null;
    return response.data.data;
  }

  //Create payment once chargable source
  //If the payment status is failed, you can determine the reason for failure. --> Maybe this is in the response so you can check here?
  async createSourcePayment(amount, id, chef_id) {
    let caught = false;
    let response = await axios({
      method: "post",
      url: "https://api.paymongo.com/v1/payments",
      data: {
        data: {
          attributes: {
            amount: amount * 100,
            description: `${chef_id}`,
            currency: "PHP",
            statement_descriptor: "tizaar",
            source: {
              id,
              type: "source",
            },
          },
        },
      },
      headers: this.headers(),
    }).catch((err) => {
      console.log(
        "error in creating a payment for the source",
        err.response.data.errors
      );
      caught = true;
    });
    if (caught) return null;
    return response.data.data;
  } //type as gcash or j source?

  async getSourcePayment(id) {
    let caught = false;
    let response = await axios({
      method: "get",
      url: `https://api.paymongo.com/v1/payments/${id}`,
      headers: this.headers(),
    }).catch((err) => {
      console.log(
        "error in retrieving the source payment",
        err.response.data.errors
      );
      caught = true;
    });
    if (caught) return null;
    return response.data.data;
  }

  async createWebhook(req, res) {
    let caught = false;
    let response = await axios({
      method: "post",
      url: "https://api.paymongo.com/v1/webhooks",
      headers: this.headers(),
      data: {
        data: {
          attributes: {
            url:
              process.env.NODE_ENV === "production"
                ? "https://api.tizaar.com/api/v1/orders/webhook/source"
                : `${"https://9c78-112-206-123-157.ngrok.io"}/api/v1/orders/webhook/source`,
            events: ["source.chargeable", "payment.paid", "payment.failed"],
          },
        },
      },
    }).catch((err) => {
      console.log("webhook creation error", err.response.data.errors);
      caught = true;
    });
    if (caught)
      return res.status(400).json({ message: "error in creating webhook" });
    res.status(200).json({
      status: "successful webhook creation",
    });
  }

  async webhookList(req, res) {
    let caught = false;
    let response = await axios({
      method: "get",
      url: "https://api.paymongo.com/v1/webhooks",
      headers: this.headers(),
    }).catch((err) => {
      console.log("webhook creation error", err.response.data.errors);
      caught = true;
    });
    if (caught)
      return res.status(400).json({ message: "error in creating webhook" });
    res.status(200).json({
      status: "successful webhook creation",
      data: response.data.data,
    });
  }

  async disableWebhook(req, res) {
    let caught = false;
    let response = await axios({
      method: "post",
      url: `https://api.paymongo.com/v1/webhooks/${req.body.id}/disable`,
      headers: this.headers(),
    }).catch((err) => {
      console.log("webhook disable error", err.response.data.errors);
      caught = true;
    });
    if (caught)
      return res.status(400).json({ message: "error in creating webhook" });
    res.status(200).json({
      status: "successful webhook creation",
      data: response.data.data,
    });
  }
}

//I think only need to create response not important

export default PayMongo;
