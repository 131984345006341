import React, { Component } from "react";
import { Redirect, NavLink } from "react-router-dom";
import creditCardType from "credit-card-type";
import Loader from "react-loader-spinner";
import PayMongo from "../../util/payMongo";
import { Helmet } from "react-helmet";
import axios from "axios";
import "./finalCheckout.css";

export class finalCheckout extends Component {
  //require login?
  state = {
    pendingOrder: null,
    loading: true,
    type: "gcash",
    creditType: "",
    credit_card: "",
    exp_month: null,
    exp_year: null,
    cvc: null,
    deliveryFee: null,
    creditLoading: false,
    authUrl: null,
    alert: false,
    creditUrl: null,
    successRedirect: null,
    pi_id: null,
    client_key: null,
    alertError: null,
    delivLoading: false,
    oosRedirect: null,
  };

  componentDidMount() {
    if (this.props.user) {
      this.props.setHistoryLink(null);
    }
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let error = params.get("error");
    let self = this;
    this.mongoWindowListener();
    if (error) {
      this.setState({ alert: true });
      setTimeout(function () {
        self.setState({ alert: false });
      }, 5000);
    }
    if (this.props.user) this.getOrder(); //otherwise redirects in any case
  }

  /*AUTH WINDOW LISTENER PAYMONGO*/
  mongoWindowListener = async () => {
    let self = this;
    window.addEventListener(
      "message",
      (ev) => {
        if (ev.data === "3DS-authentication-complete" && self.state.pi_id) {
          // 3D Secure authentication is complete. You can requery the payment intent again to check the status.
          axios
            .get(
              `https://api.paymongo.com/v1/payment_intents/${self.state.pi_id}?client_key=${self.state.client_key}`,
              {
                headers: new PayMongo(null).headers(),
              }
            )
            .then(function (response) {
              var paymentIntent = response.data.data;
              var paymentIntentStatus = paymentIntent.attributes.status;
              // // console.log(self);
              self.attachResponseChecks(paymentIntentStatus, paymentIntent);
            })
            .catch((err) => console.log(err));
        }
      },
      false
    );
  };

  getOrder = () => {
    axios({
      method: "get",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/orders/${this.props.match.params.id}`,
      withCredentials: true,
    })
      .then(async (response) => {
        // console.log("response", response);
        await this.setState({
          pendingOrder: response.data.data.order,
          loading: false,
        });
        this.getDeliveryQuote();
      })
      .catch((err) => {
        alert(
          "there was an error in getting the payment page for the order, please refresh the page."
        );
      });
  };

  getDeliveryQuote = () => {
    //could be losing or gaining money as order made in advance
    this.setState({ delivLoading: true });
    /*SPEEDY CODE*/
    axios({
      method: "get",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/orders/delivery/${this.state.pendingOrder._id}`,
      withCredentials: true,
    })
      .then((response) => {
        // console.log("DATA", response.data.data);
        this.setState({ deliveryFee: response.data.data, delivLoading: false });
      })
      .catch(
        this.setState({
          deliveryFee: "There was an error calculating the delivery fee",
        })
      );

    /* LALAMOVE CODE
    let lala = new Lalamove();
    let quote = lala.getQuotation(this.state.pendingOrder, this.props.user);
    let pendingOrder = { ...this.state.pendingOrder };
    pendingOrder.deliveryFee = quote.totalFee;
    pendingOrder.deliveryCurrency = quote.totalFeeCurrency;
    this.setState({ pendingOrder });*/
  };

  formatNumber = (number) => {
    let str = "";
    var i = 0;
    // // console.log(str, number);
    while (i < number.length) {
      str = str + number.charAt(i);
      if ((i + 1) % 4 === 0) {
        str = str + " ";
      }
      //   // console.log(str);
      i = i + 1;
    }
    return str;
  };

  convertTime = (time) => {
    time =
      this.state.pendingOrder.deliveryMode === "delivery" ? time + 1 : time;
    let timeCopy = time;
    let timeTo = timeCopy + 2;
    let stamp = "am";
    let stampCopy = "am";
    if (time >= 12) {
      stamp = "pm";
      if (time > 12) timeCopy = time - 12;
    }
    if (timeTo >= 12) {
      stampCopy = "pm";
      if (timeTo > 12) timeTo = timeTo - 12;
    }
    return `${timeCopy}${stamp} - ${timeTo}${stampCopy}`;
  };

  orderTotal = () => {
    let total = 0;
    let rewards =
      this.props.user && this.props.user.rewards ? this.props.user.rewards : 0;
    // // console.log(order.dishList);
    this.state.pendingOrder.dishList.forEach((dish) => {
      let price = this.state.pendingOrder.chef.promo
        ? Math.round(dish.dish.price * (1 - this.state.pendingOrder.chef.promo))
        : dish.dish.price;
      let quantity = dish.quantity;
      total = total + quantity * price - rewards;
    });
    if (this.state.pendingOrder.deliveryMode === "delivery") {
      // console.log("delivery Fee", total, total + this.state.deliveryFee * 1);
      total = total + Math.round(this.state.deliveryFee * 1.031);
    }
    if (this.state.type === "visa" || this.state.type === "mastercard") {
      // console.log("service Fee", total, total + 15);
      total = total + 15;
    } else {
      // console.log("service Fee", total, total + 5);
      total = total + 5;
    }
    // console.log("TOTAL", total);
    return total;
  };

  componentDidUpdate() {
    if (this.state.authUrl) {
      window.location.assign(this.state.authUrl);
    }
  }

  /*PAYMENT FUNCTIONS*/

  /*PAYMENT FUNCTIONS*/

  walletAuth = async () => {
    //wont charge if no auth so all g as long as on click that's the one they wanted.
    let error = false;
    let paymongo = new PayMongo(this.state.pendingOrder);
    let amount = this.orderTotal();
    // console.log(this.state.type, typeof this.state.type);
    let response = await paymongo
      .createSource(this.state.type, amount)
      .catch((err) => {
        error = true;
        alert(
          "There was an error in creating the payment. Please try again later."
        );
        console.log("error in create source", err);
      });
    // console.log(response);
    if (response.error) {
      let self = this;
      this.setState({
        alertError: response.data,
      });
      setTimeout(function () {
        self.setState({
          alertError: null,
        });
      }, 5000);
    } else {
      this.setState({
        authUrl: response.data.attributes.redirect.checkout_url,
      });
      // console.log(this.state.authUrl);
    }
  };

  creditAuth = async () => {
    try {
      let error = false;
      let paymongo = new PayMongo(this.state.pendingOrder);
      let credit_card = this.state.credit_card.trim();
      let amount = this.orderTotal();
      let methodResponse = await paymongo
        .createPaymentMethod(
          credit_card,
          this.state.exp_month,
          this.state.exp_year,
          this.state.cvc
        )
        .catch((err) => {
          error = true;
          alert(
            "There was an error in creating the payment. Please try again and refresh the page."
          );
          console.log("error in create method", err);
        });
      let intentResponse = await axios({
        method: "post",
        url: `${
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_URL
            : "http://localhost:8080"
        }/api/v1/orders/paymentIntentTest`,
        withCredentials: true,
        data: {
          order: this.state.pendingOrder,
          amount: amount,
        },
      }).catch((err) => {
        error = true;
        alert(
          "There was an error in creating the payment. Please try again later."
        );
        console.log("error in create payment intent", err);
      });
      intentResponse = intentResponse.data.data;
      // console.log("CLIENT_KEY: ", intentResponse.attributes.client_key);
      // let intentResponse = await paymongo
      //   .createPaymentIntent(amount)
      //   .catch((err) => {
      //     error = true;
      //     alert(
      //       "There was an error in creating the payment. Please try again later."
      //     );
      //      console.log("error in create payment intent", err);
      //   });
      // let attachResponse = await axios({
      //   method: "post",
      //   url: `${
      //     process.env.NODE_ENV === "production"
      //       ? process.env.REACT_APP_URL
      //       : "http://localhost:8080"
      //   }/api/v1/orders/attachPaymentIntent`,
      //   withCredentials: true,
      //   data: {
      //     pi_id: intentResponse._id,
      //     pm_id: methodResponse._id,
      //     client_key: intentResponse.attributes.client_key,
      //     order: this.state.pendingOrder,
      //   },
      // }).catch((err) => {
      //   error = true;
      //   alert(
      //     "There was an error in creating the payment. Please try again later."
      //   );
      //   // console.log("error in attaching payment intent", err);
      // });
      // console.log("pi_id CHECK: ---------", intentResponse.id);
      console.log("method: ", methodResponse, "intent", intentResponse);
      await this.setState({
        pi_id: intentResponse.id,
        client_key: intentResponse.attributes.client_key,
      });
      let attachResponse = await paymongo.paymentIntentAttach(
        intentResponse.id,
        methodResponse.id,
        intentResponse.attributes.client_key
      );
      // console.log("ATTACH RESPONSE", attachResponse.error, attachResponse.data);
      if (attachResponse.error) {
        let self = this;
        this.setState({
          alertError: attachResponse.data,
        });
        setTimeout(function () {
          self.setState({
            alertError: null,
          });
        }, 5000);
      } else {
        // // console.log("ATTACH: ", attachResponse.data);
        await this.attachResponseChecks(
          attachResponse.data.attributes.status,
          attachResponse.data
        );
      }
    } catch (err) {
      console.log("err reached");
      this.setState({
        alertError:
          "We encountered an error. Please re-check to make sure all your credit card info is correct. Sorry!!! ",
      });
      let self = this;
      setTimeout(function () {
        self.setState({
          alertError: null,
        });
      }, 5000);
    }
    // this.attachResponseChecks(
    //   attachResponse.data.attributes.status,
    //   attachResponse.data
    // );
  };

  attachResponseChecks = async (status, attachResponse) => {
    let self = this;
    if (status === "paid" || status === "succeeded") {
      this.setState({
        successRedirect: "/cart?payed=true",
      });
      return;
    } else if (status === "awaiting_next_action") {
      this.setState({
        creditUrl: attachResponse.attributes.next_action.redirect.url,
      });
      return;
    } else if (status === "awaiting_payment_method") {
      console.log(
        attachResponse.attributes.last_payment_error,
        attachResponse.attributes
      );
      this.setState({
        alertError: `There was an error in processing the payment: ${
          attachResponse.attributes.last_payment_error
            ? attachResponse.attributes.last_payment_error.failed_message
            : ""
        }`,
      });
      setTimeout(function () {
        self.setState({ alertError: null });
      }, 5000);
      return;
    } else if (status === "processing") {
      // let self = this;
      // setTimeout(async function () {
      //   let response = await self.queryPaymentIntent(
      //     this.state.pi_id,
      //     this.state.client_key
      //   );
      //   self.attachResponseChecks(response.attributes.status, response);
      // }, 2200);
      let response = await self.queryPaymentIntent(
        this.state.pi_id,
        this.state.client_key
      );
      await this.attachResponseChecks(response.attributes.status, response);
      return;
    }
  };

  queryPaymentIntent = async (pi_id, client_key) => {
    let paymongo = new PayMongo(this.state.pendingOrder);
    // let axios = await axios({
    //   method: "get",
    //   url: `${
    //     process.env.NODE_ENV === "production"
    //       ? process.env.REACT_APP_URL
    //       : "http://localhost:8080"
    //   }/api/v1/orders/paymentIntentTest/${pi_id}/${client_key}`,
    // });
    // axios = axios.data;
    // console.log("AT QUERY", pi_id, client_key);
    let response = await paymongo.retrievePaymentIntent(pi_id, client_key);
    return response;
  };

  checkInStock = async () => {
    let oos = false;
    let response = await axios({
      method: "post",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/orders/confirmStock`,
      withCredentials: true,
      data: {
        order: this.state.pendingOrder,
      },
    }).catch((err) => console.log(err));
    if (response.data.status !== "success") {
      oos = true;
      let self = this;
      await this.setState({
        oosRedirect: response.data.message,
        alertError: response.data.message,
      });
      setTimeout(function () {
        self.setState({
          alertError: null,
        });
      }, 5000);

      // alert(response.data.message);
    }
    return oos;
  };

  authorisedClicked = async () => {
    this.setState({ creditLoading: true });
    let oos = await this.checkInStock().catch((err) => console.log(err));
    if (oos) {
      this.setState({ creditLoading: false });
      return;
    }
    if (this.state.type === "gcash" || this.state.type === "grab_pay") {
      await this.walletAuth();
    } else if (this.state.type === "visa" || this.state.type === "mastercard") {
      await this.creditAuth();
    }
    this.setState({ creditLoading: false });
  };

  render() {
    if (!this.props.user) {
      this.props.setHistoryLink(`/pay/${this.props.match.params.id}`);
      // console.log("payment link is histo link");
      return <Redirect to="/login" />;
    }
    if (this.state.successRedirect) {
      return <Redirect to={this.state.successRedirect} />;
    }

    if (!this.state.loading && !this.state.delivLoading) {
      if (this.state.pendingOrder.payed) {
        //fine because takes time to update
        return (
          <div
            className="container_nfs flex_cont fullheight"
            // onClick={this.setState({ creditUrl: null })}
          >
            <Helmet>
              <title>tizaar: Order Form</title>
              <meta
                name="description"
                content="Tickle your taste buds at tizaar's food bazaar and unconver hidden food delights right in your very neighborhood!"
              />
            </Helmet>{" "}
            <h1
              style={{
                textAlign: "center",
                fontFamily: "landingFontTwo",
                width: "100%",
              }}
            >
              This order has already been payed for!
            </h1>
          </div>
        );
      } else {
        return (
          <div
            className="container_nfs border-square flex_cont"
            // onClick={this.setState({ creditUrl: null })}
          >
            {this.state.oosRedirect ? (
              <div className="flex_cont_column form_redirect">
                <div className="close_button_form">
                  <i
                    class="fas fa-times"
                    style={{ color: "red" }}
                    onClick={() => {
                      this.setState({ oosRedirect: null });
                    }}
                  ></i>
                </div>
                <p
                  style={{
                    fontFamily: "landingFontTwo",
                    textAlign: "center",
                    marginBottom: "10px",
                    width: "69%",
                  }}
                >
                  <b>{this.state.oosRedirect}</b>
                </p>
                <NavLink to={`/menu/${this.state.pendingOrder.chef._id}`}>
                  <div className="redirect_button">Edit & Change Order</div>
                </NavLink>
              </div>
            ) : null}
            <Helmet>
              <title>tizaar: Order Form</title>
              <meta
                name="description"
                content="Tickle your taste buds at tizaar's food bazaar and unconver hidden food delights right in your very neighborhood!"
              />
            </Helmet>{" "}
            {this.state.alertError ? (
              <div className="error_alert_fc">{this.state.alertError}</div>
            ) : (
              <div></div>
            )}
            {this.state.creditUrl ? (
              <div className="modal_view">
                <i
                  className="fas fa-window-close window_close_fc"
                  onClick={() => {
                    this.setState({ creditUrl: null });
                  }}
                ></i>
                <iframe
                  className="iframe_fc"
                  src={this.state.creditUrl}
                  frameBorder="0"
                ></iframe>
              </div>
            ) : (
              <div></div>
            )}
            {this.state.alert ? (
              <div className="error_alert">
                There was an error in authorising/processing the payment
              </div>
            ) : (
              <div></div>
            )}
            <div className="container_lhs_fc">
              <h1 style={{ marginBottom: "30px" }}>
                <b>Your order from chef {this.state.pendingOrder.chef.name}</b>
              </h1>
              {/* {this.state.pendingOrder.dishList.map((dish) => {
              return (
                <div className="dishlist flex_center flex_fc">
                  <div
                    style={{ backgroundImage: `url(${dish.dish.photo})` }}
                    alt=""
                    className="dish_image_r image shorten_box"
                  >
                    <div className="hover_tag">
                      <p className="name">Name: {dish.dish.name}</p>
                      <p className="quantity">Quantity: {dish.quantity}</p>
                    </div>
                  </div>
                </div>
              );
            })} */}
              <div className="flex_cont" style={{ marginBottom: "10px" }}>
                <p>
                  <b>Delivery Time:</b>{" "}
                  {this.convertTime(this.state.pendingOrder.selectedTime)}
                </p>
                <p>
                  <b>Phone Contact:</b> {this.state.pendingOrder.phone}
                </p>
              </div>
              {/* <p> TODO: CHECK!
                <b>Assortment Flavor/Type:</b>{" "}
                {this.state.pendingOrder.chefNote}
              </p> */}
              <p>
                <b>Actual Date:</b> {this.state.pendingOrder.actualDate}
              </p>
              <p>
                <b>
                  {this.state.pendingOrder.deliveryMode === "delivery"
                    ? "Delivery Address:"
                    : "Pickup Address: "}
                </b>{" "}
                {this.state.pendingOrder.deliveryMode === "delivery"
                  ? this.state.pendingOrder.address
                  : this.state.pendingOrder.chef.address}
              </p>
              <h1 style={{ marginTop: "20px" }}>
                <b>Select a payment method</b>
              </h1>
              <div className="flex_center payment_methods">
                <div
                  className={
                    this.state.type === "gcash"
                      ? "payment_cont gcash_cont active"
                      : "payment_cont gcash_cont"
                  }
                  onClick={() => {
                    this.setState({ type: "gcash" });
                  }}
                >
                  <img
                    src="https://tizaar-foodbazaar.s3-ap-southeast-1.amazonaws.com/gcash.png"
                    alt=""
                    className="payment_logos"
                  />
                  <p>5P service charge</p>
                </div>
                <div
                  className={
                    this.state.type === "grab_pay"
                      ? "payment_cont grab_cont active"
                      : "payment_cont grab_cont"
                  }
                  onClick={() => {
                    this.setState({ type: "grab_pay" });
                  }}
                >
                  <img
                    src="https://tizaar-foodbazaar.s3-ap-southeast-1.amazonaws.com/grabpay.png"
                    alt=""
                    className="payment_logos grab_pay"
                  />
                  <p>5P service charge</p>
                </div>
                <div
                  className={
                    this.state.type === "mastercard"
                      ? "payment_cont active"
                      : "payment_cont"
                  }
                  onClick={() => {
                    this.setState({
                      type: "mastercard",
                      creditType: "mastercard",
                    });
                  }}
                >
                  <img
                    src="https://tizaar-foodbazaar.s3-ap-southeast-1.amazonaws.com/mastercard.png"
                    alt=""
                    className="payment_logos master_card"
                  />
                  <p>15P service fee</p>
                </div>
                <div
                  className={
                    this.state.type === "visa"
                      ? "payment_cont visa_cont active"
                      : "payment_cont visa_cont"
                  }
                  onClick={() => {
                    this.setState({ type: "visa", creditType: "visa" });
                  }}
                >
                  <img
                    src="https://tizaar-foodbazaar.s3-ap-southeast-1.amazonaws.com/visa.png"
                    alt=""
                    className="payment_logos visa"
                  />
                  <p>15P service fee</p>
                </div>
              </div>
            </div>
            <div className="container_rhs_fc">
              <div className="flex_cont center">
                {this.state.type === "mastercard" ||
                this.state.type === "visa" ? (
                  <form className="payment_detail_checkout_fc">
                    <div>
                      <h2>Card Number</h2>
                      <div className="card_detail_fc">
                        {this.state.creditType === "visa" ? (
                          <i className="fab fa-cc-visa"></i>
                        ) : this.state.creditType === "mastercard" ? (
                          <i className="fab fa-cc-mastercard"></i>
                        ) : this.state.creditType === "american-express" ? (
                          <i className="fab fa-cc-amex"></i>
                        ) : this.state.creditType === "jcb" ? (
                          <i className="fab fa-cc-jcb"></i>
                        ) : (
                          <i className="far fa-credit-card"></i>
                        )}
                        {/* <label htmlFor={"cardnumber"}>
                      {this.formatNumber(this.state.credit_card)}
                    </label> */}
                        <input
                          type="text"
                          className="card_number_fc"
                          placeholder="1234 1234 1234"
                          name="cardnumber"
                          id="cardnumber"
                          value={this.formatNumber(this.state.credit_card)}
                          onChange={async (e) => {
                            // console.log(this.state.type);
                            let str =
                              e.target.value.length > 3
                                ? e.target.value.substring(0, 3)
                                : e.target.value;
                            //check for case of taking out space
                            if (
                              e.target.value.split(" ").join("") ===
                              this.state.credit_card
                            ) {
                              e.target.value = e.target.value.substring(
                                0,
                                e.target.value.length - 1
                              );
                            }
                            await this.setState({
                              creditType:
                                creditCardType(str)[0] !== undefined
                                  ? creditCardType(str)[0].type
                                  : this.state.type,
                              credit_card: e.target.value.split(" ").join(""),
                            });
                            console.log(
                              this.state.creditType,
                              this.state.credit_card,
                              creditCardType(str)
                            );
                            if (e.target.value.length > e.target.maxLength) {
                              e.target.value = e.target.value.slice(
                                0,
                                e.target.maxLength
                              );
                            }
                          }}
                          maxLength={19}
                          required
                        />
                      </div>
                    </div>
                    <div className="card_detail_cvv_fc">
                      <div className="card_detail_cvv">
                        <h2>CVV</h2>
                        <input
                          type="number"
                          className="card_cvv_fc"
                          placeholder="123"
                          maxLength={3}
                          name="cvc"
                          onChange={(e) => {
                            if (e.target.value.length > e.target.maxLength) {
                              e.target.value = e.target.value.slice(
                                0,
                                e.target.maxLength
                              );
                            }
                            this.setState({ cvc: e.target.value });
                          }}
                          required
                        />
                      </div>
                      <div className="div">
                        {" "}
                        <h2>Expiration Date</h2>
                        <div className="exp-wrapper">
                          <input
                            autoComplete="cc-exp-month"
                            className="exp"
                            id="month"
                            maxLength="2"
                            name="ccmonth"
                            pattern="[0-9]*"
                            inputMode="numerical"
                            placeholder="MM"
                            type="text"
                            data-pattern-validate
                            required
                            onChange={(e) => {
                              this.setState({ exp_month: e.target.value * 1 });
                            }}
                          />
                          <input
                            autoComplete="cc-exp-year"
                            className="exp"
                            id="year"
                            name="ccyear"
                            maxLength="2"
                            pattern="[0-9]*"
                            inputMode="numerical"
                            placeholder="YY"
                            type="text"
                            data-pattern-validate
                            required
                            onChange={(e) => {
                              this.setState({ exp_year: e.target.value * 1 });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="" style={{ padding: "20px 40px" }}>
                {this.state.pendingOrder.dishList.map((dish, i) => {
                  let priceD = this.state.pendingOrder.chef.promo
                    ? Math.round(
                        dish.dish.price *
                          (1 - this.state.pendingOrder.chef.promo)
                      )
                    : dish.dish.price;
                  return (
                    <div
                      className="dish_item_list_checkout dish_item_list_checkout_fc"
                      key={i}
                    >
                      <div
                        className="dish_photo_checkout image .dish_photo_checkout_fc"
                        style={{ backgroundImage: `url(${dish.dish.photo})` }}
                      ></div>
                      <div className="dish_data_checkout">
                        <h1 className="dish_name_checkout dish_name_checkout_fc">
                          {dish.quantity}*{dish.dish.name}
                        </h1>
                      </div>
                      {dish.note ? ( //TODO: CHECK!
                        <p>Assortment Note: {dish.note}</p>
                      ) : null}
                      <h1 style={{ marginLeft: "20px", fontFamily: "p-font" }}>
                        <b>{priceD * dish.quantity}P</b>
                      </h1>
                    </div>
                  );
                })}
                <div className="order_calc" style={{ padding: "40px 0px" }}>
                  {this.state.pendingOrder.deliveryMode === "delivery" ? (
                    <div className="service_fee delivery_fee">
                      <p>
                        DELIVERY FEE:{" "}
                        <b>{Math.round(1.031 * this.state.deliveryFee)}P</b>
                      </p>
                      {/* <p className="note">*Not included in total price below</p> */}
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div className="service_fee">
                    {this.state.type === "visa" ||
                    this.state.type === "mastercard" ? (
                      <div className="flex_cont">
                        <p style={{ textAlign: "left", fontSize: "20px" }}>
                          SERVICE FEE:
                        </p>
                        <p style={{ textAlign: "right", fontSize: "20px" }}>
                          15P
                        </p>
                      </div>
                    ) : (
                      <div className="flex_cont">
                        <p style={{ textAlign: "left", fontSize: "20px" }}>
                          SERVICE FEE:
                        </p>
                        <p style={{ textAlign: "right", fontSize: "20px" }}>
                          5P
                        </p>
                      </div>
                    )}
                    {this.props.user && this.props.user.rewards ? (
                      <div className="flex_cont">
                        <p style={{ textAlign: "left", fontSize: "20px" }}>
                          REWARDS:
                        </p>
                        <p style={{ textAlign: "right", fontSize: "20px" }}>
                          {this.props.user.rewards}P
                        </p>
                      </div>
                    ) : null}
                    <hr></hr>
                    <div className="flex_cont total_price_fc">
                      <p style={{ textAlign: "left", fontSize: "20px" }}>
                        TOTAL:
                      </p>
                      <p style={{ textAlign: "right", fontSize: "20px" }}>
                        {this.orderTotal()}P
                      </p>
                    </div>
                    <div
                      className="checkout_btn"
                      style={{ fontFamily: "section-font" }} // zIndex: "1000"
                      onClick={this.authorisedClicked}
                    >
                      {this.state.creditLoading ? (
                        <Loader
                          type="Puff"
                          color="#FFFF00"
                          height={20}
                          width={20}
                        />
                      ) : (
                        <b>Authorise & Pay {this.orderTotal()}P</b>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="fullheight">
          <div
            className="chef_float"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/landing/chef.png)`,
            }}
          ></div>
        </div>
      );
    }
  }
}

export default finalCheckout;
