import React, { Component } from "react";
import axios from "axios";
import "./receipt.css";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";

export class receipt extends Component {
  state = {
    order: null,
    courier: null,
    load1: true,
    load2: true,
    redirect: false,
  };

  componentDidMount() {
    if (this.props.user) {
      this.props.setHistoryLink(null);
    }

    axios({
      method: "get",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/orders/${this.props.match.params.id}`,
      withCredentials: true,
    })
      .then((response) => {
        this.setState({ order: response.data.data.order, load1: false });
      })
      .catch((err) => {
        this.setState({ redirect: true });
        console.log(err);
      });
    axios({
      method: "get",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/orders/deliveryInfo/${this.props.match.params.id}`,
      withCredentials: true,
    })
      .then(async (response) => {
        if (response.data.status === "success") {
          await this.setState({ courier: response.data.data, load2: false });
        }
        // console.log("RESPONSE", response.data.data, this.state.courier);
      })
      .catch((err) => console.log(err));
  }

  calculateTotalPrice = () => {
    let price = 0;
    Array.from(this.state.order.dishList).forEach((dish) => {
      price = dish.dish.price + price;
    });
    return price;
  };
  render() {
    let serviceFee = this.state.order && this.state.order.digital ? 5 : 15;
    let additional =
      this.state.order && this.state.order.deliveryMode === "pickup" ? 0 : 1;
    if (this.state.redirect) {
      this.props.setHistoryLink(`/track/${this.props.match.params.id}`);
      return <Redirect to="/login" />;
    }
    if (!this.state.load1 || !this.state.load2) {
      //might be error if delivery hasn't been created yet
      return (
        <div className="container_nfs">
          <Helmet>
            <title>tizaar - Track Order</title>
            <meta
              name="description"
              content="Tickle your taste buds at tizaar's food bazaar and unconver hidden food delights right in your very neighborhood!"
            />
          </Helmet>
          <h1 style={{ fontFamily: "landingFontTwo" }}>
            <b>Order ID: {this.state.order._id}</b>
          </h1>
          {this.state.order.dishList.map((dish) => {
            return (
              <div className="dishlist flex_center">
                <div
                  style={{ backgroundImage: `url(${dish.dish.photo})` }}
                  alt=""
                  className="dish_image_r image"
                >
                  <div className="hover_tag">
                    <p className="name">Name: {dish.dish.name}</p>
                    <p className="quantity">Quantity: {dish.quantity}</p>
                  </div>
                </div>
              </div>
            );
          })}
          <div
            className="flex_cont"
            style={{ justifyContent: "space-between" }}
          >
            <div className="order_details_r">
              <div className="field_r">
                <i className="far fa-clock"></i>
                <p>
                  DELIVERY TIME (MAX):{" "}
                  {this.state.order.selectedTime + 2 + additional >= 12
                    ? this.state.order.selectedTime + 2 + additional === 12
                      ? `${12}pm`
                      : `${
                          this.state.order.selectedTime + 2 + additional - 12
                        }pm`
                    : `${this.state.order.selectedTime + 2 + additional}am`}
                </p>
              </div>
              <div className="field_r">
                <i className="fas fa-map-marker-alt"></i>
                <p>ORDER ADDRESS: {this.state.order.address}</p>
              </div>
              {this.state.order &&
              this.state.order.deliveryMode === "pickup" ? null : (
                <div className="field_r">
                  <i className="fas fa-truck"></i>{" "}
                  <p>
                    DELIVERY FEE:{" "}
                    {Math.round(1.031 * this.state.order.deliveryFee)}P
                  </p>
                </div>
              )}
              {this.state.order &&
              this.state.courier &&
              this.state.order.deliveryMode === "pickup" ? null : (
                <div className="field_r delivery_status">
                  <i className="far fa-question-circle"></i>
                  <p>DELIVERY STATUS: </p>
                  <div
                    className={
                      !this.state.order.finished
                        ? "cooking_order_r padding_btn"
                        : this.state.courier === null ||
                          this.state.courier === undefined
                        ? "waiting_delivery_r padding_btn"
                        : "OTW padding_btn"
                    }
                  >
                    {!this.state.order.finished
                      ? this.state.order.confirmed
                        ? "Order Confirmed"
                        : "Waiting on Confirmation"
                      : this.state.courier === null ||
                        this.state.courier === undefined
                      ? "Cooking Complete, Waiting on Delivery Confirmation"
                      : "Delivery is OTW. You will get an SMS/call on arrival"}
                  </div>
                </div>
              )}
            </div>
            <div className="order_total_price_r">
              <p>
                NET FOOD PRICE: <b>{this.calculateTotalPrice()}P</b>
              </p>
              {this.state.order &&
              this.state.order.deliveryMode === "pickup" ? null : (
                <p>
                  DELIVERY FEE:{" "}
                  <b>{Math.round(this.state.order.deliveryFee * 1.031)}P</b>
                </p>
              )}

              <p>
                SERVICE FEE: <b>{this.state.order.digital ? 5 : 15}P</b>
              </p>
              <div>
                <hr></hr>
                <span>TOTAL PRICE:</span>
                <b>
                  {this.calculateTotalPrice() +
                    serviceFee +
                    (this.state.order &&
                    this.state.order.deliveryMode === "pickup"
                      ? 0
                      : Math.round(this.state.order.deliveryFee * 1.031))}
                  P
                </b>
              </div>
            </div>
          </div>
          {this.state.order &&
          this.state.order.deliveryMode === "delivery" &&
          this.state.order.finished &&
          this.state.courier ? (
            <div
              className="courier_info"
              style={{ fontFamily: "landingFontTwo" }}
            >
              <h1>Courier Info</h1>
              <div className="courier_profile flex_cont">
                <div>
                  <p className="field_r">NAME: {this.state.courier.name}</p>
                  <p className="field_r">PHONE: {this.state.courier.phone}</p>
                </div>
                <div
                  className="courier_photo image"
                  style={{
                    backgroundImage: `url(${this.state.courier.photo_url})`,
                  }}
                ></div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      );
    } else {
      return (
        <div className="fullheight">
          <div
            className="chef_float "
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/landing/chef.png)`,
            }}
          ></div>
        </div>
      );
    }
  }
}

export default receipt;
