import React, { Component } from "react";
import "./tableCell.css";

export class tableCell extends Component {
  state = {
    active: false,
  };

  componentDidMount() {
    if (this.props.deliveryTimes[this.props.indexTime] != null) {
      this.setState({ active: true });
    } else {
      this.setState({ active: false });
    }
  }

  setActive = () => {
    let active = !this.state.active;
    this.setState({ active });
    this.props.clicked(
      this.props.time,
      this.props.date,
      this.props.indexTime,
      active
    );
  };

  render() {
    return (
      <td
        className="table_cell"
        onClick={this.setActive}
        onDrag={this.setActive}
      >
        {this.state.active ? (
          <i className="fas fa-check schedule"></i>
        ) : (
          <div></div>
        )}
      </td>
    );
  }
}

export default tableCell;
