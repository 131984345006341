const monthMap = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

const dateIndexMapRe = {
  0: "Sun",
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
};

Date.prototype.addDays = function (days) {
  // // console.log("ADDED", this.valueOf());
  var date = new Date();
  date.setDate(date.getDate() + days);
  // // console.log("MODIFIED DATE", date);
  return `${monthMap[date.getMonth()]} ${date.getDate()}`;
};

Date.prototype.addDaysCreatedFor = function (days) {
  var date = new Date();
  // // console.log(date);
  date.setDate(date.getDate() + days);
  // // console.log("post add", date);
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

const dateMap = {
  Sun: 0,
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
};

const dateIndexMap = {
  Mon: 0,
  Tue: 1,
  Wed: 2,
  Thu: 3,
  Fri: 4,
  Sat: 5,
  Sun: 6,
};

export class DateLogic {
  static convertUTCDateToLocalDate(date) {
    var newDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    );
    return newDate;
  }

  static addDays = function (days) {
    // // console.log("ADDED", this.valueOf());
    var date = new Date();
    date.setDate(date.getDate() + days);
    // // console.log("MODIFIED DATE", date);
    return `${monthMap[date.getMonth()]} ${date.getDate()}`;
  };

  static addDaysCreatedFor = function (days) {
    var date = new Date();
    // // console.log(date);
    date.setDate(date.getDate() + days);
    // // console.log("post add", date);
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  static returnNewDate = (date, createdFor, dayPrep) => {
    // // console.log("CREATED FOR", createdFor);
    var today = new Date();
    // DateLogic.convertUTCDateToLocalDate(new Date());
    // console.log(
    //   new Date(),
    //   "today",
    //   today,
    //   "DAY Prep: ",
    //   dayPrep,
    //   "INEQUALITY VALSt",
    //   today.getDay() + dayPrep,
    //   dateMap[date]
    // );
    if (
      today.getDay() + dayPrep > dateMap[date] &&
      (date !== "Sun" ||
        today.getDay() === 0 ||
        (date === "Sun" && today.getDay() + dayPrep > 7))
      //so one day means on the next day orders are allowed. AKA, no orders on day itself!!! If passed alr ahead.https://www.seanmcp.com/articles/storing-data-in-state-vs-class-variable/
    ) {
      //or return next week date?
      var daysTillNextWeekDay = (function () {
        var toSun = 7 - today.getDay();
        var sunToDate =
          date !== "Sun" ? dateMap[date] : today.getDay() === 0 ? 0 : 7; //i.e if sat, then add 7
        return toSun + sunToDate;
      })();
      if (
        today.getDay() + dayPrep > 7 &&
        today.getDay() + dayPrep - 7 > dateMap[date]
      )
        daysTillNextWeekDay = daysTillNextWeekDay + 7;
      if (!createdFor) {
        // // console.log("ADDED DAYS", daysTillNextWeekDay);
        let newDate = new Date(today.valueOf());
        newDate.setDate(newDate.getDate() + daysTillNextWeekDay);
        // // console.log("ADDED", newDate, newDate.getDate());
        return DateLogic.addDays(daysTillNextWeekDay);
      } else {
        return DateLogic.addDaysCreatedFor(daysTillNextWeekDay); //CHECK DAY CONFLICT!
      }
    } else {
      var daysTillDay = (function () {
        if (today.getDay() > dateMap[date]) {
          var toSun = 7 - today.getDay(); //check
          var sunToDate = dateMap[date];
          return toSun + sunToDate;
        } else {
          // // console.log(dateMap[date] - today.getDay());
          return dateMap[date] - today.getDay();
        }
      })();
      if (!createdFor) {
        return DateLogic.addDays(daysTillDay);
      } else {
        // // console.log("STRING DATE", DateLogic.addDaysCreatedFor(daysTillDay));
        return DateLogic.addDaysCreatedFor(daysTillDay);
      }
    }
  };
}

export default DateLogic;
