import React from "react";
import scriptLoader from "react-async-script-loader";
import { Redirect, NavLink, withRouter } from "react-router-dom";
import Modal from "./modal";
import Geocode from "react-geocode";
import PlacesAutocomplete, {
  geocodeByPlaceId,
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Loader from "react-loader-spinner";
import "./locationInput.css";
import Store from "../Store";
import ReactDOM from "react-dom";
import axios from "axios";

Geocode.setApiKey("AIzaSyCDTCEbBYYoythM-3jiOB_iwk0fmVb3krc"); //remove later!
Geocode.setLanguage("en");
Geocode.setRegion("ph");
Geocode.setLocationType("ROOFTOP");

const searchOptions = {
  componentRestrictions: { country: ["ph"] },
  // types: ["postal_code"], //might have to convert to cities
};

class LocationInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.valueC
        ? this.props.valueC
        : this.props.user && this.props.user.address
        ? this.props.user.address
        : Store.getAddress(),
      coordinates: {},
      updateAddress: !(
        (Store.getLat() &&
          Store.getLng()) /*&& Store.getAddress() TODO: ADD BACK*/ ||
        (this.props.user &&
          this.props.user.location &&
          this.props.user.location.coordinates)
      ),
      redirect: false,
      alert: null,
      modal: false,
      loader: false,
    };
    this.element = React.createRef(); //this.state.updateAddress
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    if (this.input && this.input.current) {
      this.element.current.addEventListener("keypress", this.handleKeyPress);
    }
    // this.getLatLngByZipcode("1267");
  }

  componentDidUpdate() {
    if (this.input && this.input.current)
      this.element.current.addEventListener("keypress", this.handleKeyPress);
  }

  componentWillUnmount() {
    if (this.input && this.input.current)
      this.element.current.removeEventLitsener("keypress", this.handleKeyPress);
  }

  handleKeyPress(event) {
    // [Enter] should not submit the form when choosing an address.
    // console.log(this.state);
    if (event.keyCode === 13) {
      event.preventDefault();
      let self = this;
      this.setState({ alert: "Please select an address from the dropdown" });
      setTimeout(function () {
        self.setState({ alert: null });
      }, 3000);
      return;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.date != this.props.date) {
      this.setState({ redirect: false });
    }
  }

  // getLatLngByZipcode(zipcode) {
  //   let lat,
  //     lng = null;

  //   axios({
  //     method: "get",
  //     url: `http://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCDTCEbBYYoythM-3jiOB_iwk0fmVb3krc&components=postal_code:${zipcode}&countryCode={ph}`,
  //     withCredentials: true,
  //   }).then((result) => {
  //     // console.log("zip code RESULT", result);
  //   });
  //   // Geocode.fromAddress(`${zipcode}, PH`).then(
  //   //   (response) => {
  //   //     // console.log(response.results[0]);
  //   //     const { lat, lng } = response.results[0].geometry.location;
  //   //     alert(`${lat}, ${lng}`);
  //   //   },
  //   //   (error) => {
  //   //     alert(error);
  //   //     // console.log(error);
  //   //   }
  //   // );
  //   // return [lat, lng];
  // }

  updateLandingLoc = (address, lat, lng) => {
    let coordinates = { lat: lat, lng: lng };
    this.setState({ address, coordinates });
  };

  handleChange = (address) => {
    this.setState({ address });
  };

  onConfirmClick = () => {
    this.setState({ redirect: true, modal: false });
    // axios({
    //   method: "post",
    //   url: `${
    //     process.env.NODE_ENV === "production"
    //       ? process.env.REACT_APP_URL
    //       : "http://localhost:8080"
    //   }/api/v1/users/testConfirm`,
    //   withCredentials: true,
    //   data: { confirm: true },
    // }).then(async (response) => {
    //   this.setState({ redirect: true, modal: false });
    // });
  };

  autoLocation = () => {
    let retEarly = false;
    let self = this;
    navigator.geolocation.watchPosition(
      function (position) {},
      function (error) {
        console.log(error.code);
        if (error.code == error.PERMISSION_DENIED) {
          retEarly = true;
          self.setState({
            loader: false,
            alert:
              "There was an error getting your location from the browser - please make sure location services are enabled! Please type it in manually. Sorry!",
          });
          setTimeout(function () {
            self.setState({ alert: null });
          }, 3000);
        }
      }
    );
    if (retEarly) return;
    if (navigator.geolocation) {
      /* &&
      
      Object.keys(navigator.geolocation).length > 0*/
      this.setState({ loader: true });
      navigator.geolocation.getCurrentPosition((position) => {
        let { latitude, longitude } = position.coords;
        console.log("response", position);
        Geocode.fromLatLng(latitude, longitude)
          .then(
            async (response) => {
              const address = response.results[0].formatted_address;
              console.log(address);
              await this.stateUpdates(latitude, longitude, address);
            },
            (error) => {
              console.error(error);
              this.setState({
                loader: false,
                alert:
                  "There was an error getting your location from the browser! Please type it in manually. Sorry!",
              });
              let self = this;
              setTimeout(function () {
                self.setState({ alert: null });
              }, 3000);
            }
          )
          .catch((err) => console.log("error at auto", err));
      });
    } else {
      console.log("err");
      this.setState({
        loader: false,
        alert:
          "There was an error getting your location from the browser - please make sure location services are enabled! Please type it in manually. Sorry!",
      });
      let self = this;
      setTimeout(function () {
        self.setState({ alert: null });
      }, 3000);
    }
  };

  stateUpdates = async (lat, lng, address) => {
    let latLng = {
      lat,
      lng,
    };
    Store.setLat(lat);
    Store.setLng(lng);
    // console.log("selected address", address);
    Store.setAddress(address);

    if (this.props.setLocation) {
      this.props.setLocation(address, lat, lng);
    }
    if (this.props.modal) {
      this.props.updateLandingLoc(address, lat, lng);
    }
    if (this.props.user) await this.props.updateLocation([lng, lat], address);

    !this.props.profile && !this.props.homechef
      ? await this.setState({
          address,
          coordinates: latLng,
          modal: true,
          loader: false,
          redirect: true, //only because only for auto atm
        })
      : await this.setState({ address, coordinates: latLng, loader: false });
  };

  handleSelect = (address) => {
    if (this.props.setLoading) this.props.setLoading();
    geocodeByAddress(address)
      .then(async (results) => {
        // let length = results[0].address_components
        //   ? results[0].address_components.length
        //   : 0;
        // if (length)
        //   console.log(length, results[0].address_components[length - 1].long_name);
        let latLng = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        };
        // console.log("LATLNG", latLng, address);
        Store.setLat(latLng.lat);
        Store.setLng(latLng.lng);
        // console.log("selected address", address);
        Store.setAddress(address);

        if (this.props.setLocation) {
          this.props.setLocation(
            address,
            results[0].geometry.location.lat(),
            results[0].geometry.location.lng()
          );
        }
        if (this.props.modal) {
          this.props.updateLandingLoc(address, latLng.lat, latLng.lng);
        }

        if (this.props.user)
          await this.props.updateLocation(
            Object.values(latLng).reverse(),
            address
          );
        !this.props.profile && !this.props.homechef
          ? await this.setState({
              address,
              coordinates: latLng,
              modal: true,
            })
          : await this.setState({ address, coordinates: latLng });
        // this.props.history.push("/bazaar");
        // return <Redirect to="/bazaar" />; //worked with NavLink try this. Try putting this in render. I.e update state then conditional
        //might have to call props here to update user with coordinates and address to home and then to parent render --> this is on completion in any case.
        //Call Redirect here to go to bazaar
      })
      .catch((error) => {
        alert(
          "There was an error in collecting your address! Apologies for the inconvenience."
        ); //allow them to visit the bazaar in any case? Call the protected route TOO!
        console.log(error);
      });
  };

  closeForm = () => {
    this.setState({ modal: false });
  };

  render() {
    if (this.state.redirect) return <Redirect to="/bazaar" />;
    if (this.state.modal)
      return (
        <Modal
          updateLocation={this.props.updateLocation}
          user={this.props.user}
          lat={this.state.coordinates.lat}
          lng={this.state.coordinates.lng}
          confirm={this.onConfirmClick}
          closeForm={this.closeForm}
          updateLandingLoc={this.updateLandingLoc}
        />
      );
    return this.state.updateAddress || this.props.homechef ? (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
      >
        {/* A function that is being called --> nested child function */}
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div
            className={
              !this.props.homechef ? "location-btn" : "homechef-location-btn"
            }
          >
            {!this.state.loader ? (
              <div className="flex loco" onClick={this.autoLocation}>
                <div className="loco_text">
                  <b>Locate Me</b>
                </div>
                <i class="fas fa-location-arrow"></i>
              </div>
            ) : (
              <div className="loco">
                <Loader
                  type="Puff"
                  color="#FFA500"
                  height={10}
                  width={10}
                  timeout={3000} //3 secs
                />
              </div>
            )}
            {this.state.alert ? (
              <div className={"error_alert"} style={{ zIndex: "10000000" }}>
                {this.state.alert}
              </div>
            ) : (
              <div></div>
            )}
            <div className="flex_cont">
              <i className="fas fa-map-marker-alt"></i>
              <input
                {...getInputProps({
                  placeholder: !this.props.homechef
                    ? "Enter your address to explore the bazaar ->"
                    : "Please enter your address",
                  className: !this.props.homechef
                    ? "location-search-input"
                    : "location-search-input-homechef",
                })}
                value={this.state.address}
                ref={this.element}
                // onSubmit={(e) => e.preventDefault()}
                onKeyDown={this.handleKeyPress}
              />
            </div>
            <div
              className={
                !this.props.homechef
                  ? "autocomplete-dropdown-container"
                  : "autocomplete-dropdown-container-homechef"
              }
            >
              {loading && <div className="loading_loc">Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? !this.props.homechef
                    ? "suggestion-item--active"
                    : "suggestion-item--active-homechef"
                  : !this.props.homechef
                  ? "suggestion-item"
                  : "suggestion-item-homechef";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    ) : (
      //to reduce googleAPI requests.
      <div
        className={
          !this.props.profile
            ? "location-btn flex_cont"
            : "location-btn location-btn-profile flex_cont"
        }
      >
        <NavLink
          to="/bazaar"
          className={
            this.props.profile
              ? "hover_explore no_transform"
              : "hover_explore no_translate"
          }
        >
          Explore the Bazaar &#10140;
        </NavLink>
        <div
          className="update_location"
          onClick={() => this.setState({ updateAddress: true })}
        >
          <div className="flex_cont">
            <p>
              Update <br />
              Location
            </p>
            <i className="fas fa-map-marker-alt"></i>
          </div>
        </div>
      </div>
    );
  }
}

export default LocationInput;
