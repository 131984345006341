import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import ReactDOm from "react-dom";

import "../bazaar/general_bazaar.css";
import "./navbar.css";

export class navbar extends Component {
  state = {
    hamburger: false,
  };

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, false);
  }

  handleClickOutside = (e) => {
    // // console.log("here", e.target, this.popup, this.popup.contains(e.target));
    if (!this.popup.contains(e.target) && this.state.hamburger) {
      this.setState({ hamburger: false });
    }
  };

  highlight = (e) => {
    // console.log(e.target);
    var old = document.querySelector(".active_link");
    old
      ? old.classList.remove("active_link") // console.log("n/a navlink");
      : e.target.classList.add("active_link");
    if (this.state.hamburger) this.setState({ hamburger: false });
  };

  renderNavLinks = () => {};

  render() {
    return (
      <div>
        <section className="nav_and_top">
          <div className={this.props.homechef ? "nav fixed_nav" : "nav"}>
            <div className="nav_left">
              {/* <NavLink to="/"> */}
              <NavLink to="/">
                <div
                  className="navlink home_link active_link"
                  onClick={this.highlight}
                >
                  <div className="logo">tizaar</div>
                  <div className="logo-within">
                    <div>food</div>
                    <div>Bazaar</div>
                  </div>
                </div>
              </NavLink>
              {/* </NavLink> */}
            </div>
            <div
              className={
                this.state.hamburger ? "nav_right active_ham_nav" : "nav_right"
              }
              ref={(node) => (this.popup = node)}
            >
              <div
                className={
                  this.state.hamburger
                    ? "hamburger_menu active_ham"
                    : "hamburger_menu"
                }
                onClick={() => {
                  this.setState({ hamburger: !this.state.hamburger });
                }}
              ></div>
              {this.props.user && this.props.user.role === "chef" ? (
                <NavLink to="/my-menu">
                  <div className="navlink" onClick={this.highlight}>
                    MY MENU
                  </div>
                </NavLink>
              ) : (
                <div></div>
              )}
              {this.props.user && this.props.user.role === "chef" ? (
                <NavLink to="/dashboard">
                  <div className="navlink" onClick={this.highlight}>
                    DASHBOARD
                  </div>
                </NavLink>
              ) : (
                <div></div>
              )}
              {this.props.user && this.props.user.role === "chef" ? (
                <NavLink to="/analytics">
                  <div className="navlink" onClick={this.highlight}>
                    ANALYTICS
                  </div>
                </NavLink>
              ) : (
                <div></div>
              )}
              {/* {this.props.user.location ? (
                <NavLink to="/bazaar">
                  <div className="navlink" onClick={this.highlight}>
                    FOOD BAZAAR
                  </div>{" "}
                </NavLink>
              ) : (
                <div></div>
              )} */}
              {!this.props.user || this.props.user.role !== "chef" ? (
                <NavLink to="/homechef">
                  <div className="navlink" onClick={this.highlight}>
                    BECOME A HOMECHEF/SUPPLIER
                  </div>
                </NavLink>
              ) : (
                <div></div>
              )}

              {!this.props.user ? (
                <NavLink to="/login">
                  <div
                    className="navlink cart_navlink"
                    onClick={this.highlight}
                  >
                    LOGIN
                  </div>
                </NavLink>
              ) : (
                <NavLink to="/profile">
                  <div
                    className="navlink profile_link"
                    onClick={this.highlight}
                  >
                    {/* <div
                    className="profile image"
                    style={{ backgroundImage: `url(${this.props.user.photo ? this.props.user.photo : })` }}
                  ></div> */}
                    <div className="align-items-center-v">PROFILE</div>
                  </div>
                </NavLink>
              )}
              {!this.props.user ? null : (
                <NavLink to="/cart">
                  <div
                    className="navlink cart_navlink"
                    onClick={this.highlight}
                  >
                    <i
                      className="fas fa-shopping-cart"
                      style={{ marginRight: "5px" }}
                    ></i>
                    <b>CART</b>
                  </div>
                </NavLink>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default navbar;
