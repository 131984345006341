import React, { Component } from "react";
import LocationInput from "./locationInput";
import GoogleMapReact from "google-map-react";
import "./modal.css";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
const {
  MarkerWithLabel,
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");

const MapWithAMarker = withGoogleMap((props) => {
  console.log("YO WHATS GOING ON", props);
  return (
    <GoogleMap
      defaultZoom={17}
      defaultCenter={props.defaultCenter}
      key={Date.now()}
    >
      <Marker
        position={props.defaultCenter}
        // label={"WE WILL DELIVER HERE"}
        // labelStyle={{
        //   padding: "20px",
        //   backgroundColor: "orange",
        //   fontFamily: "landingFontTwo",
        //   borderRadius: "10px",
        //   color: "orange",
        // }}
      />
      {/* <MarkerWithLabel
        position={props.defaultCenter}
        labelStyle={{
          backgroundColor: "yellow",
          fontSize: "32px",
          padding: "16px",
        }}
      >
        <div>WE WILL DELIVER HERE</div>
      </MarkerWithLabel> */}
    </GoogleMap>
  );
});

const AnyReactComponent = ({ text }) => (
  <div
    style={{
      color: "white",
      background: "orange",
      width: "50px",
      height: "50px",
      padding: "2px",
      display: "flex",
      alignContent: "center",
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100%",
      transform: "translate(-50%, -50%)",
      fontFamily: "landingFontTwo",
    }}
  >
    {text}
  </div>
);

export class modal extends Component {
  state = {
    lat: this.props.lat,
    lng: this.props.lng,
  };

  componentDidMount() {
    console.log(this.props);
  }

  componentDidUpdate() {
    if (
      this.state.lat !== this.props.lat ||
      this.state.lng !== this.props.lng
    ) {
      let lat = this.props.lat;
      let lng = this.props.lng;
      this.setState({ lat, lng });
      console.log(this.state);
    }
  }

  render() {
    return (
      <div className="location_popup">
        <LocationInput
          updateLocation={this.props.updateLocation}
          user={this.props.user}
          date={new Date()}
          homechef={true}
          modal={true}
          updateLandingLoc={this.props.updateLandingLoc}
        />
        <div className="close_button_form">
          <i
            class="fas fa-times"
            style={{ color: "red" }}
            onClick={this.props.closeForm}
          ></i>
        </div>
        {console.log("BEFORE< MAP WITH", this.state)}
        <div
          className="map_container"
          style={{ width: "100%", height: "300px", padding: "30px" }}
        >
          <MapWithAMarker
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            defaultCenter={this.state}
          />{" "}
          <p className="note">We will deliver food to the point above</p>
          {/* <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyCDTCEbBYYoythM-3jiOB_iwk0fmVb3krc",
            }}
            defaultCenter={this.defaultProps}
            defaultZoom={11}
          >
            <AnyReactComponent
              lat={this.props.lat}
              lng={this.props.lng}
              text={"HERE"}
            />
          </GoogleMapReact> */}
        </div>
        {/* MAP */}

        <div className="confirm_address_btn" onClick={this.props.confirm}>
          Confirm Address
        </div>
      </div>
    );
  }
}

export default modal;
