import React, { Component } from "react";
import "../general_bazaar.css";
import "./popularDish.css";
import "../media_bazaar.css";

export class PopularDish extends Component {
  render() {
    // // console.log(this.props.dish.fileName);
    return (
      <div
        className="dish_card image"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/cotf/${this.props.dish.fileName})`,
        }}
      >
        <p className="dishName">{this.props.dish.dishName}</p>
      </div>
    );
  }
}

export default PopularDish;
