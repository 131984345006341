import React, { Component } from "react";
import "./sellerCards.css";
export class sellerCards extends Component {
  render() {
    return (
      <div className="chef_benefit_card">
        <img
          src={`${process.env.PUBLIC_URL}/homechef/${this.props.fileName}.png`}
          alt=""
          className="chef_benefit_icon"
        />
        <h1 className="chef_benefit_header">{this.props.header}</h1>
        <p className="chef_benefit_description">{this.props.message}</p>
      </div>
    );
  }
}

export default sellerCards;
