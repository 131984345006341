import React, { Component } from "react";
import "../general_bazaar.css";
import "./cotf.css";
import "../media_bazaar.css";
import PopularDish from "./PopularDish.jsx";
export class cotf extends Component {
  state = {
    chefName: "Ranbir",
    chefSlogan:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ut unde dolores delectus necessitatibus accusantium voluptas?",
    dish_one: {
      fileName: "beef.png",
      dishName: "Beef Stew",
    },
    dish_two: {
      fileName: "healthy_rice.png",
      dishName: "Vegetable Rice",
    },
    dish_three: {
      fileName: "beef_potatoes.png",
      dishName: "Steak and Potatoes",
    },
  };
  render() {
    return (
      <div className="chefs_of_the_week temp">
        <div className="main_cotf">
          <div className="lhs_cotf">
            <div className="chef_image image"></div>
            <div className="about_chef">
              <h1 className="chef_name">{this.state.chefName}</h1>
              <br />
              <p className="chef_slogan">{this.state.chefSlogan}</p>
            </div>
          </div>
          <div className="rhs_cotf">
            <h1 className="popular_dishes">
              {`${this.state.chefName}'s Popular Dishes`}{" "}
            </h1>
            <div className="dishes">
              <div className="col-3 dish_one split">
                <PopularDish dish={this.state.dish_one} />
              </div>
              <div className="col-3 split">
                <PopularDish dish={this.state.dish_two} />{" "}
              </div>
              <div className="col-3 split">
                <PopularDish dish={this.state.dish_three} />
              </div>
            </div>
          </div>
        </div>
        <div className="btn_btn inline see_more">See More</div>
        <i className="far fa-arrow-alt-circle-left"></i>
        <i className="far fa-arrow-alt-circle-right"></i>
      </div>
    );
  }
}

export default cotf;
