import React, { Component } from "react";
import "./mainFilters.css";
import cuisineFilters from "./cuisineFilters";

export class mainFilters extends Component {
  render() {
    const icon = require(`../../images/bw_logos/${this.props.fileName}`)
      .default;
    let img = (
      <img
        src={icon} //check for all!
        alt={``}
        className={
          this.props.featureName === "price-one"
            ? "icon_img price_icon"
            : "icon_img"
        }
      />
    );
    return (
      <div
        className={
          this.props.main === this.props.featureName
            ? `filter_bubble_main active_filter_main`
            : `filter_bubble_main ${this.props.featureName}`
        }
        onClick={async () => {
          if (this.props.main !== this.props.featureName) {
            await this.props.setMain(this.props.featureName);
            cuisineFilters.selected_cuisine = "All";
          }
          this.props.onClick(this.props.featureName);
        }}
      >
        {img}
        <b>{this.props.featureName}</b>
      </div>
    );
  }
}

export default mainFilters;
