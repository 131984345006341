import React, { Component } from "react";
import "./homestyle.css";
import { Redirect, NavLink } from "react-router-dom";
import LocationInput from "../locationInput/locationInput";
import { Helmet } from "react-helmet";
import RatingsPopup from "../ratings/ratingsPopup";
const mainPhoto = require(`./images/main.jpg`).default;

export class landing extends Component {
  state = {
    loading: false,
    imagesLoading: false,
    isBottom: false,
    redirect: false,
    alert: null,
  };
  onScroll = () => {
    const isBottom = window.scrollY > 350;
    if (!this.state.isBottom && isBottom !== this.state.isBottom) {
      this.setState({ isBottom });
    }
  };

  componentDidMount() {
    document.addEventListener("scroll", this.onScroll);

    if (
      this.props.user &&
      this.props.user.address &&
      this.props.user.location
    ) {
      //console.log(this.props.user.address, this.props.user.location);
      this.setState({ redirect: true });
    } //ADD BACK!
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
    let user = this.props.user;
    if (user && user.rewards && user.rewards !== 0) {
      this.setState({
        alert: `You have earned ${user.rewards}P in rewards for your next order - to be applied on checkout!`,
      });
      let self = this;
      setTimeout(() => {
        self.setState({ alert: null });
      }, 5000);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.onScroll);
  }

  imageLoading = () => {
    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image.url;
        // wait 2 seconds to simulate loading time
        loadImg.onload = () =>
          setTimeout(() => {
            resolve(image.url);
          }, 2000);

        loadImg.onerror = (err) => reject(err);
      });
    };
    loadImage({
      url:
        "https://tizaar-foodbazaar.s3-ap-southeast-1.amazonaws.com/landing.png",
    })
      .then(() => {
        this.setState({ imagesLoading: true });
      })
      .catch(() => {
        this.setState({ imagesLoading: true });
      });
  };

  render() {
    if (this.state.redirect) return <Redirect to="/bazaar" />;
    return (
      <div
        className={
          !this.state.loading && !this.state.imagesLoading
            ? "body_h"
            : "body massive_height"
        }
      >
        {this.state.alert ? (
          <div className="payed_alert">{this.state.alert}</div>
        ) : null}
        <RatingsPopup />
        <div
          className={
            this.state.loading && this.state.imagesLoading
              ? "chef_float"
              : "display_none"
          }
          style={{
            backgroundImage: `url(https://tizaar-foodbazaar.s3-ap-southeast-1.amazonaws.com/chef-01.png)`,
          }}
        ></div>
        <section
          className={
            !this.state.loading
              ? "landing fullheight align-items-center-h image"
              : "display_none"
          }
        >
          <img
            src={
              "https://tizaar-foodbazaar.s3-ap-southeast-1.amazonaws.com/landing.png"
            }
            className="fullheight align-items-center-h landing_preload"
            alt=""
          />
          <div className="container">
            <Helmet>
              <title>tizaar: Homecooked Food and Bakeries Near You!</title>
              <meta
                name="description"
                content="Tickle your taste buds at tizaar's food bazaar and unconver hidden food delights right in your very neighborhood!"
              />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1"
              />
            </Helmet>
            <div className="text">
              <h1 className="main">TIZAAR'S FOOD BAZAAR</h1>
              <h2 className="submain">
                <span style={{ "--i": 1 }}>T</span>
                <span style={{ "--i": 2 }}>i</span>
                <span style={{ "--i": 3 }}>c</span>
                <span style={{ "--i": 4 }}>k</span>
                <span style={{ "--i": 5 }}>l</span>
                <span style={{ "--i": 6 }}>e</span> your taste buds at{" "}
                <a className="main_text">Tizaar's Food Bazaar</a>
              </h2>
              {/* <a href="#" className="btn landing-btn">
                Explore the Bazaar &#10140;
              </a> IF ADDRESS EXISTS HAVE THIS INSTEAD*/}
              <LocationInput
                updateLocation={this.props.updateUserLocation}
                user={this.props.user}
                date={new Date()}
                landing={true}
              />
            </div>
          </div>
        </section>
        <section className={!this.state.loading ? "service" : "display_none"}>
          {/* <!-- <img src={`${process.env.PUBLIC_URL}/landing/images/`burger")} alt="" className="burger icon" /> --> */}
          <div className="inline col-5 left_cont">
            <img
              src={`https://tizaar-foodbazaar.s3-ap-southeast-1.amazonaws.com/chef-01.png`}
              alt=""
              className="left_chef"
            />
          </div>
          <div className="inline right_block col-7 align-items-center-h">
            <div className="service_container align-items-center-v align-items-center-h">
              <img
                src={`https://tizaar-foodbazaar.s3-ap-southeast-1.amazonaws.com/Bubble.png`}
                alt=""
                className="bubbles"
              />
              <img
                src={`https://tizaar-foodbazaar.s3-ap-southeast-1.amazonaws.com/Circle.png`} //${process.env.PUBLIC_URL}/landing/images/Circle.png
                alt=""
                className="circles"
              />
              {/* <img src={`${process.env.PUBLIC_URL}/landing/images/`ng")} alt="" className="wave" /> */}
              <div className="text_service_blob">
                <p className="service_text">Support your local community!</p>
                <p className="service_text">
                  All sellers on tizaar are homechefs or small local businesses.
                </p>
                <p className="service_text"></p>
              </div>
              <img
                src={`https://tizaar-foodbazaar.s3-ap-southeast-1.amazonaws.com/1.png`}
                alt=""
                className="curve_lines"
              />
              <img
                src={`https://tizaar-foodbazaar.s3-ap-southeast-1.amazonaws.com/hand.png`} //${process.env.PUBLIC_URL}/landing/images/hand.png
                alt=""
                className={this.state.isBottom ? "hands hands_move" : "hands"}
              />
            </div>
          </div>
        </section>
        {/* <img
          src="https://tizaar-foodbazaar.s3.ap-southeast-1.amazonaws.com/emman+olympics.png"
          alt=""
          className=""
        /> */}
        <div className="container_b">
          <h2 className="section_title_h">Some Dishes on Tizaar</h2>
          <img
            src={`${process.env.PUBLIC_URL}/landing/images/line.png`}
            alt=""
            className="line_underscore"
          />
          <div className="dishes_promo flex_wrap" style={{ marginTop: "40px" }}>
            <div className="food_card_l">
              <img
                src="https://tizaar-foodbazaar.s3.ap-southeast-1.amazonaws.com/Dishcalifornia60d716eb372fb8001584bbde+copy+3.png"
                alt=""
                className="dish_img_l special_size"
              />
              <div className="dish_dets">
                <div className="div_l">
                  <div className="cuisine_label">JAPANESE</div>
                  <h3 className="dish_name_l">Jan's California Maki</h3>
                  <NavLink to="/menu/60d716eb372fb8001584bbde">
                    <div
                      className="small btn_orange"
                      style={{ fontSize: "15px" }}
                    >
                      See Jan's Menu
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="food_card_l">
              <img
                src="https://tizaar-foodbazaar.s3.ap-southeast-1.amazonaws.com/Dishchiangmai+noodles60bdbc2105597c001507e780+(1).png"
                alt=""
                className="dish_img_l special_size"
              />
              <div className="dish_dets">
                <div className="div_l">
                  <div className="cuisine_label">THAI</div>
                  <h3 className="dish_name_l">Prayot's Chiangmai Noodles</h3>
                  <NavLink to="/menu/60bdbc2105597c001507e780">
                    <div
                      className="small btn_orange"
                      style={{ fontSize: "15px" }}
                    >
                      See Prayot's Menu
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
            {/*<div className="food_card_l">
            <img
              src="https://tizaar-foodbazaar.s3.ap-southeast-1.amazonaws.com/octopus.png"
              alt=""
              className="dish_img_l"
            />
             <div className="dish_dets">
              <div className="div_l">
                <div className="cuisine_label">SPANISH</div>
                <h3 className="dish_name_l">Denise's Ensalada De Polpo</h3>
                <NavLink to="/menu/60e4e0f63fb92000152dffd2">
                  <div
                    className="small btn_orange"
                    style={{ fontSize: "15px" }}
                  >
                    See Denise's Menu
                  </div>
                </NavLink>
              </div>
            </div>
          </div> */}
            <div className="food_card_l">
              <img
                src="https://tizaar-foodbazaar.s3.ap-southeast-1.amazonaws.com/artichocke+pizza.png"
                alt=""
                className="dish_img_l special_size"
              />
              <div className="dish_dets">
                <div className="div_l">
                  <div className="cuisine_label">ITALIAN</div>
                  <h3 className="dish_name_l">
                    Emmans's Spinach Artichoke Pizza
                  </h3>
                  <NavLink to="/menu/60dabff25f704e0015f101a8">
                    <div
                      className="small btn_orange"
                      style={{ fontSize: "15px" }}
                    >
                      See Emman's Menu
                    </div>
                  </NavLink>
                </div>
              </div>
              {/* <div className="mini_dets">
              <hr style={{ color: "gray" }}></hr>
              <div className="flex_cont fullwidth">
                <p className="price_tag_l">300P</p>
                <p className="portion_size">
                  {" "}
                  <span> 1 </span> person
                </p>
              </div>
            </div> */}
            </div>
            <div className="food_card_l">
              <img
                src="https://tizaar-foodbazaar.s3.ap-southeast-1.amazonaws.com/Dishsignature+campfire+cookies+(box+of+6)60beeefe1b8e35001564eaff+(1).png"
                alt=""
                className="dish_img_l special_size_l"
              />
              <div className="dish_dets">
                <div className="div_l">
                  <div className="baker_label">COOKIES</div>
                  <h3 className="dish_name_l">Melody's Campfire Cookies</h3>
                  <NavLink to="/menu/60beeefe1b8e35001564eaff">
                    <div
                      className="small btn_blue"
                      style={{ fontSize: "15px" }}
                    >
                      See Melody's Menu
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="food_card_l">
              <img
                src="https://tizaar-foodbazaar.s3.ap-southeast-1.amazonaws.com/baklava+copy+(1).png"
                alt=""
                className="dish_img_l"
              />
              <div className="dish_dets">
                <div className="div_l">
                  <div className="baker_label">MIDDLE EASTERN, DESERT</div>
                  <h3 className="dish_name_l">Avi's Baklava</h3>
                  <NavLink to="/menu/60cdf387564048001593ce0c">
                    <div
                      className="small btn_blue"
                      style={{ fontSize: "15px" }}
                    >
                      See Avi's Menu
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section
          className={!this.state.loading ? "value_prop_buyer" : "display_none"}
        >
          <div className="container_b">
            <h2 className="section_title_h">Why Buy From Tizaar?</h2>
            <img
              src={`${process.env.PUBLIC_URL}/landing/images/line.png`}
              alt=""
              className="line_underscore"
            />

            <div className="card-container">
              <div className="card_logo_h">
                <div className="column-arrange">
                  <div className="image-wrapper logo_one">
                    <img
                      src={`https://tizaar-foodbazaar.s3-ap-southeast-1.amazonaws.com/Talented+chefs.png`}
                      alt=""
                      className="photo_logo"
                    />
                  </div>
                  <h1 className="slogan">TALENTED</h1>
                  <h1 className="slogan">HOMECHEFS & VENDORS</h1>
                  <p className="description_logo">
                    Each homechef, or vendor, goes through an application
                    process to ensure that the quality of their homecooked food
                    or ingredients are fantastic.
                  </p>
                </div>
              </div>
              <div className="card_logo_h">
                <div className="column-arrange">
                  <div className="image-wrapper logo_two">
                    <img
                      src={`${process.env.PUBLIC_URL}/landing/loc.svg`}
                      alt=""
                      className="photo_logo"
                    />
                  </div>
                  <h1 className="slogan" style={{ color: "white" }}>
                    FOOD NEAR YOU
                  </h1>
                  <p className="description_logo">
                    Find homecooked meals near you! The ability to sort by
                    distance will help to find meals within your neighborhood
                    and get cheaper food delivery. More often than not you may
                    even be able to simply walk to pick up your food given how
                    close the chefs are!
                  </p>
                </div>
              </div>
              <div className="card_logo_h">
                <div className="column-arrange">
                  <div className="image-wrapper logo_three">
                    <img
                      src={`https://tizaar-foodbazaar.s3-ap-southeast-1.amazonaws.com/Variety.png`}
                      alt=""
                      className="photo_logo"
                    />
                  </div>
                  <h1 className="slogan variety">
                    AFFORDABILITY, VARIETY & AUTHENTICITY
                  </h1>
                  <p className="description_logo">
                    Uncover hidden gems. Tizaar's food bazaar contains food from
                    all across the world and some intriguing, creative, and
                    delicious creations at a range of prices.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className={!this.state.loading ? "value_prop_s" : "display_none"}
        >
          <div className="container_nf container_v">
            <h2 className="section_title_h">Stories From Our Homechefs</h2>
            <img
              src={`${process.env.PUBLIC_URL}/landing/images/line.png`}
              alt=""
              className="line_underscore extra_margin_b"
            />
            <div className="container_s">
              <img
                src={`${process.env.PUBLIC_URL}/landing/images/wave.png`}
                alt=""
                className="yellow_wave"
              />
              {/* <!-- <img src={`${process.env.PUBLIC_URL}/landing/images/`purple_wave")} alt="" className="purple_wave" /> --> */}
              {/* <i className="far landing_arrow fa-arrow-alt-circle-left"></i>
              <i className="far landing_arrow fa-arrow-alt-circle-right"></i> */}
              <div className="card_s card_s_one">
                {/* <div className="top_right_star">
                  <i className="fa fa-star" aria-hidden="true"></i>
                  <p>4.5</p>
                </div> */}
                <div className="center_image">
                  <img
                    src={`https://tizaar-foodbazaar.s3.ap-southeast-1.amazonaws.com/profile/User60b484c331afd700154e13c0`}
                    alt=""
                    className="chef_image_s not_cropped"
                  />
                </div>
                <div className="text_s">
                  <h1 className="name_s">Ange</h1>
                  <p className="description_s">
                    I started PB (Pink Bakery) back in high school but put it on
                    hold while I pursued my culinary degree. I then worked for 2
                    Manila restaurants & decided to pursue PB full time with my
                    best friend, Patxi whose seemingly opposite background
                    turned out to be the perfect partner I needed to help turn
                    PB from a side hustle into a business!
                  </p>
                </div>
              </div>
              <div className="card_s card_s_two">
                {/* <div className="top_right_star">
                  <i className="fa fa-star" aria-hidden="true"></i>
                  <p>4.5</p>
                </div> */}
                <div className="center_image">
                  <img
                    src={`https://tizaar-foodbazaar.s3.ap-southeast-1.amazonaws.com/profile/Brand60ac8a07b03d4d00156cef8f`}
                    alt=""
                    className="chef_image_s not_cropped"
                  />
                </div>
                <div className="text_s">
                  <h1 className="name_s">Kanika</h1>
                  <p className="description_s">
                    My love for the Kitchen has grown ever since I left a
                    lucrative banking career in 2009. After working in a modern
                    Mediterranean Kitchen in Sydney and running a home kitchen
                    in Shanghai, I was left inspired by all the different
                    flavours and ingredients. I have brought this inspiration to
                    the Indian food I grew up with to create a guilt free yet
                    incredibly satisfying experience!
                  </p>
                </div>
              </div>
              <div className="card_s card_s_three">
                {/* <div className="top_right_star">
                  <i className="fa fa-star" aria-hidden="true"></i>
                  <p>4.5</p>
                </div> */}
                <div className="center_image">
                  <img
                    src={`https://tizaar-foodbazaar.s3.ap-southeast-1.amazonaws.com/profile/User60bdbc2105597c001507e780`}
                    alt=""
                    className="chef_image_s not_cropped"
                  />
                </div>
                <div className="text_s">
                  <h1 className="name_s">Prayot</h1>
                  <p className="description_s">
                    I learned how to cook authentic thai cuisine food through
                    wandering around the streets of Bangkok and Chiangmai. I
                    recreate these very dishes I got to see and taste with thai
                    herbs and spices that we grow in our own mini farm-like
                    garden, a thai herb garden located in Metro Manila.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className={!this.state.loading ? "featured_dishes" : "display_none"}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
            className="purple_wave_two display_none"
          >
            <path
              fill="#4d2dac"
              fillOpacity="1"
              d="M0,32L26.7,80C53.3,128,107,224,160,229.3C213.3,235,267,149,320,112C373.3,75,427,85,480,80C533.3,75,587,53,640,42.7C693.3,32,747,32,800,64C853.3,96,907,160,960,165.3C1013.3,171,1067,117,1120,85.3C1173.3,53,1227,43,1280,74.7C1333.3,107,1387,181,1413,218.7L1440,256L1440,320L1413.3,320C1386.7,320,1333,320,1280,320C1226.7,320,1173,320,1120,320C1066.7,320,1013,320,960,320C906.7,320,853,320,800,320C746.7,320,693,320,640,320C586.7,320,533,320,480,320C426.7,320,373,320,320,320C266.7,320,213,320,160,320C106.7,320,53,320,27,320L0,320Z"
            ></path>
          </svg>
        </section>
      </div>
    );
  }
}

export default landing;
