import React, { Component } from "react";
import RadioButton from "./radioButtons";
import LocationInput from "../locationInput/locationInput";
import "./form.css";
import { Redirect } from "react-router-dom";
import axios from "axios";

export class form extends Component {
  state = {
    user: this.props.user,
    email: this.props.user ? this.props.user.email : null,
    zipCode: null,
    cuisine: [],
    phone: this.props.user ? this.props.user.phone : null,
    address: this.props.user ? this.props.user.address : null, //Object.keys if null or [] don't allow submit otherwise patch request
    // typeOf: "",
    baker: false,
    cook: true,
    pantry: false,
    vegetarian: false,
    signup: true,
    loading: false,
    name: this.props.user ? this.props.user.name : null,
    socialMedia: "",
    pantryA: [],
    pickup: true,
    loginClick: false,
  };
  componentDidMount() {}
  componentDidUpdate(prevProps) {
    if (this.props.user && this.props.user.address !== prevProps.user.address) {
      // console.log("here at update _________");
      this.setState({ address: this.props.user.address });
    }
  }
  submitAndCheckDetails = () => {
    let returnEarly = false;
    let state = { ...this.state };
    // console.log("address", this.state.address);
    Object.keys(state).forEach((key) => {
      if (returnEarly) return;
      // console.log(this.state[key], key);
      if (
        this.state[key] === null ||
        this.state[key] === undefined ||
        this.state[key] === "" ||
        (Array.isArray(this.state[key]) && this.state[key].length === 0)
      ) {
        if (
          key !== "user" &&
          key !== "baker" &&
          key !== "pantry" &&
          key !== "vegetarian" &&
          key !== "pickup" &&
          key !== "loading" &&
          key !== "pantryA" &&
          key !== "socialMedia"
          //  && key != "address"
        ) {
          alert(`please enter all fields first, including the ${key} field`);
          returnEarly = true;
          return false;
        }
      }
    });
    this.setState({ loading: true });
    state.name = state.name ? state.name.toLowerCase() : null;
    delete state.loading;
    if (returnEarly) {
      this.setState({ loading: false });
      return;
    }
    // console.log("FORM SUBMIT", state);
    if (state.cuisine.includes("None")) {
      //doesn't really matter even if they have None or not.
      state.cuisine = [];
    }
    state.cuisine = state.cuisine.concat(this.state.pantryA);
    state.type = "homechef";
    delete state.pantryA;
    // console.log(state);
    //note that updateProfile will get rid of user field in any case :)
    axios({
      method: "patch",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/users/me`,
      data: state,
      withCredentials: true,
    }).then((response) => {
      if (response.data.status === "success") {
        this.props.setUser(response.data.data.user);
        this.setState({ loading: false });
        //MIGHT WANT TO ADD THE DELAY AND ALL
        alert(
          "An email has been sent to tizaar :) We will get back to you ASAP."
        );
        // console.log("email shoulda been sent");
        // <Redirect to="/" />; //not necessary should automatically render!
      } else {
        alert(
          "There was an issue recieving your form. Please email masterchef@tizaar.com that you want to become a chef/seller on the platform, and we'll take it from there."
        );
      }
    });
  };

  pantrySelect = async (cuisine, includes, cap) => {
    if (cap === 2) {
      if (this.state.pantryA.length >= 2) {
        if (includes) {
          //removal process
          let cuisines = [];
          if (this.state.pantryA[0] === cuisine) {
            cuisines.push(this.state.pantryA[1]);
          } else {
            cuisines.push(this.state.pantryA[0]);
          }
          await this.setState({ pantryA: cuisines });
          // console.log(this.state.pantryA);

          return;
        } else {
          //don't allow
          return;
        }
      } else {
        //for homechefs vs small chefs
        if (includes) {
          const cuisines = [];
          await this.setState({ pantryA: cuisines });
          // console.log(this.state.pantryA);
        } else {
          let cuisines = [...this.state.pantryA];
          cuisines.push(cuisine);
          await this.setState({ pantryA: cuisines });
          // console.log(this.state.pantryA);
        }
        return;
      }
    } else if (cap == 1) {
      this.setState({ typeOf: cuisine });
      //homechef vs small business
    }
  };

  cuisineSelect = async (cuisine, includes, cap) => {
    if (cuisine === "None") {
      this.setState({ cuisine: ["None"] });
    } else if (cap === 2) {
      if (this.state.cuisine.length >= 2) {
        if (includes) {
          //removal process
          let cuisines = [];
          if (this.state.cuisine[0] === cuisine) {
            cuisines.push(this.state.cuisine[1]);
          } else {
            cuisines.push(this.state.cuisine[0]);
          }
          await this.setState({ cuisine: cuisines });
          // console.log(this.state.cuisine);

          return;
        } else {
          //don't allow
          return;
        }
      } else {
        //for homechefs vs small chefs
        if (includes) {
          const cuisines = [];
          await this.setState({ cuisine: cuisines });
          // console.log(this.state.cuisine);
        } else {
          let cuisines;
          if (!this.state.cuisine.includes("None")) {
            cuisines = [...this.state.cuisine];
          } else {
            cuisines = [];
          }
          cuisines.push(cuisine);
          await this.setState({ cuisine: cuisines });
          // console.log(this.state.cuisine);
        }
        return;
      }
    } else if (cap == 1) {
      this.setState({ typeOf: cuisine });
      //homechef vs small business
    }
  };

  render() {
    if (!this.props.user && this.state.loginClick) {
      this.props.setHistoryLink("/homechef");
      // console.log("homechef is histo link");
      return <Redirect to="/login" />;
    }
    return (
      <form
        className={!this.props.user ? "form cursor" : "form"}
        onClick={() => {
          this.setState({ loginClick: true });
        }}
      >
        <div
          className={
            !this.props.user
              ? "login_button_form "
              : "none hide login_button_form"
          }
        >
          <b>
            Please Login First Before Submitting this Form to Become a Chef{" "}
            {"->"}{" "}
          </b>
        </div>
        <div
          className={
            !this.props.user
              ? "form_row_container opacity_gray"
              : "form_row_container"
          }
        >
          <div className="form_col">
            <div className="chef_input_fields">
              <p className="fieldName_chefInput">
                <b>Address</b>
              </p>
              <LocationInput
                updateLocation={this.props.updateUserLocation}
                user={this.props.user}
                date={new Date()}
                homechef={true}
              />
              {/* <input
                type="text"
                value={this.state.address}
                className="bubble_input address_input"
                placeholder="House Address/City/State"
                onChange={(e) => this.setState({ address: e.target.value })}
              /> */}
            </div>
            <div className="chef_input_fields">
              <p className="fieldName_chefInput">
                <b>
                  Are you a baker or a cook? If neither select the NONE option
                  for cooks/bakers below.{" "}
                </b>
              </p>
              <div
                className="radio_container_f"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div
                  className={
                    this.state.cook ? "yes_button_f active_yes" : "yes_button_f"
                  }
                  onClick={() => {
                    if (this.state.cook) return;
                    this.setState({ cook: true, baker: false, cuisine: [] });
                  }}
                >
                  Cook
                </div>
                <div
                  className={
                    this.state.baker
                      ? "yes_button_f active_no no_baker"
                      : "yes_button_f"
                  }
                  onClick={() => {
                    if (this.state.baker) return;
                    this.setState({ baker: true, cook: false, cuisine: [] });
                  }}
                >
                  Baker
                </div>
              </div>
            </div>
            <div className="chef_input_fields">
              <p className="fieldName_chefInput">
                <b>Cuisine (select upto 2)</b>
              </p>
              {this.state.baker ? (
                <div className="input_radios">
                  <RadioButton
                    name="Pie"
                    clicked={this.cuisineSelect}
                    selected={this.state.cuisine}
                    cap={2}
                  />
                  <RadioButton
                    name="Macaroons"
                    clicked={this.cuisineSelect}
                    selected={this.state.cuisine}
                    cap={2}
                  />
                  <RadioButton
                    name="Baked Sushi"
                    clicked={this.cuisineSelect}
                    selected={this.state.cuisine}
                    cap={2}
                  />
                  <RadioButton
                    name="Donuts"
                    clicked={this.cuisineSelect}
                    selected={this.state.cuisine}
                    cap={2}
                  />
                  <RadioButton
                    name="Breads"
                    clicked={this.cuisineSelect}
                    selected={this.state.cuisine}
                    cap={2}
                  />
                  <RadioButton
                    name="Cakes"
                    clicked={this.cuisineSelect}
                    selected={this.state.cuisine}
                    cap={2}
                  />
                  <RadioButton
                    name="Other"
                    clicked={this.cuisineSelect}
                    selected={this.state.cuisine}
                    cap={2}
                  />
                  <RadioButton
                    name="None"
                    clicked={this.cuisineSelect}
                    selected={this.state.cuisine}
                    cap={2}
                  />
                </div>
              ) : (
                <div className="input_radios">
                  <RadioButton
                    name="Filipino"
                    clicked={this.cuisineSelect}
                    selected={this.state.cuisine}
                    cap={2}
                  />
                  <RadioButton
                    name="South Asian"
                    clicked={this.cuisineSelect}
                    selected={this.state.cuisine}
                    cap={2}
                  />
                  <RadioButton
                    name="Chinese"
                    clicked={this.cuisineSelect}
                    selected={this.state.cuisine}
                    cap={2}
                  />
                  <RadioButton
                    name="Mexican"
                    clicked={this.cuisineSelect}
                    selected={this.state.cuisine}
                    cap={2}
                  />
                  <RadioButton
                    name="Korean"
                    clicked={this.cuisineSelect}
                    selected={this.state.cuisine}
                    cap={2}
                  />
                  <RadioButton
                    name="Thai"
                    clicked={this.cuisineSelect}
                    selected={this.state.cuisine}
                    cap={2}
                  />
                  <RadioButton
                    name="Middle Eastern"
                    clicked={this.cuisineSelect}
                    selected={this.state.cuisine}
                    cap={2}
                  />
                  <RadioButton
                    name="Italian"
                    clicked={this.cuisineSelect}
                    selected={this.state.cuisine}
                    cap={2}
                  />
                  <RadioButton
                    name="Japanese"
                    clicked={this.cuisineSelect}
                    selected={this.state.cuisine}
                    cap={2}
                  />
                  <RadioButton
                    name="Spanish"
                    clicked={this.cuisineSelect}
                    selected={this.state.cuisine}
                    cap={2}
                  />
                  <RadioButton
                    name="Vietnamese"
                    clicked={this.cuisineSelect}
                    selected={this.state.cuisine}
                    cap={2}
                  />
                  <RadioButton
                    name="American"
                    clicked={this.cuisineSelect}
                    selected={this.state.cuisine}
                    cap={2}
                  />
                  <RadioButton
                    name="Other"
                    clicked={this.cuisineSelect}
                    selected={this.state.cuisine}
                    cap={2}
                  />
                  <RadioButton
                    name="None"
                    clicked={this.cuisineSelect}
                    selected={this.state.cuisine}
                    cap={2}
                  />
                </div>
              )}
            </div>
            <div className="chef_input_fields">
              <p className="fieldName_chefInput">
                <b>ZIP CODE</b>
              </p>
              <input
                type="text"
                className="bubble_input zip_input"
                placeholder="Enter your ZIP CODE"
                onChange={(e) => this.setState({ zipCode: e.target.value })}
              />
            </div>
          </div>
          <div className="form_col">
            <div className="chef_input_fields">
              <p className="fieldName_chefInput">
                <b>Name</b>
              </p>
              <input
                type="text"
                value={this.state.name}
                className="bubble_input phone_input"
                placeholder="What's your name?"
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </div>
            <div className="chef_input_fields">
              <p className="fieldName_chefInput">
                <b>Phone Number (10 Digits)</b>
              </p>
              <span className="phone_precursor">+63| </span>
              <input
                type="text"
                value={this.state.phone}
                className="bubble_input phone_input phone_input_form"
                maxLength={10}
                placeholder="Enter your phone number"
                onChange={(e) => {
                  this.setState({ phone: e.target.value });
                }}
              />
            </div>
            <div className="chef_input_fields">
              <p className="fieldName_chefInput">
                <b>
                  Will you be selling ingredients, sauces, or ready made food?
                </b>
              </p>
              <div
                className="radio_container_f"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div
                  className={
                    this.state.pantry
                      ? "yes_button_f active_yes"
                      : "yes_button_f"
                  }
                  onClick={() => {
                    if (this.state.pantry) {
                      return;
                    }
                    this.setState({ pantry: true, pantryA: [] });
                  }}
                >
                  Yes
                </div>
                <div
                  className={
                    !this.state.pantry
                      ? "yes_button_f active_no"
                      : "yes_button_f"
                  }
                  onClick={() => {
                    if (!this.state.pantry) {
                      return;
                    }
                    this.setState({ pantry: false, pantryA: [] });
                  }}
                >
                  No
                </div>
              </div>

              <div className="chef_input_fields">
                {this.state.pantry ? (
                  <div>
                    <p className="fieldName_chefInput">
                      <b>Pantry (select upto 2)</b>
                    </p>
                    <div className="input_radios">
                      <RadioButton
                        name="Sauces and Spreads"
                        clicked={this.pantrySelect}
                        selected={this.state.pantryA}
                        cap={2}
                      />
                      <RadioButton
                        name="Dairy"
                        clicked={this.pantrySelect}
                        selected={this.state.pantryA}
                        cap={2}
                      />
                      <RadioButton
                        name="Fresh"
                        clicked={this.pantrySelect}
                        selected={this.state.pantryA}
                        cap={2}
                      />
                      <RadioButton
                        name="Beverages"
                        clicked={this.pantrySelect}
                        selected={this.state.pantryA}
                        cap={2}
                      />
                      <RadioButton
                        name="Batters and Doughs"
                        clicked={this.pantrySelect}
                        selected={this.state.pantryA}
                        cap={2}
                      />
                      <RadioButton
                        name="Drinks"
                        clicked={this.pantrySelect}
                        selected={this.state.pantryA}
                        cap={2}
                      />
                    </div>
                  </div>
                ) : (
                  <div> </div>
                )}
              </div>
            </div>
            <div className="chef_input_fields">
              <p className="fieldName_chefInput">
                <b>Will you mainly be cooking vegetarian food?</b>
              </p>
              <div
                className="radio_container_f"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div
                  className={
                    this.state.vegetarian
                      ? "yes_button_f active_yes"
                      : "yes_button_f"
                  }
                  onClick={() => this.setState({ vegetarian: true })}
                >
                  Yes
                </div>
                <div
                  className={
                    !this.state.vegetarian
                      ? "yes_button_f active_no"
                      : "yes_button_f"
                  }
                  onClick={() => this.setState({ vegetarian: false })}
                >
                  No
                </div>
              </div>
              <p className="fieldName_chefInput">
                <b>
                  Are you willing to do pickup orders? (Most orders will be from
                  your neighbors so this will be a big benefit!)
                </b>
              </p>
              <div
                className="radio_container_f"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div
                  className={
                    this.state.pickup
                      ? "yes_button_f active_yes"
                      : "yes_button_f"
                  }
                  onClick={() => this.setState({ pickup: true })}
                >
                  Yes
                </div>
                <div
                  className={
                    !this.state.pickup
                      ? "yes_button_f active_no"
                      : "yes_button_f"
                  }
                  onClick={() => this.setState({ pickup: false })}
                >
                  No
                </div>
              </div>
              <div className="chef_input_fields">
                <p className="fieldName_chefInput">
                  <b>Existing Social Media (OPTIONAL)</b>
                </p>
                <input
                  type="text"
                  value={this.state.socialMedia}
                  className="bubble_input phone_input"
                  placeholder="IF you have a social media page, put it here."
                  onChange={(e) =>
                    this.setState({ socialMedia: e.target.value })
                  }
                />
              </div>
            </div>
            {/* <div className="chef_input_fields">
              <p className="fieldName_chefInput">
                <b>Independent/Small Business</b>
              </p>
              <div className="input_radios">
                <RadioButton
                  name="Independent"
                  clicked={this.cuisineSelect}
                  selected={[this.state.typeOf]}
                  cap={1}
                />
                <RadioButton
                  name="Small Business"
                  clicked={this.cuisineSelect}
                  selected={[this.state.typeOf]}
                  cap={1}
                />
              </div>
            </div> */}
            <div className="chef_input_fields">
              <p className="fieldName_chefInput">
                <b>Email</b>
              </p>
              <input
                type="text"
                className="bubble_input email_input"
                placeholder="Enter your email"
                value={this.state.email}
                onChange={(e) => {
                  this.setState({ email: e.target.value });
                }}
              />
              {/* auto fill if email exists */}
            </div>
          </div>
        </div>
        {!this.props.user ? null : (
          <p style={{ textAlign: "center" }}>
            <b>
              You will recieve an email and message from us within around 24
              hours on how to proceed with the taste testing.
            </b>{" "}
          </p>
        )}
        <div
          className={
            !this.props.user
              ? "button chef_detail_submit none"
              : "button chef_detail_submit"
          }
          onClick={this.submitAndCheckDetails}
          style={{ textAlign: "center" }}
        >
          {this.state.loading ? "Loading..." : "Submit"}
        </div>
      </form>
    );
  }
}

export default form;
