import React, { Component } from "react";
import GoogleLogin from "react-google-login";
import { NavLink, Redirect, Route } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

import "./login.css";

export class login extends Component {
  state = {
    email: "",
    password: "",
    loading: false,
    redirectIssue: false,
  };

  responseGoogle = (response) => {
    // console.log(response);
    this.setState({ email: response.profileObj.email, loading: true });
    if (response.tokenId) {
      axios({
        method: "post",
        url: `${
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_URL
            : "http://localhost:8080"
        }/api/v1/users/googleSignIn`,
        data: { tokenId: response.tokenId },
        withCredentials: true,
      }).then((response) => {
        this.props.setUser(response.data.data.user);
        this.setState({ loading: false });
      });
    }
  };

  responseGoogleFail = (response) => {
    alert(
      "There was an issue with your google login please sign up manually or try again"
    );
  };

  setPassword = (e) => {
    this.setState({ password: e.target.value });
  };

  setEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  submitLogin = () => {
    //POST REQUEST HERE FOR PROTECTION with email and password
    // console.log("submit logn");
    this.setState({ loading: true });
    axios({
      method: "post",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/users/login`,
      data: {
        email: this.state.email,
        password: this.state.password,
      },
      withCredentials: true,
    })
      .then((response) => {
        // console.log(response, response.status);
        if (response.data.status === "success") {
          // console.log(response.data.data);
          // console.log(this.props.setLoading);
          this.props.setUser(response.data.data.user);
          // this.props.setLoading(false);
          // this.props.setLoading(true);
          this.setState({ loading: false });
        } else {
          alert("There was an issue regarding your login!");
          this.setState({ loading: false });
          // window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
        alert(
          "There was an error with your login, please make sure your username and password are correct"
        );
        this.setState({ loading: false });
        // window.location.reload();
      });
    //if success
    // this.props.setUser(user)
  };

  // sendForgotEmail = () => {
  //   alert(
  //     "A link to reset your password will arrive to your email shortly. Please do this ASAP for security purposes."
  //   );
  // };

  render() {
    // if (this.state.redirectIssue)
    //   return (
    //     <div>
    //       <Redirect to="/login" />
    //     </div>
    //   );
    return (
      <div className="login_container">
        <Helmet>
          <title>tizaar - Login or Signup</title>
          <meta
            name="description"
            content="Login or signup to tizaar to see what homecooked meals your neighbors are cooking!"
          />
        </Helmet>
        <div className="left_login_cont">
          <p className="signup_login_text">
            Don't have a login?
            <NavLink to="/signup">
              <span className="sign_up_text">
                <b> Sign up</b>
              </span>
            </NavLink>
          </p>
          <div className="main_login_cont">
            <h1 style={{ color: "#6d6d6d", fontFamily: "landingFontTwo" }}>
              Login
            </h1>
            <div className="sign_up_google_btn">
              <GoogleLogin
                clientId="176704683569-qc46nbriadugqt1a2k9nme5oph80jjgl.apps.googleusercontent.com"
                buttonText="Login with Google"
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogleFail}
                cookiePolicy={"single_host_origin"}
                className="google_login"
              />
            </div>
            <form onSubmit={this.submitLogin} action="#">
              <div className="username_bubble_div bubble_login_div">
                <p>
                  <b>Email</b>
                </p>
                <input
                  type="email"
                  className="Please enter your email"
                  onChange={this.setEmail}
                />
              </div>
              <div className="password_bubble_div bubble_login_div">
                <p>
                  <b>Password</b>
                </p>
                <div className="password_wrapper">
                  <input
                    type="password"
                    className="password_input input_bubble_login"
                    onChange={this.setPassword}
                  />
                  <NavLink to="/forgotPasswordStageOne">
                    <p className="forgotPassword">
                      <b>Forgot password?</b>
                    </p>
                  </NavLink>
                </div>
              </div>
              <button
                style={{ width: "100%" }}
                type="submit"
                className="login_btn"
                // onSubmit={this.submitLogin}
                onClick={(e) => {
                  e.preventDefault();
                  this.submitLogin();
                }}
              >
                <b>{this.state.loading ? "Loading..." : "Login"}</b>
              </button>
            </form>

            {/* <h1 className="welcome">Welcome to tizaar</h1> */}
          </div>
        </div>
        <div className="wrapper_login">
          <div
            className="right_login_cont image"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/landing/chef.png)`,
            }}
          ></div>
        </div>
      </div>
    );
  }
}

export default login;
