import React, { Component } from "react";
import "./menuDatePicker.css";

export class menuDatePicker extends Component {
  state = {
    menus: [
      {
        day: "Mon",
        dishes: [{}],
      },
      { day: "Wed", dishes: [{}] },
      { day: "Fri", dishes: [{}] },
    ],
  };

  //ALL DATE LOGIC NEEDS TO BE MOVED HERE! JUST DO IT FOR EASE OF BACKEND
  render() {
    if (!this.state.menus) {
      return (
        <p>
          The chef doesn't appear to have their menu ready yet. Or an error
          occured.
        </p>
      );
    } else {
      return (
        <div className="flex_date_container_m">
          {this.state.menus.map((tuple) => {
            <div className="date_menu_bubble">{tuple.day}</div>;
          })}
        </div>
      );
    }
  }
}

export default menuDatePicker;
