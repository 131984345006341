import React, { Component } from "react";
import "./formCreate.css";
import axios from "axios";

export class formCreate extends Component {
  state = {
    dates: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    taglines: [
      "spice",
      "dairy",
      "vegetarian",
      "nuts",
      "vegan",
      "glutenFree",
      "sugar-free",
    ],
    dish: { ...this.props.dish },
    showEditPopup: false,
    imageLoading: false,
    photoUpdate: null,
    note: null,
  };

  dayMap = {
    Mon: 0,
    Tue: 1,
    Wed: 2,
    Thu: 3,
    Fri: 4,
    Sat: 5,
    Sun: 6,
  };

  iconMap = {
    dairy: (
      <img
        src={`${process.env.PUBLIC_URL}/menu/dairy.svg`}
        style={{ width: "16px", height: "16px" }}
        alt=""
      />
    ),
    spice: <i className="fas fa-pepper-hot" style={{ color: "red" }}></i>,
    vegetarian: <i className="fas fa-leaf" style={{ color: "green" }}></i>,
    nuts: (
      <img
        src={`${process.env.PUBLIC_URL}/menu/nuts.png`}
        style={{ width: "16px", height: "16px" }}
        alt=""
      />
    ),
    vegan: (
      <img
        src={`${process.env.PUBLIC_URL}/menu/vegan.svg`}
        style={{ width: "16px", height: "16px" }}
        alt=""
      />
    ),
    glutenFree: (
      <img
        src={`${process.env.PUBLIC_URL}/menu/wheat.png`}
        style={{ width: "16px", height: "16px" }}
        alt=""
        key="gluten"
        data-tooltip="GLUTEN-FREE"
      />
    ),
    "sugar-free": (
      <img
        src={`${process.env.PUBLIC_URL}/menu/sugar-free.png`}
        style={{ width: "16px", height: "16px" }}
        alt=""
        key="sugar-free"
        data-tooltip="SUGAR-FREE"
      />
    ),
  };

  dateNames = {
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday",
    Sat: "Saturday",
    Sun: "Sunday",
  };

  dateMap = {
    Mon: 0,
    Tue: 1,
    Wed: 2,
    Thu: 3,
    Fri: 4,
    Sat: 5,
    Sun: 6,
  };

  componentDidMount() {
    this.setState({ dish: { ...this.props.dish } });
    // // console.log(`SHOW EDIT POPUP ${this.props.showEditPopup}`);
    // this.setState({ showEditPopup: this.props.showEditPopup });
    // var preview = document.querySelector(".chef_image_dish_edit");
  }

  // defValues = () => {
  //   var name = document.getElementById("dishNameId");
  //   var description = document.querySelector(".about_dish_input_main");
  //   var allergyDescription = document.querySelector(".allergy_dish_input");
  //   // console.log("TESTING FOR DISH AUTOFILL");
  //   // console.log(this.state.dish.name);
  //   name.value = this.state.dish.name;
  //   description.value = this.state.dish.description;
  //   allergyDescription.value = this.state.dish.allergyDescription;
  // WHY NO WORK???};

  changeDishName = (e) => {
    var name = e.target.value;
    // console.log(name);
    let dish = { ...this.state.dish };
    dish.name = name;
    this.setState({ dish });
  };

  checkFileType = (file) => {
    return (
      file &&
      (file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png")
    );
  };

  uploadDishImage = (e) => {
    this.setState({ imageLoading: true }, () => {
      // console.log(this.state.imageLoading);
    });
    // // console.log("I just set the imageLoading to true!");
    var bodyFormData = new FormData();
    var imageFile = e.target.files[0];
    if (!this.checkFileType(imageFile)) {
      alert("You must put a jpg/jpeg or png image");
      this.setState({ imageLoading: false });
      return;
    }
    // console.log(imageFile, e);
    //ONLY ALLOW JpGs!!!
    let self = this;
    bodyFormData.append("image", imageFile);
    bodyFormData.append("name", this.state.dish.name.toLowerCase());
    bodyFormData.append("quantity", this.state.dish.servingSize);

    // bodyFormData.append("edit", this.props.edit);
    //if edit then wont be able to find dish and upload photo before it's created. We just set the dishPhoto to the link, and as soon as it's created using post middleware we findById and set it to dish.photo
    axios({
      method: "post",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/images/dish`,
      data: bodyFormData,
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=<calculated when request is sent>",
      },
      withCredentials: true,
    })
      .then(function (response) {
        let imageUrl = response.data.imageUrl;
        let dish = { ...self.state.dish };
        dish.photo = imageUrl;
        // // console.log("DISH", dish);
        self.setState({ dish, imageLoading: false, photoUpdate: Date.now() });
        // // console.log("DISH STATE", self.state.dish);
      })
      .catch(function (response) {
        self.setState({ imageLoading: false });
        console.log(response);
        alert("There was an error in uploading the image");
      });
    e.target.value = null;
  };

  updateTag = (add, tag) => {
    let dish = { ...this.state.dish };
    if (add) {
      dish.tags.push(tag);
    } else {
      //INCLUDES ALR, REMOVE!
      let tags = [];
      this.state.dish.tags.forEach((t) => {
        if (t !== tag) {
          tags.push(t);
        }
      });
      dish.tags = tags;
    }
    this.setState({ dish });
  };

  updateDishDates = (add, date) => {
    let index = this.dayMap[date];
    let dish = { ...this.state.dish };
    if (add) {
      dish.deliveryDates[index] = date;
    } else {
      dish.deliveryDates[index] = "";
    }
    this.setState({ dish });
  };

  updateDescription = (e) => {
    var description = e.target.value;
    let dish = { ...this.state.dish };
    dish.description = description;
    this.setState({ dish });
  };

  updateAllergyDescription = (e) => {
    var allergy = e.target.value;
    let dish = { ...this.state.dish };
    dish.allergyDescription = allergy;
    this.setState({ dish });
  };

  toggleStock = (stock) => {
    //stock means which button clicked, instock = true
    let dish = { ...this.state.dish };
    if (stock && !dish.stock) {
      dish.stock = !dish.stock; //true
    } else if (!stock && dish.stock) {
      dish.stock = !dish.stock; //false
    } else {
      return;
    }
    this.setState({ dish });
  };

  updatePrice = (e) => {
    var price = JSON.parse(e.target.value);
    let dish = { ...this.state.dish };
    dish.price = price;
    this.setState({ dish });
  };

  checkForDeliveryDates = () => {
    var count = 0;
    this.state.dish.deliveryDates.forEach((date) => {
      if (date === "") {
        count = count + 1;
      }
      // console.log(date, count);
    });
    // console.log(count); //why no work?
    return !(count === 7);
  };

  sendUpdatedDish = () => {
    //CREATE NEW
    var name = document.querySelector(".name_dish_edit_input"); //problem is that it's getting first one
    // console.log(name, this.state.dish.name);
    // var description = document.querySelector(".about_dish_input_main");
    // var price = document.querySelector(".price_dish_input");
    // var unit = document.querySelector(".dish_unit");
    // console.log("SUBMIT");
    var datesExist = this.checkForDeliveryDates();
    if (this.state.dish.name === "") {
      alert("Please fill up the dish name!");
    } else if (this.state.dish.description === "") {
      alert("Please fill up the description to tell us about your food.");
    } else if (this.state.dish.price === "" || !this.state.dish.price) {
      alert("Please put in a price!");
    } else if (!datesExist) {
      alert(
        "You haven't selected delivery/cooking dates for when people can place orders!"
      );
    } else if (this.state.dish.unit === "") {
      alert("Please put in a unit for your serving size");
    } else {
      console.log(
        "THIS IS THE DISH NOW",
        this.state.dish.todayStock,
        this.state.dish.now
      );
      // console.log("DISH IS UPDATING...", this.state.dish);
      this.props.updateDish(this.state.dish);
      this.setState({
        dish: {
          photo: "",
          name: "",
          deliveryDates: ["", "", "", "", "", "", ""],
          tags: [],
          description: "",
          price: 0,
          allergyDescription: "",
          stockQuantity: [false, false, false, false, false, false, false],
          groupPromo: false,
          servingSize: 1,
          unit: "",
          price: "",
          weeklyStock: 0,
        },
      });
      // this.setState({ dish: this.props.dish });
    }
  };

  updateWeeklyStock = (e) => {
    let dish = { ...this.state.dish };
    if (e.target.value && e.target.value !== "") {
      dish.weeklyStock = JSON.parse(e.target.value);
    } else {
      dish.weeklyStock = null;
    }
    this.setState({ dish });
  };

  changeStockAvailable = (val, index) => {
    let dish = { ...this.state.dish };
    dish.stockQuantity[index] = JSON.parse(val);
    // console.log("index", index, "val", val);
    // console.log(dish.stockQuantity[index]);
    this.setState({ dish });
  };

  setImmediateT = (bool) => {
    if (bool === this.state.dish.immediate) {
      return;
    }
    let dish = { ...this.state.dish };
    dish.immediate = bool;
    this.setState({ dish });
  };

  setGroup = (groupOrder) => {
    if (groupOrder === this.state.dish.groupPromo) {
      return;
    }
    let dish = { ...this.state.dish };
    dish.groupPromo = groupOrder;
    this.setState({ dish });
  };

  setServingSize = (e) => {
    // console.log(e.target.value);
    let dish = { ...this.state.dish };
    if (e.target.value && e.target.value !== "") {
      dish.servingSize = JSON.parse(e.target.value);
    } else {
      dish.servingSize = "";
    }
    this.setState({ dish });
  };

  setNow = (bool) => {
    let dish = { ...this.state.dish };
    dish.now = bool;
    this.setState({ dish });
  };

  changeTodayStockAvailable = (val) => {
    let dish = { ...this.state.dish };
    dish.todayStock = JSON.parse(val);
    // console.log(dish.todayStock);
    this.setState({ dish });
  };

  setTheUnit = (e) => {
    let dish = { ...this.state.dish };
    dish.unit = e.target.value;
    this.setState({ dish });
  };

  render() {
    const mandatory = <p className="mandatory">*</p>;
    //ORDER this.props.menuDates
    // // console.log("RENDERING AGAIN", this.state.dish);
    return (
      <form
        className="detailPopup"
        style={{
          position: "fixed",
          display:
            this.props.showEditPopup || this.props.submissionLoad
              ? "block"
              : "none", //showEditPopup state here not necessary
        }}
      >
        <div className="top_header_detail">
          <div className="dish_detail_header">
            {this.props.edit ? "Edit " : "Create "} {this.state.dish.name}
          </div>
          <div className="close_button">
            <i className="fas fa-times" onClick={this.props.cancelForm}></i>
          </div>
        </div>
        <div className="content">
          <span style={{ color: "red", fontSize: "15px" }}>
            ***Note:{" "}
            <b>
              Scroll to the bottom and press save for changes to persist/apply
              after reload. Only the edited fields will be updated.
            </b>
          </span>
          <div className="name_dish_edit dish_edit">
            <p>
              <b style={{ position: "relative" }}>Edit Dish Name {mandatory}</b>
            </p>
            <input
              type="text"
              className="name_dish_edit_input edit_input_text"
              id="dishNameId"
              placeholder="Edit Dish Name Here"
              onChange={this.changeDishName}
              value={this.state.dish.name}
            />
          </div>
          <div className="delivery_dish_edit dish_edit">
            <p>
              <b>Select Menu/Delivery Dates</b>
            </p>
            <div className="select_delivery_edit_dish days">
              {this.props.menuDates.map((date, i) => {
                // console.log(this.props.menuDates, date);
                if (
                  // this.props.menuDates[date] &&
                  this.state.dish.deliveryDates.includes(date)
                ) {
                  return (
                    <div
                      className={`date_bubble_menu active_bubble ${date}_bubble`}
                      onClick={() => {
                        this.updateDishDates(false, date);
                      }}
                      key={i}
                    >
                      {date}
                    </div>
                  );
                  //}
                } else {
                  return (
                    <div
                      className={`date_bubble_menu ${date}_bubble`}
                      onClick={() => {
                        this.updateDishDates(true, date, i);
                      }}
                      key={i}
                    >
                      {date}
                    </div>
                  );
                }
              })}
              {this.props.today ? ( //only is user has today as a menu option enabled.
                this.state.dish.now ? ( //then now is if it is there or not.
                  <div
                    className={`date_bubble_menu active_bubble NOW_bubble`}
                    onClick={() => this.setNow(false)}
                  >
                    TODAY
                  </div>
                ) : (
                  <div
                    className={`date_bubble_menu NOW_bubble`}
                    onClick={() => this.setNow(true)}
                  >
                    TODAY
                  </div>
                )
              ) : (
                <div></div>
              )}
            </div>
          </div>
          {/* NOW FUNCTIONALITY POPUP */}
          {this.state.dish.now ? (
            <p
              className="note today_note"
              style={{ color: "red", fontSize: "15px", marginBottom: "10px" }}
            >
              <b>TODAY ORDERS NOTE:</b> Make sure you update your stock for
              today and that you go to your profile page to indicate when your
              working hours are/when you want to recieve deliveries. Keep track
              of how many orders you're getting.{" "}
              <b>If you get too many orders in a given time period</b>, you can
              go to your profile and de-select or remove that particular time
              block for delivery/pickup for today's day of the week, i.e{" "}
              {new Date().getDay()}. You can also put the dish out of stock, by
              setting todays stock to 0. <b>This has to be updated daily!</b>
            </p>
          ) : (
            <div></div>
          )}
          {/* STOCK! */}
          <div className="stock_dish_edit dish_edit">
            <p>
              <b>Edit Stock Availablity: </b>
            </p>
            <div className="flex_stock_option">
              <div className="date_set">
                {this.state.dish.deliveryDates.map((date, i) => {
                  if (
                    date != "" &&
                    date != null &&
                    this.props.menuDates.includes(date)
                  ) {
                    return (
                      <div className="mon_stock" key={i}>
                        <h1 className="stock_date_title">
                          Stock for <b>{this.dateNames[date]}</b>
                        </h1>

                        {this.state.dish.stockQuantity[this.dateMap[date]] ? (
                          <select
                            name="choice"
                            className="stock_date_input monday_stock"
                            onChange={(e) =>
                              this.changeStockAvailable(
                                e.target.value,
                                this.dateMap[date]
                              )
                            }
                            defaultValue={true}
                          >
                            <option value={false}>Out of Stock</option>
                            <option value={true}>In Stock</option>
                          </select>
                        ) : (
                          <select
                            name="choice"
                            className="stock_date_input monday_stock"
                            onChange={(e) =>
                              this.changeStockAvailable(
                                e.target.value,
                                this.dateMap[date]
                              )
                            }
                            defaultValue={false}
                          >
                            <option value={false}>Out of Stock</option>
                            <option value={true}>In Stock</option>
                          </select>
                        )}
                      </div>
                    );
                  } else return <div style={{ display: "none" }} key={i}></div>;
                })}
                {this.state.dish.now ? (
                  <div className="today_stock">
                    <h1 className="stock_date_title">
                      Stock for <b>TODAY</b>
                    </h1>
                    {this.state.dish.todayStock ? (
                      <select
                        name="choice"
                        className="stock_date_input monday_stock"
                        onChange={(e) => this.changeTodayStockAvailable(false)}
                      >
                        <option value={false}>Out of Stock</option>
                        <option value={true} selected>
                          In Stock
                        </option>
                      </select>
                    ) : (
                      <select
                        name="choice"
                        className="stock_date_input monday_stock"
                        onChange={(e) => this.changeTodayStockAvailable(true)}
                      >
                        <option value={false} selected>
                          Out of Stock
                        </option>
                        <option value={true}>In Stock</option>
                      </select>
                    )}
                  </div>
                ) : (
                  <div style={{ display: "none" }}></div> //now is the selected date.
                )}
              </div>
            </div>
          </div>
          <span
            style={{
              fontSize: "14px",
              color: "red",
              fontFamily: "landingFontTwo",
            }}
          >
            {/* <b>
              IF you don't plan on delivering any/anymore orders for a given
              day, then put 0 for stock in that given day. IF you don't want to
              limit stock put a high number like 1000.
            </b> */}
            Remember to press the save button at the bottom, otherwise, this
            wont be saved!!!
          </span>
          {/* <div>
            <p style={{ fontSize: "18px" }}>
              Average cooking time(optional)
            </p>
            
          </div> */}

          {/* {this.props.pantry ? (
            <div className="weekly_stock flex">
              <p style={{ fontSize: "18px", paddingRight: "20px" }}>
                <b>Weekly Order Stock (OPTIONAL)</b>
              </p>
              <input
                type="number"
                placeholder="Order Stock"
                onChange={(e) => this.updateWeeklyStock(e)}
                className="serving_size_form"
                value={this.state.dish.weeklyStock}
                min={0}
              />
            </div>
          ) : null} */}
          <div>
            <p style={{ fontSize: "18px" }}>
              <b>Meal Tray/Promo Section: </b>Do you want to put this in your
              meal tray & promo section? I.e, this is for menu items which offer
              a packaged complete meal or for menu items being sold at large
              quantities and usually at a slightly discounted price.
            </p>
            <div className="group_order_buttons">
              <div
                className={
                  this.state.dish.groupPromo
                    ? "yes_group active_group_btn"
                    : "yes_group"
                }
                onClick={() => this.setGroup(true)}
              >
                YES
              </div>
              <div
                className={
                  !this.state.dish.groupPromo
                    ? "yes_group no_group active_group_btn"
                    : "yes_group no_group"
                }
                onClick={() => this.setGroup(false)}
              >
                NO
              </div>
            </div>
          </div>
          <div>
            <p style={{ fontSize: "18px", marginTop: "10px" }}>
              <b>Assorted Menu Items: </b>
              Is this dish representative of an assorted box of food? I.e A box
              of 12 macaroons of two different flavors. If it is, please type a
              note to ask the customers to fill out the flavors they want)
            </p>
            <textarea
              row="20"
              cols="50"
              type="text"
              value={this.state.dish.note}
              placeholder="I.e Please type out the two flavors you would like. The flavors will be split equally in quantity."
              onChange={(e) => {
                let dish = { ...this.state.dish };
                dish.note = e.target.value;
                this.setState({ dish });
              }}
              className="text-area-assorted"
            ></textarea>
          </div>
          <div
            className="servingSize dish-edit"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p style={{ marginRight: "10px" }}>
              <b style={{ marginBottom: "10px" }}>Serving Size: </b>
            </p>
            {/* {this.state.dish.groupPromo ? ( */}
            <input
              type="number"
              placeholder="# of People/Pieces"
              onChange={this.setServingSize}
              className="serving_size_form"
              value={this.state.dish.servingSize}
              min={0}
            />
            {/* ) : (
              <input
                type="number"
                placeholder="# of People/Pieces"
                className="serving_size_form"
                value={1}
              />
            )} */}
            <input
              type="text"
              placeholder="people, pieces, rolls, etc.."
              value={this.state.dish.unit}
              className="dish_unit"
              onChange={this.setTheUnit}
            />
            {mandatory}
          </div>
          {/* <p style={{ marginRight: "10px", marginBottom: "10px" }}>
            <b style={{ color: "red", fontSize: "15px" }}>
              If this is not a group order the serving quantity must be for a
              single person/piece
            </b>
          </p> */}
          <div className="photo_dish_edit dish_edit">
            <p>
              {/* <b> */}
              {this.state.imageLoading
                ? "Image Upload is Loading..."
                : "Edit Photo"}
              {/* </b> */}
            </p>
            {/* ADD BACK THE EASY FILE UPLOAD TO MAKE IT APPEAR LIKE IT WORKS */}
            {/* {// console.log("INTERVAL", this.state.dish)} */}
            <div
              className="chef_image_dish_edit image"
              style={{
                backgroundImage: `url(${this.state.dish.photo}?${this.state.photoUpdate})`,
              }}
            >
              <label htmlFor={`upload_dish_img_${this.props.edit}`}>
                <i className="far fa-images edit_dish_photo"></i>
              </label>
              <input
                type="file"
                id={`upload_dish_img_${this.props.edit}`}
                onChange={this.uploadDishImage}
              />
            </div>
            {/* <div className="note">
              This is saved as soon as you select and upload the image.
              Everything else is only saved upon clicking the save button below{" "}
            </div> */}
          </div>
          {/* <input type="number" className="hour_prep" placeholder/> */}
          <div className="taglines_dish_edit dish_edit">
            <p style={{ marginRight: "50px" }}>
              <b>Edit Tags</b>
            </p>
            <div className="dish_tagline_btn">
              {this.state.taglines.map((tag, i) => {
                if (Array.from(this.state.dish.tags).includes(tag)) {
                  return (
                    <div
                      className="tag_image active_tag"
                      onClick={() => {
                        this.updateTag(false, tag);
                      }}
                      key={i}
                    >
                      {this.iconMap[tag]}
                      {tag}
                    </div>
                  );
                } else {
                  return (
                    <div
                      className="tag_image"
                      onClick={() => {
                        this.updateTag(true, tag);
                      }}
                      key={tag}
                    >
                      {this.iconMap[tag]}
                      {tag}
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className="about_dish_edit dish_edit">
            <p>
              <b>Edit About</b> <br />{" "}
            </p>
            <textarea
              type="text"
              className="about_dish_input about_dish_input_main"
              placeholder="Tell us about your dish..."
              onChange={this.updateDescription}
              value={this.state.dish.description}
            />
          </div>
          <div className="allergy_warnings dish_edit">
            <p>
              <b>Edit Allergy Warnings</b> <br />
            </p>
            <textarea
              type="text"
              className="about_dish_input allergy_dish_input"
              placeholder="Please let your customers know if they should be concerned about any allergy issues..."
              onChange={this.updateAllergyDescription}
              value={this.state.dish.allergyDescription}
            />
          </div>
          <div className="price_dish_edit dish_edit">
            <div>
              <b>Edit Price: </b>{" "}
              {false ? ( //this.props.edit before
                <span>
                  (Please email masterchef@tizaar.com with subject "price
                  change". Simply inform us about the dish name for which the
                  price is being changed, and what the new price should be).
                </span>
              ) : (
                <div>
                  <input
                    type="number"
                    className="price_dish_input"
                    placeholder="Enter Price here"
                    value={this.state.dish.price}
                    onChange={this.updatePrice}
                  />
                  <span className="span-block">
                    Please fill this in correctly. You will have to email us to
                    edit your price. We do this to prevent frequent price
                    changes
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="save_edit_btn btn" onClick={this.sendUpdatedDish}>
          {this.props.edit
            ? this.props.submissionLoad
              ? "Loading"
              : "Save"
            : this.props.submissionLoad
            ? "Loading"
            : "Create"}
        </div>
      </form>
    );
  }
}

export default formCreate;
