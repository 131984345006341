import React, { Component } from "react";
import "./FAQ.css";

export class FAQ extends Component {
  state = {
    active: false,
  };

  putActive = () => {
    this.setState({ active: !this.state.active });
  };

  render() {
    return (
      <div className="container_one_faq">
        <div className="faq_top_display">
          <h1 className="faq_question">{this.props.question}</h1>
          <div className="faq_icon" onClick={this.putActive}>
            {!this.state.active ? (
              <i
                className="fas fa-plus"
                style={{ color: "#0f0f0f", fontSize: "12px" }}
              ></i>
            ) : (
              <i className="fas fa-minus"></i>
            )}
          </div>
        </div>
        {this.state.active ? (
          <p className="faq_answer faq_active">{this.props.answer}</p>
        ) : (
          <p className="faq_answer">{this.props.answer}</p>
        )}
      </div>
    );
  }
}

export default FAQ;
