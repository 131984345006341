import React, { Component } from "react";
import Form from "./form.jsx";
import Sellerbenefits from "./sellerCards";
import FAQ from "./FAQ.jsx";
import "./homechef.css";
import "./media_homechef.css";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

export class homechef extends Component {
  state = {
    user: this.props.user,
    address: this.props.user ? this.props.user.address : null,
  };

  componentDidUpdate(prevProps) {
    if (this.props.user.address !== prevProps.user.address) {
      // console.log("here at update _________");
      this.setState({
        user: this.props.user,
        address: this.props.user.address,
      });
    }
  }

  componentDidMount() {
    if (this.props.user) {
      this.props.setHistoryLink(null);
    }
  }
  render() {
    const var1 = (
      <p>
        After you submit the form above, you may be contacted by a tizaar
        employee to send a small <b> sample of your food </b>. If your social
        media link indicates an existing, succesful customer base, we will avoid
        the taste testing process. <br /> <br />
        If the sample passes the taste test we will provide you with tutorials
        and help to introduce you to the platform. <br />
        <br /> Upon completion, you will be registered on the tizaar platform.
      </p>
    );
    return (
      <div>
        <Helmet>
          <title>tizaar - Become a Homechef</title>
          <meta
            name="description"
            content="Dough-nut miss your chance to become a homechef on tizaar!"
          />
        </Helmet>
        <div className="container_homechef homechef_big_div">
          <div className="hidden_container_trick">
            <div className="homechef_main">
              <img
                src={`https://tizaar-foodbazaar.s3-ap-southeast-1.amazonaws.com/dishes/freepik_pasta.png`}
                alt=""
                className="freepik_pasta"
              />
              <img
                src={`${process.env.PUBLIC_URL}/homechef/apple.png`}
                alt=""
                className="freepik_apple"
              />
              {/* <img
              src={`${process.env.PUBLIC_URL}/homechef/apple.png`}
              alt=""
              className="freepik_apple_2"
            /> */}
              <img
                src={`${process.env.PUBLIC_URL}/homechef/leaf.png`}
                alt=""
                className="freepik_leaf"
              />
              <div className="left_container_seller">
                <h1 className="form_slogan">
                  D
                  <img
                    src={`https://tizaar-foodbazaar.s3-ap-southeast-1.amazonaws.com/Donut-1.png`}
                    alt=""
                    className="donut"
                  />
                  {/* <div
              className="freepik_pasta image"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/homechef/freepik_pasta.png)`,
              }}
            ></div> */}
                  <img
                    src={`https://tizaar-foodbazaar.s3-ap-southeast-1.amazonaws.com/Donut-2.png`}
                    alt=""
                    className="donut"
                  />
                  GH-NUT <br />{" "}
                  <span className="general_text">
                    miss your chance <br></br> to make that
                  </span>
                  {"  "}
                  dou <span className="dollar">$</span>h
                </h1>
              </div>
              <div className="right_container_seller">
                <div className="application_timeline">
                  <h1>Application Timeline</h1>
                  <div className="form_review imgs_container">
                    <i className="fas fa-file-alt icon_timeline inline"></i>
                    <div className="background_border form_appliance"></div>
                    Form Review
                  </div>
                  <div className="taste_test imgs_container">
                    <img
                      src={`${process.env.PUBLIC_URL}/homechef/lips.png`}
                      alt=""
                      className="safety_image image icon_timeline inline"
                    ></img>
                    <div className="background_border"></div>
                    Taste Test
                  </div>
                  <div className="safety_clearance imgs_container">
                    <img
                      src={`${process.env.PUBLIC_URL}/homechef/safety.png`}
                      alt=""
                      className="safety_image image icon_timeline inline"
                    />
                    <div className="background_border"></div>
                    Safety Clearance
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="left_container_seller_m">
            <Form
              user={this.state.user}
              setUser={this.props.setUser}
              updateUserLocation={this.props.updateUserLocation}
              setHistoryLink={this.props.setHistoryLink}
            />
          </div>
          <section className="seller_benefits">
            <div className="left_container_general_s">
              <h1 className="seller_benefits_title">Benefits</h1>
              <div className="seller_benefit_cards_cont">
                <Sellerbenefits
                  fileName="Convinience"
                  header="Convenience"
                  message="The tizaar dashboard makes order management and delivery really easy!"
                />
                <Sellerbenefits
                  fileName="money"
                  header="Earn Money"
                  message="The tizaar platform will help advertise your food, which will in turn translate to much more sales!"
                />
                <Sellerbenefits
                  fileName="marketing"
                  header="Marketing & Analytics"
                  message="The tizaar platform and marketing plans will help increase your businesses exposure!"
                />
                <Sellerbenefits
                  fileName="boss"
                  header="You're the Boss"
                  message="You decide when to cook, how much you want to cook, and what you want to cook!"
                />
              </div>
            </div>
          </section>
          <section className="faq">
            <div className="left_container_general_s">
              <h1 className="section_title">FAQ</h1>
              <div className="faq_flex">
                <div
                  className="column-1 inline"
                  style={{ width: "45%", minWidth: "354px" }}
                >
                  <FAQ
                    question="How does the vetting and taste testing process work?"
                    answer={var1}
                  />
                  <FAQ
                    question="How will I recieve payment for my orders?"
                    answer="We are working with payment processing company PayMongo to ensure you recieve money for your orders. At around 8pm on Monday we will send out payments via direct bank transfer/gcash."
                  />
                  <FAQ
                    question="How does delivery work?"
                    answer="We are using Mr.Speedy to facilitate deliveries. Delivery is organised for a certain order through just the click of a button!"
                  />
                </div>
                <div
                  className="column-1 inline"
                  style={{ width: "45%", minWidth: "354px" }}
                >
                  <FAQ
                    question="How often do I have to cook?"
                    answer="You are your own boss! You can cook anytime you'd like. For each week simply update your menu for the days you'd like to cook food on. "
                  />
                  <FAQ
                    question="What is the commision like?"
                    answer="We charge a 5% commision. This is to cover our digital processing fee. We will be experimenting with a business model where chefs on the platform can choose whether or not to pay for certain add ons on the website. (Add ons will eventually include, loyalty programs, advanced data, in-app chat, etc...)"
                  />
                  <FAQ
                    question="How does my customer know when the food is arriving?"
                    answer="Once a chef on tizaar, you will have a dashboard. In this dashboard, each order will have an attached finished button. Once this button is pressed a notification gets sent to your customer that delivery has started or that the food is ready for pickup. Mr.Speedy will inform you and your customer through SMS when delivering the food."
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default homechef;
