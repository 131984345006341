import React, { Component } from "react";

export class alert extends Component {
  state = {
    error: this.props.error,
    message: this.props.message,
  };
  render() {
    return (
      <div className={this.state.error ? "error_alert" : "payed_alert"}>
        {this.state.message}
      </div>
    );
  }
}

export default alert;
