import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Select from "react-select";

import "./searchheader.css";
import "../media_bazaar.css";
import Chef from "./chef.png";
import Dish from "./utensil.svg";

export class searchheader extends Component {
  state = {
    search: "",
    dropdownOpen: false,
    type: "chefs",
  };

  setSearch = (e) => {
    e.preventDefault();
    this.setState({ search: e.target.value });
    if (e.target.value === "") {
      this.props.searchFor(e.target.value);
    }
  };
  toggle = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  render() {
    return (
      <div className="top_searchbar">
        {/* <div className="zip_code">
          <i className="fas fa-map-marker-alt"></i>
          {/* <FontAwesomeIcon className="fa-map-marker-alt" icon={faThumbsUp} /> 
          <p className="p-font">Dasmarinas/1221</p>
        </div> */}
        <div className="search_bar">
          {this.state.type === "chefs" ? (
            <img src={Chef} className="bootstrap_icon" alt="" />
          ) : (
            <img src={Dish} className="bootstrap_icon" alt="" />
          )}
          <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
            <DropdownToggle caret>
              {`SEARCH BY ${this.state.type}`.toUpperCase()}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  this.props.setType("chefs");
                  this.setState({ type: "chefs" });
                }}
              >
                <img src={Chef} className="bootstrap_icon" alt="" />{" "}
                <span>Search by chefs</span>
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  this.props.setType("dishes");
                  this.setState({ type: "dishes" });
                }}
              >
                <img src={Dish} className="bootstrap_icon" alt="" />
                <span>Search by dishes</span>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <form
            className="flex_fill form_submit"
            onSubmit={(e) => {
              e.preventDefault();
              // console.log("I Just clikced this!");
              this.props.searchFor(this.state.search);
              return false;
            }}
          >
            <input
              type="text"
              placeholder={
                this.state.type === "chefs"
                  ? "(optional) Find chefs by name!"
                  : "Search for a dish and see what chefs have that dish!"
              }
              className="p-font submit_input"
              onChange={this.setSearch}
              value={this.state.search}
            />
            <input
              type="submit"
              className="form_submit_btn"
              // onClick={(e) => {
              //   e.preventDefault();
              //   // console.log("I Just clikced this!");
              //   this.props.searchFor(this.state.search);
              // }}
            />
          </form>
        </div>
      </div>
    );
  }
}

export default searchheader;
