// const Order = require("../../../Backend/Model/orderModel");
const axios = require("axios");
const CryptoJS = require("crypto-js");
const crypto = require("crypto");

class Lalamove {
  constructor() {
    // this.body = {};
  }

  //MODULARISE!!

  createHeaderToken(bodyy) {
    const SECRET = process.env.LALAMOVE_SECRET
      ? process.env.LALAMOVE_SECRET
      : "MCwCAQACBQDDym2lAgMBAAECBDHB";
    const time = new Date().getTime().toString(); // => `1545880607433`

    const method = "POST";
    const path = "/v2/quotations";
    const body = JSON.stringify(bodyy); // => the whole body for '/v2/quotations'

    const rawSignature = `${time}\r\n${method}\r\n${path}\r\n\r\n${body}`;
    // => '1546222219293\r\nPOST\r\n/v2/quotations\r\n\r\n{\n \"scheduleAt\": \"2018-12-31T14:30:00.00Z\",\n \"serviceType\": \"MOTORCYCLE\",\n \"requesterContact\": { \"name\": \"Peter Pan\", \"phone\": \"232\" },\n \"stops\": [\n {\n \"location\": { \"lat\": \"-6.255431000000001\", \"lng\": \"106.60114290000001\" },\n \"addresses\": {\n \"en_ID\": {\n \"displayString\":\n \"Jl. Perum Dasana Indah No.SD 3/ 17-18, RT.3/RW.1, Bojong Nangka, Klp. Dua, Tangerang, Banten 15810, Indonesia\",\n \"country\": \"ID\"\n }\n }\n },\n {\n \"location\": { \"lat\": \"-6.404722800000001\", \"lng\": \"106.81902130000003\" },\n \"addresses\": {\n \"en_ID\": {\n \"displayString\": \"Jl. Kartini, Ruko No. 1E, Depok, Pancoran MAS, Kota Depok, Jawa Barat 16431, Indonesia\",\n \"country\": \"ID\"\n }\n }\n }\n ],\n \"deliveries\": [\n {\n \"toStop\": 1,\n \"toContact\": {\n \"name\": \"mm\",\n \"phone\": \"9999999\"\n }\n }\n ]\n}\n'

    const SIGNATURE = CryptoJS.HmacSHA256(rawSignature, SECRET).toString();
    // => '5133946c6a0ba25932cc18fa3aa1b5c3dfa2c7f99de0f8599b28c2da88ed9d42'

    const API_KEY = process.env.LALAMOVE_API_KEY;
    return `${API_KEY}:${time}:${SIGNATURE}`;
  }

  headerObj(body) {
    return {
      Authorization: `hmac ${this.createHeaderToken(body)}`,
      "X-LLM-Country": "PH_MNL",
      "X-Request-ID": crypto.randomBytes(16).toString("hex"), //has to be unique everytime right
    };
  }

  createContact(name, phone) {
    return { name: name ? name : "", phone: phone };
  }

  createPoint(lat, lng, address) {
    return {
      location: {
        lat: lat,
        lng: lng,
      },
      addresses: {
        en_PH: {
          displayString: address,
          country: "PH_MNL",
        },
      },
    };
  }

  returnBody(order) {
    let body = {};

    let pickupPoint = this.createPoint(
      order.chef.location.coordinates[1],
      order.chef.location.coordinates[0],
      order.chef.address
    );
    let pickupContact = this.createContact(order.chef.name, order.chef.phone);
    let dropContact = this.createContact(order.name, order.phone);
    let dropPoint = this.createPoint(order.lat, order.lng, order.address);
    //UPDATES
    body.deliveries = {
      toStop: 1,
      toContact: dropContact,
      remarks:
        "This is food. Please try and handle appropriately. Have a good day :) ",
    };
    body.requesterContact = pickupContact;
    body.stops = [pickupPoint, dropPoint];
    body.serviceType = "MOTORCYLE";
    body.specialRequests = [];
    return body;
  }

  getQuotation(order) {
    //pass the order in the req.body and req.user is thru protect
    let totalFee = null;
    let totalFeeCurrency = null;
    let body = this.returnBody(order);
    axios({
      method: "post",
      url: `${process.env.LALAMOVE_URL}/v2/quotations`, //what to put for base url, is this right?
      data: body,
      headers: this.headerObj(body),
    })
      .then((response) => {
        // console.log(response);
        // this.totalFee = response.totalFee;
        // this.totalFeeCurrency = response.totalFeeCurrency;
        totalFee = response.totalFee;
        totalFeeCurrency = response.totalFeeCurrency;
      })
      .catch((err) => console.log(err));
    return { totalFee, totalFeeCurrency }; //this;
  }

  async placeOrder(totalFee, totalFeeCurrency, order) {
    //all params from quoted order
    let body = this.returnBody(order);
    let data = {};
    let orderRef;
    // let data = { ...body };
    data.quotedTotalFee = {
      amount: totalFee,
      currency: totalFeeCurrency,
    };
    let responses = await axios({
      method: "post",
      url: `${process.env.LALAMOVE_URL}/v2/orders`, //what to put for base url, is this right?
      data: data,
      headers: this.headerObj(data),
    });
    orderRef = responses.orderRef;
    //OR
    return orderRef; //OR orderRef
  } //contains orderRef and orderId // SHOULD APPEND THIS TO ORDER FIELD

  async getOrderDetails(orderId) {
    //Return shareLink, driver phone, driver plate number, driver name, IF DELIVERY
    let driverId;
    let shareLink;
    let driverName;
    let driverPhone;
    let plateNumber;
    let orderDetails = await axios({
      method: "get",
      url: `${process.env.LALAMOVE_URL}/v2/orders/${orderId}`,
      headers: this.headerObj(null),
    });
    driverId = orderDetails.driverId;
    shareLink = orderDetails.shareLink;

    let driverDetails = await axios({
      method: "get",
      url: `${process.env.LALAMOVE_URL}/v2/orders/${orderId}/drivers/${driverId}`,
      headers: this.headerObj(null),
    });
    driverName = driverDetails.name;
    driverPhone = driverDetails.phone;
    plateNumber = driverDetails.plateNumber;
    return { shareLink, driverName, driverPhone, plateNumber };
  }
}

export default Lalamove;
