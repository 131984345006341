import React, { Component } from "react";
import GoogleLogin from "react-google-login";
import { Redirect } from "react-router-dom";
import "./signup.css";
import axios from "axios";
import { Helmet } from "react-helmet";

export class signup extends Component {
  state = {
    email: "",
    password: "",
    passwordConfirm: "",
    loading: false,
  };

  responseGoogle = (response) => {
    // console.log(response);
    this.setState({ email: response.profileObj.email, loading: true });
    if (response.tokenId) {
      axios({
        method: "post",
        url: `${
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_URL
            : "http://localhost:8080"
        }/api/v1/users/googleSignIn`,
        data: { tokenId: response.tokenId },
        withCredentials: true,
      }).then((response) => {
        this.props.setUser(response.data.data.user);
        this.setState({ loading: false });
      });
    }
  };

  responseGoogleFail = (response) => {
    alert(
      "There was an issue with your google login please sign up manually or try again"
    );
  };

  setPassword = (e) => {
    this.setState({ password: e.target.value });
  };

  setEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  setpasswordConfirm = (e) => {
    // // console.log(e.target.value);
    this.setState({ passwordConfirm: e.target.value });
  };

  checkPasswords = () => {
    return this.state.password === this.state.passwordConfirm;
  };

  submitLogin = () => {
    if (!this.checkPasswords()) {
      alert("The passwords provided do not match!");
    } else {
      //POST REQUEST HERE FOR PROTECTION with email and password
      this.setState({ loading: true });
      axios({
        method: "POST",
        url: `${
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_URL
            : "http://localhost:8080"
        }/api/v1/users/signup`,
        data: {
          email: this.state.email,
          password: this.state.password,
          passwordConfirm: this.state.passwordConfirm,
        },
        withCredentials: true,
      }).then((response) => {
        if (response.data.status === "success") {
          this.props.setUser(response.data.data.user);
          this.setState({ loading: false });
          // <Redirect to="/" />; //not necessary should automatically render!
        } else {
          alert("There was an issue regarding your signup!");
          this.setState({ loading: false });
          // window.location.reload();
        }
      });
      //risky? no success so will be redirected.
      //this.props.setUser!
    }
  };

  render() {
    if (!this.state.loading) {
      return (
        <div className="login_container">
          <Helmet>
            <title>tizaar - Signup</title>
            <meta
              name="description"
              content="Signup with tizaar to see what homecooked meals your neighbors are cooking!"
            />
          </Helmet>
          <div className="left_login_cont left_signup_cont">
            <div className="main_login_cont">
              <h1 style={{ color: "#6d6d6d", fontFamily: "landingFontTwo" }}>
                Signup
              </h1>
              <div className="sign_up_google_btn">
                <GoogleLogin
                  clientId="176704683569-qc46nbriadugqt1a2k9nme5oph80jjgl.apps.googleusercontent.com"
                  buttonText="Signup with Google"
                  onSuccess={this.responseGoogle}
                  onFailure={this.responseGoogleFail}
                  cookiePolicy={"single_host_origin"}
                  className="google_login"
                />
              </div>
              <form onSubmit={this.submitLogin} action="#">
                <div className="username_bubble_div bubble_login_div">
                  <p>
                    <b>Email</b>
                  </p>
                  <input
                    type="email"
                    className="Please enter your email"
                    onChange={this.setEmail}
                  />
                </div>
                <div className="password_bubble_div bubble_login_div">
                  <p>
                    <b>Password</b>
                  </p>
                  <div className="password_wrapper">
                    <input
                      type="password"
                      className="password_input input_bubble_login"
                      onChange={this.setPassword}
                    />
                  </div>
                </div>
                <div className="password_bubble_div bubble_login_div">
                  <p>
                    <b>Confirm Password</b>
                  </p>
                  <div className="password_wrapper">
                    <input
                      type="password"
                      className="confirm_password_input input_bubble_login"
                      onChange={this.setpasswordConfirm}
                    />
                  </div>
                </div>
                <button
                  style={{ width: "100%" }}
                  type="submit"
                  className="login_btn"
                  onClick={(e) => {
                    e.preventDefault();
                    this.submitLogin();
                  }}
                >
                  <b>Signup</b>
                </button>{" "}
              </form>

              {/* <h1 className="welcome">Welcome to tizaar</h1> */}
            </div>
          </div>
          <div className="wrapper_login right_signup_cont">
            <div
              className="right_login_cont image"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/landing/chef.png)`,
              }}
            ></div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="chef_float"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/landing/chef.png)`,
          }}
        ></div>
      );
    }
  }
}

export default signup;
