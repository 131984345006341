import React, { Component } from "react";
import "./dashboard.css";
import "./dashboard_general.css";
import axios from "axios";
import Modal from "./modal";
import Lalamove from "../../util/lalamove";
import Joyride from "react-joyride";
import Loader from "react-loader-spinner";
import Tour from "reactour";
import { Helmet } from "react-helmet";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { Button } from "reactstrap";

const dateMap = {
  Mon: "Monday",
  Tue: "Tuesday",
  Wed: "Wednesday",
  Thu: "Thursday",
  Fri: "Friday",
  Sat: "Saturday",
  Sun: "Sunday",
};

//USE SOCKETS FOR BIDRECTIONAL COMMUNICATION?
export class dashboard extends Component {
  state = {
    //parent referencing populate for bookings.
    pendingOrders: [],
    checkoutOrders: [],
    hiddenTwo: true,
    rating: 4.5,
    totalCount: 0,
    hidden: true,
    filterSearch: "", //deliveryDate
    loadingPending: false,
    loadingCheckout: false,
    loadingDelivery: false,
    loadingFinished: false,
    loadingFinishedC: false,
    deliveryId: "",
    showPopup: false,
    errorDetails: [],
    monthlyR: null,
    searchType: null,
    dropdownOpen: false,
    digitalDue: 0,
    confirmLoading: null,
    modal: false,
    deliveryFee: null,
    remarks: null,
    time: null,
    isOpen: true,
    pending: false,
    orderList: false,
    view: false,
    sort: false,
    group: null,
    groupModal: null,
    groupLoading: false,
    groupLoadingS: false,
    steps: [
      {
        selector: ".delivery_date",
        content:
          "You can see what pending orders you have for each day by using this dropdown filter!",
      },
      {
        selector: ".pending",
        position: "top",
        content:
          "This is where your pending orders are listed. We will make the design much better in the future, but at the moment it may require some scrolling to see all the data per order!",
      },
      {
        selector: ".deliv_field",
        content:
          "You should finish !cooking! your order any time within the range provided and for the given date. This has delivery times taken into consideration, if for a delivery order.",
      },
      {
        selector: ".cust_dets",
        content:
          "We will eventually add details about your customer i.e how many orders they've made in the past, their name, maybe even their most frequently bought dishes/previous orders so you can give them the right freebies!,  and so on...",
      },
      {
        selector: ".conf_ord",
        content:
          "You need to press this right after recieving an order! This will send a payment link to the buyer via message, where they can pay for your order, as well as notify them that the order has been confirmed. You will get an SMS when the order has been payed for, as well as an SMS when you get an order so you know to immediately press confirm. Try not to reject the order, and update your timetable and menu according to how busy you are!!!",
      },
      {
        selector: ".not_comp",
        // action: (node) => {
        //   window.scroll(0, 20);
        // },
        content:
          "When you are done making the order press this! This will automatically send a delivery person from Mr.Speedy or Lalamove to pick up the order! In case there is an issue we will provide the phone number and address of the customer so you can schedule the order on your own. It likely wont come to this :) Scroll to see the rest of the fields",
      },
      // {
      //   selector: ".payed_field",
      //   content:
      //     "This tells you whether the order has been payed for or not. Again you will get an SMS when it is payed for. Keep in mind COD for pickup orders will never be payed for until the customer comes to your door!",
      // },
      // {
      //   selector: ".dropdown",
      //   // action: (node) => {
      //   //   // by using this, focus trap is temporary disabled
      //   //   window.scroll(0, node.offsetTop);
      //   //   node.focus();
      //   //   // console.log("yup, the target element is also focused!");
      //   // },
      //   position: "bottom",
      //   // action: (node) => {
      //   //   // by using this, focus trap is temporary disabled
      //   //   window.scroll(0, window.innerHeight);
      //   //   node.focus();
      //   //   // console.log("yup, the target element is also focused!");
      //   // },
      //   content:
      //     "Here you can see which of your completed orders is a digitally payed order, COD order, or both. We will weekly deposit money into your bank account for digital orders",
      // },
    ],
    // deliveryError: false,
  };

  showDropDown = () => {
    //means was added
    this.setState({ hidden: false });
  };

  showDropDownTwo = () => {
    //means was added
    this.setState({ hiddenTwo: false });
  };

  hideDropDownTwo = () => {
    //means was added
    this.setState({ hiddenTwo: true });
  };

  hideDropDown = () => {
    //means was added
    this.setState({ hidden: true });
  };

  updateFilter = async (e) => {
    const filterSearch = e.target.className;
    await this.setState({ filterSearch, hidden: true });
    this.axiosPendingOrders();
    // this.axiosCheckoutOrders();
  };

  updateFilterTwo = async (e) => {
    let time;
    if (e.target.className !== "" && e.target.className !== "All") {
      time = JSON.parse(e.target.className);
    } else {
      console.log("AT NULL");
      time = null;
    }
    await this.setState({ time, hiddenTwo: true });
    // console.log("time, ", this.state.time);
    this.axiosPendingOrders();
  };

  componentDidMount = () => {
    //will have to do some axios request here to get data and await it. Only get payed true and for matching chefId with passed in user.chefId.
    this.setState({ loadingPending: true, loadingCheckout: true });
    this.monthlyRevenue();
    this.axiosPendingOrders();
    this.axiosCheckoutOrders();
    // this.axiosOrderCount();

    //add dashboard link also.
  };

  getDigitalDue = () => {
    axios({
      method: "get",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/orders/due`,
      withCredentials: true,
    }).then((response) => {
      this.setState({ digitalDue: response.data.data });
    });
  };

  monthlyRevenue = () => {
    // // console.log("AT MONTHLY REVNUE! ");
    axios({
      method: "get",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/orders/monthlyRevenue`,
      withCredentials: true,
    }).then((response) => {
      this.setState({ monthlyR: response.data.data });
    });
  };

  axiosOrderCount = () => {
    axios({
      method: "get",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/orders/orderCount`,
      withCredentials: true,
    }).then((response) => {
      this.setState({ totalCount: response.data.data });
    });
  };

  axiosPendingOrders = () => {
    let filterSearch = "";
    // console.log(this.state.time, "1");
    if (this.state.time && this.state.time !== "") {
      filterSearch = `?selectedTime=${this.state.time}`;
      // console.log(this.state.time, "2");
    }
    if (this.state.filterSearch != "") {
      if (filterSearch === "") {
        filterSearch = `?deliveryDate=${this.state.filterSearch}`;
      } else {
        filterSearch = `${filterSearch}&deliveryDate=${this.state.filterSearch}`;
      }
    }
    axios({
      method: "get",
      url:
        filterSearch != ""
          ? `${
              process.env.NODE_ENV === "production"
                ? process.env.REACT_APP_URL
                : "http://localhost:8080"
            }/api/v1/orders/chefOrders${filterSearch}&sort=${this.state.sort}`
          : `${
              process.env.NODE_ENV === "production"
                ? process.env.REACT_APP_URL
                : "http://localhost:8080"
            }/api/v1/orders/chefOrders?sort=${this.state.sort}`,
      withCredentials: true,
    }).then((response) => {
      if (response.data.status === "success") {
        const pendingOrders = this.calculatePrice(response.data.data.orders);
        this.setState({
          pendingOrders,
          loadingPending: false,
        });
      } else {
        alert(
          "We weren't able to get your pending orders! Please contact tizaar@support.com, or call 09175183938"
        );
      }
    });
  };

  axiosCheckoutOrders = () => {
    let url =
      this.state.searchType !== null
        ? `${
            process.env.NODE_ENV === "production"
              ? process.env.REACT_APP_URL
              : "http://localhost:8080"
          }/api/v1/orders/chefOrdersCompleted?digital=${this.state.searchType}`
        : `${
            process.env.NODE_ENV === "production"
              ? process.env.REACT_APP_URL
              : "http://localhost:8080"
          }/api/v1/orders/chefOrdersCompleted`;
    axios({
      method: "get",
      url: url,
      withCredentials: true,
    }).then((response) => {
      if (response.data.status === "success") {
        const checkoutOrders = this.calculatePrice(response.data.data.orders);
        this.setState({
          checkoutOrders,
          loadingCheckout: false,
        });
      } else {
        alert(
          "We weren't able to get your checkout orders! Please contact tizaar@support.com."
        );
      }
    });
  };

  createAxiosCheckout = async (order, index) => {
    //POST ORDER HERE! GET DELIVERYID --> Then update backend to post deliveryId here.
    /* //LALAMOVE: 
    let lala = new Lalamove();
    let orderRef = await lala.placeOrder(
      order.deliveryFee,
      order.deliveryCurrency,
      order
    );*/
    // let details = await lala.getOrderDetails(orderRef);
    // if (!orderRef) {
    //   alert(
    //     `PLEASE READ: We weren't able to get your notify your customer of the finished order! It would be a good idea to call a a company like lalamove, happymove, or grab and send the order to ${order.address} for ${order.name}. You can message them when delivery is confirmed at ${order.phone}.  Please call 9175183938 to ingorm us about this. We will attempt to manually call another delivery service if you haven't done so already.`
    //   );
    //   return;
    // }
    let response = await axios({
      method: "patch",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/orders/chefOrders/${order._id}`,
      data: { digital: order.digital },
      // data: { deliveryId: orderRef },
      withCredentials: true,
    }).catch((err) => {
      alert(
        `IMPORTANT: There was an error in marking this order as finished. Try this again in one minute. Otherwise, please contact ${
          order.name ? order.name : "?"
        }(the customer) at ${
          order.phone
        } (phone). Please also organise delivery to the customers address at ${
          order.address
        }! Sorry about this! This is a last precaution and please let us know about this so we can solve this ASAP. This info is saved in customer details!`
      );
      this.setState({ loadingFinished: null });
      let errorArray = [...this.state.errorDetails];
      errorArray.push(order._id);
      this.setState({ errorDetails: errorArray });
      console.log(err);
    });
    if (response && response.data && response.data.status === "success") {
      // // console.log("order transfered");
      let pendingOrders = [];
      this.state.pendingOrders.forEach((el, i) => {
        if (i !== index) pendingOrders.push(el);
      });
      let pendingOrder = { ...this.state.pendingOrders[index] };
      pendingOrder.finished = true;
      Array.from(pendingOrders).splice(index, 1);
      // // console.log(`pending after removal at index ${index}`);
      // // console.log(pendingOrders);
      let checkoutOrders = [...this.state.checkoutOrders];
      checkoutOrders.push(pendingOrder);
      this.setState({ checkoutOrders, pendingOrders, loadingFinished: null });
      if (pendingOrder.deliveryMode === "delivery") {
        alert(
          "Your delivery order was succesfully created. You will get an SMS once a driver is assigned to your order. Your customer has been notified that you're done cooking!"
        );
      } else {
        alert("You have succesfully notified your customer for pickup");
      }
    } else if (response) {
      this.setState({ loadingFinished: null });
      alert(
        "There was an error in registering your order as finished. Please call 09175183938. However, the delivery person should be on the way so your food will be delivered"
      );
    }
  };

  // cookingNow = (index) => {
  //   let pendingOrders = [...this.state.pendingOrders];
  //   pendingOrders[index].cooking = true;
  //   pendingOrders[index].cookingTime = Date.now();
  //   this.setState({ pendingOrders });
  //   //PATCH pending order for cooking time field
  // };

  finishedCooking = (index) => {
    if (this.state.pendingOrders[index].finished) {
      //should never be the case lol.
      return;
    }
    let pendingOrder = { ...this.state.pendingOrders[index] };
    var r = window.confirm(
      pendingOrder.deliveryMode === "delivery"
        ? `Are you sure you're ready to deliver now?`
        : `Are you sure you're ready to have your food be picked up!`
    );
    if (!r) {
      return;
    }
    this.setState({ loadingFinished: index });
    this.createAxiosCheckout(pendingOrder, index);
  };

  calculatePrice = (order) => {
    const array = order;
    var price = 0;
    const checkOutArray = Array.from(array).map((orders) => {
      let newObj = { ...orders };
      newObj.dishList = [];
      Array.from(orders.dishList).forEach((dish) => {
        // let dishName = Object.keys(dish)[0];
        let dishQuantity = dish.quantity;
        let price = dishQuantity * dish.dish.price;
        let dishCopy = { ...dish };
        dishCopy.dish.price = price;
        newObj.dishList.unshift(dishCopy);

        //// console.log(price); correct
        //// console.log(price[dishName]); wrong
        // // console.log(dishCopy);
      });
      return newObj;
    });
    // // console.log(`THIS IS THE CHECKOUT ARRAY`);
    // // console.log(checkOutArray);
    return checkOutArray;
  };

  confirmOrder = (index) => {
    //Pass in paymongo link as BODY? ALSO, GET QUOTATION HERE, get delivery quotation here and pass it in then use paymongo link.
    this.monthlyRevenue();
    let order = this.state.pendingOrders[index];
    // // console.log("@ confirmOrder");
    if (order.confirmed !== undefined) {
      return;
    }
    // // console.log("@ confirmOrder 2");
    order.confirmed = true;
    this.setState({ confirmLoading: index });
    axios({
      method: "patch",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/orders/confirm`,
      data: { order },
      withCredentials: true,
    })
      .then((response) => {
        let pendingOrders = [...this.state.pendingOrders];
        pendingOrders[index] = order;
        alert(
          "Succesful Confirmation. You will be notified once your customer has paid."
        );
        this.setState({ pendingOrders, confirmLoading: null });
      })
      .catch((err) => {
        // this.setState({ deliveryError: index });
        //PAYMONGO LINK! get paymongolink function
        order.digital
          ? alert(
              `IMPORTANT: There was an error in sending the SMS. Please organise delivery and use this link for payments. Customer Phone: ${order.phone}, Customer Address: ${order.address} `
            )
          : alert(
              `Please retry pressing confirm. If there is still an error just message the customer that their order is ready at, ${order.phone}.`
            );
      });
  };

  rejectOrder = (index, remarks) => {
    let order = this.state.pendingOrders[index];
    if (order.confirmed !== undefined) {
      return;
    }
    // // console.log("rej");
    order.confirmed = false;
    if (!this.state.remarks) {
      alert(
        "Please add a message to your buyer first explaining why you can't do the order"
      );
      return;
    }
    this.setState({ confirmLoading: index });
    axios({
      method: "patch",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/orders/reject`,
      data: { remarks: remarks, order },
      withCredentials: true,
    })
      .then((response) => {
        let pendingOrders = [...this.state.pendingOrders];
        pendingOrders[index] = order;
        this.setState({ pendingOrders, confirmLoading: null });
        alert("succesful rejection");
      })
      .catch((err) =>
        alert(
          "There was an error in rejecting the order. Please contact tizaar at 09175344189"
        )
      );
  };

  convertTime = (time, incr, delivery) => {
    if (!time) return;
    let timeCopy = time;
    let timeTo = JSON.parse(timeCopy) + incr;
    // // console.log(time, timeTo);
    let stamp = "am";
    let stampCopy = "am";
    if (time >= 12) {
      stamp = "pm";
      if (time > 12) timeCopy = time - 12;
    }
    if (timeTo >= 12) {
      stampCopy = "pm";
      if (timeTo > 12) timeTo = timeTo - 12;
    }
    // // console.log(timeCopy, stamp, timeTo, stampCopy);
    return delivery
      ? `${timeCopy}:30${stamp} - ${timeTo}${stampCopy}`
      : `${timeCopy}${stamp} - ${timeTo}${stampCopy}`;
  };

  capitalizeFirstLetter = (string) => {
    let str = string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
    return str;
  };

  capitalizeAllLetter = (string) => {
    // console.log(string);
    let str = string.split(" ");
    let ret = "";
    str.forEach((s) => {
      let st = this.capitalizeFirstLetter(s);
      ret = "" + ret + st + " ";
    });
    return ret;
  };

  getOrderList = async () => {
    this.setState({ loadingDelivery: true });
    let arrfrom = Array.from(this.state.checkoutOrders);
    let deliveryIds = [];

    arrfrom.forEach((order) => {
      if (order.deliveryId !== undefined && order.deliveryId !== null)
        deliveryIds.push(order.deliveryId);
    });
    // console.log("deliverYids", deliveryIds);
    let response = await axios({
      method: "post",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/orders/deliveryList`,
      data: { order_list: deliveryIds },
      withCredentials: true,
    }).catch((err) => console.log(err));
    // console.log("FIRST RESPONSE", response);
    if (response && response.data && response.data.data) {
      //look for deliveryId with order_id
      let orderIdToI = {};
      let responseArr = Array.from(response.data.data);
      let arr = responseArr.map((order, i) => {
        orderIdToI[order.order_id] = i;
        return `${order.order_id}`;
      });
      console.log(
        response,
        "deliveryList;, ",
        "respone array",
        responseArr,
        "mapping",
        orderIdToI,
        "array id direct",
        arr
      );
      let checkoutOrders = [];
      arrfrom.forEach((order) => {
        if (arr.includes(order.deliveryId)) {
          // console.log(arr, order.deliveryId);
          // let num = responseArr[orderIdToI[order.deliveryId]].date;
          // order.duration = Math.round(num * 100) / 100;
          order.duration = responseArr[orderIdToI[order.deliveryId]].date;
          order.status = responseArr[orderIdToI[order.deliveryId]].status;
          checkoutOrders.push(order);
        }
      });
      this.setState({ checkoutOrders, loadingDelivery: false });
    }
    if (this.state.loadingDelivery) this.setState({ loadingDelivery: false });
  };

  set = (str, val) => {
    this.setState({ [str]: val });
  };

  addToGroup = (order, index) => {
    this.setState({ groupLoading: true });
    if (order._id === this.state.group._id) {
      alert("You can't group together the same order");
      this.setState({ group: null, groupModal: null, groupLoading: false });
      return;
    }
    if (
      order.deliveryMode !== "delivery" ||
      this.state.group.deliveryMode !== "delivery"
    ) {
      alert("You can't group together orders that aren't delivery orders");
      this.setState({ group: null, groupModal: null, groupLoading: false });
      return;
    }
    axios({
      method: "get",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/orders/delivery/group/${this.state.group._id}/${order._id}`,
      withCredentials: true,
    })
      .then((response) => {
        order.groupFee = response.data.data;
        this.setState({ groupModal: order, groupLoading: false });
      })
      .catch((err) => {
        console.log("Err in group fee", err);
        alert("There was in error in organising the group delivery");
        this.setState({ group: null, groupModal: null, groupLoading: false });
      });
    // console.log(this.state.group._id, order._id);
  };

  submitGroupOrdering = async () => {
    if (
      -1 * this.state.groupModal.groupFee +
        (this.state.group.deliveryFee + this.state.groupModal.deliveryFee) <=
      0
    ) {
      alert("You can only make a group order if there are savings");
      return;
    }
    this.setState({ groupLoadingS: true });
    let response = await axios({
      method: "patch",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/orders/delivery/group/${this.state.group._id}/${
        this.state.groupModal._id
      }`,
      withCredentials: true,
      data: {
        group: true,
        total:
          this.state.group.deliveryFee +
          this.state.groupModal.deliveryFee -
          this.state.groupModal.groupFee,
      },
    }).catch((err) => {
      console.log(err);
      this.setState({ loadingFinishedC: null });
      alert(
        `We failed to group the orders. Perhaps try doing them individually for now. Sorry! Please let us know about this`
      );
    });
    this.axiosPendingOrders();
    this.axiosCheckoutOrders();
    this.setState({
      group: null,
      groupModal: null,
      groupLoading: false,
      groupLoadingS: false,
    });
  };

  handleTourClose = () => {
    let guides = this.props.user.guides;
    guides[0] = false;
    axios({
      method: "patch",
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_URL
          : "http://localhost:8080"
      }/api/v1/users/me`,
      data: { guides },
      withCredentials: true,
    }).then((response) => {
      if (response.data.status === "success") {
        let user = { ...this.props.user };
        user.guides = guides;
        this.props.setUser(user);
      } else {
        // console.log("error getting rid of guide");
      }
    });
  };

  recreateOrder = async (order, index) => {
    var r = window.confirm(
      "This will only work if the order was created within 30 minutes from now AND the driver HASN'T reached the FIRST PICK UP POINT. Are you sure you want to re-create the delivery order? Only do this if your driver asks you to cancel the order due to unforseen circumstances (i.e car crash)"
    );
    if (r) {
      this.setState({ loadingFinishedC: index });
      let response = await axios({
        method: "post",
        url: `${
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_URL
            : "http://localhost:8080"
        }/api/v1/orders/recreateOrder/${order._id}`,
        withCredentials: true,
        data: {
          deliveryId: order.deliveryId,
          digital: order.digital,
          userId: order.chef.id,
        },
      }).catch((err) => {
        console.log(err);
        this.setState({ loadingFinishedC: null });
        alert(
          `We failed to recreate the order :(. Please create the delivery yourself (sorry!). You can reach  ${
            order.name ? order.name : "?"
          }(the customer) at ${
            order.phone
          } (phone). Please also organise delivery to the customers address at ${
            order.address
          }! Sorry about this! This is a last precaution and please let us know about this so we can solve this ASAP. This info is saved in customer details!`
        );
      });
      // console.log("response,", response);
      if (response.data && response.data.status === "success") {
        let order = response.data.data;
        let checkoutOrders = [...this.state.checkoutOrders];
        checkoutOrders[index] = order;
        this.setState({ loadingFinishedC: null, checkoutOrders });
        alert(
          "Another delivery order was SUCCESFULLY created, and the previous one cancelled. IF it takes longer than ten minutes to get a driver assigned, message or email me!"
        );
      } else {
        this.setState({ loadingFinishedC: null });
      }
    }
  };

  groupDeliveries = () => {};

  render() {
    const { steps } = this.state;
    let type = "Any";
    if (!this.state.pending) {
      type = "Any";
    } else {
      type = "Awaiting Confirmation";
    }
    if (!this.state.loadingCheckout && !this.state.loadingPending) {
      // // console.log(checkOutArray);
      return (
        <div style={{ position: "relative" }}>
          {this.state.groupModal ? (
            <div className="group_modal">
              <i
                class="fas fa-times"
                onClick={() => {
                  this.setState({ group: null, groupModal: null });
                }}
              ></i>
              <p style={{ fontFamily: "landingFontTwo" }}>
                Delivery Fee Separate:{" "}
                {this.state.group.deliveryFee +
                  this.state.groupModal.deliveryFee}
                P
              </p>
              <p style={{ fontFamily: "landingFontTwo" }}>
                Group Delivery Fee: {this.state.groupModal.groupFee}
              </p>
              <p style={{ fontFamily: "landingFontTwo" }}>
                Savings:
                {-1 * this.state.groupModal.groupFee +
                  (this.state.group.deliveryFee +
                    this.state.groupModal.deliveryFee)}
              </p>
              <p>
                Dropoff Point 1: {this.state.group._id}.{" "}
                <span style={{ color: "red" }}>
                  (Please label this with a BIG 1)
                </span>
              </p>
              <p>
                Dropoff Point 2: {this.state.groupModal._id}.{" "}
                <span style={{ color: "red" }}>
                  (Please label this with a BIG 2)
                </span>
              </p>
              {this.state.groupLoadingS ? (
                <Loader
                  type="Puff"
                  color="#FFA500"
                  height={20}
                  width={20}
                  timeout={3000} //3 secs
                />
              ) : (
                <div
                  className="create_delivery_pool"
                  onClick={this.submitGroupOrdering}
                >
                  Group Deliveries
                </div>
              )}
            </div>
          ) : null}
          <div className="send_feedback">
            <img
              src={`${process.env.PUBLIC_URL}/dashboard/feedback.png`}
              alt=""
              className="chat_bubble"
            />
            <p>
              Send Feedback <br /> at <b>masterchef@tizaar.com</b>
            </p>
          </div>
          {this.state.showPopup ? (
            Array.from(this.state.errorDetails).includes(
              this.state.showPopup._id
            ) ? (
              <div className="show_details">
                <div style={{ padding: "10px", marginBottom: "5px" }}>
                  {/* <h1>Emergency Customer & Delivery Details</h1>
                {this.state.showPopup.deliveryMode === "delivery" ? (
                  <p></p>
                ) : (
                  <p>Phone: {this.state.showPopup.phone}</p>
                )} */}
                  <div>
                    {this.state.showPopup.dishList &&
                      this.state.showPopup.dishList.map((dish) => {
                        return (
                          <div style={{ padding: "10px" }}>
                            {dish.note || dish.spiceLevel ? (
                              <h3>{dish.dish.name}</h3>
                            ) : null}
                            <p>
                              {dish.note
                                ? `Assortment Note: ${
                                    dish.note ? dish.note : ""
                                  }`
                                : ""}
                            </p>
                            <p>
                              {dish.spiceLevel
                                ? `Spice Level
                              : ` +
                                  dish.spiceLevel +
                                  " / 4"
                                : ""}
                            </p>
                          </div>
                        );
                      })}
                  </div>
                  {this.state.showPopup.note ? (
                    <div style={{ padding: "10px" }}>
                      <h3>Customer Note: </h3>
                      <p>{this.state.showPopup.note}</p>
                    </div>
                  ) : null}
                  <div style={{ padding: "10px" }}>
                    <h3>Customer Details: </h3>
                    <p>Address: {this.state.showPopup.address}</p>
                    <p>Phone: {this.state.showPopup.phone}</p>
                    <p>Name: {this.state.showPopup.name}</p>
                  </div>
                  <i
                    className="fas fa-window-close"
                    onClick={() => {
                      this.setState({ showPopup: null });
                    }}
                  ></i>
                </div>
              </div>
            ) : (
              <div className="show_details">
                <div style={{ padding: "10px", marginBottom: "5px" }}>
                  {this.state.showPopup.dishList &&
                    this.state.showPopup.dishList.map((dish) => {
                      return (
                        <div style={{ padding: "10px" }}>
                          {dish.note || dish.spiceLevel ? (
                            <h3>{dish.dish.name}</h3>
                          ) : null}
                          <p>
                            {dish.note
                              ? `Assortment Note: ${dish.note ? dish.note : ""}`
                              : ""}
                          </p>
                          <p>
                            {dish.spiceLevel
                              ? `Spice Level
                              : ` +
                                dish.spiceLevel +
                                " / 4"
                              : ""}
                          </p>
                        </div>
                      );
                    })}
                  {this.state.showPopup.note ? (
                    <div style={{ padding: "10px" }}>
                      <h3>Customer Note: </h3>
                      <p>{this.state.showPopup.note}</p>
                    </div>
                  ) : null}
                  {this.state.showPopup.deliveryMode === "delivery" ? (
                    <div style={{ padding: "10px" }}>
                      <h3>Customer Details: </h3>
                      <p>Name: {this.state.showPopup.name}</p>
                    </div>
                  ) : (
                    <div style={{ padding: "10px" }}>
                      <h3>Customer Details: </h3>
                      <p>Phone: {this.state.showPopup.phone}</p>
                      <p>Name: {this.state.showPopup.name}</p>
                    </div>
                  )}
                  <i
                    className="fas fa-window-close"
                    onClick={() => {
                      this.setState({ showPopup: null });
                    }}
                  ></i>
                </div>
              </div>
            )
          ) : (
            <div></div>
          )}
          {this.props.user.guides[0] ? (
            <Tour
              steps={steps}
              // nextButton
              // showNavigationNumber
              // prevButton

              accentColor={"#ffbb3a"}
              scrollDuration={1}
              isOpen={this.state.isOpen}
              onRequestClose={() => {
                this.setState({ isOpen: false });
                this.handleTourClose();
              }}
              // continuous={true}
              // showProgress={true}
              // showSkipButton={true}
              // scrollToFirstStep
              // run
              // disableScrolling
            />
          ) : (
            <div></div>
          )}

          <div className="container_nf">
            {/* HEADER */}
            <Helmet>
              <title>tizaar: Your Dashboard</title>
              <meta
                name="description"
                content="Tickle your taste buds at tizaar's food bazaar and unconver hidden food delights right in your very neighborhood!"
              />
            </Helmet>
            <div className="header_block">
              <div className="metrics">
                <div className="pending_card card_dash">
                  <img
                    src={`${process.env.PUBLIC_URL}/dashboard/pending.png`}
                    alt=""
                    className="bubble_img"
                  />
                  <div className="content">
                    <p className="subtitle">Pending Orders</p>
                    <h1 className="statistic">
                      {this.state.pendingOrders.length}
                    </h1>
                  </div>
                </div>
                <div className="checkout_card card_dash">
                  <img
                    src={`${process.env.PUBLIC_URL}/dashboard/complete.png`}
                    alt=""
                    className="bubble_img two_image"
                  />
                  <div className="content">
                    <p className="subtitle">Orders for Checkout</p>
                    <h1 className="statistic">
                      {this.state.checkoutOrders.length}
                    </h1>
                  </div>
                </div>
                {/* <h1
                  className="order_total_dash"
                  style={{ fontFamily: "landingFontTwo" }}
                >
                  Total Orders: {this.state.totalCount}
                </h1> */}

                {/* CGECK!!! CHeCK!!! <div className="rating_card card_dash">
                  <img
                    src={`${process.env.PUBLIC_URL}/dashboard/Rating.png`}
                    alt=""
                    className="bubble_img"
                  />
                  <div className="content">
                    <p className="subtitle">Rating</p>
                    <h1 className="statistic">4.7</h1>
                  </div>
                </div> */}
              </div>

              <div className="flex_cont  flex_cont_dash">
                {/* FILTER BY DATE */}
                <div className="delivery_date">
                  {this.state.filterSearch === ""
                    ? "Select Delivery Date"
                    : dateMap[this.state.filterSearch]}
                  {this.state.hidden ? (
                    <i
                      className="fas fa-angle-down"
                      onClick={this.showDropDown}
                    ></i>
                  ) : (
                    <i
                      className="fas fa-angle-up"
                      onClick={this.hideDropDown}
                    ></i>
                  )}
                  <div
                    className={
                      this.state.hidden ? "drop_down hidden" : "drop_down"
                    }
                  >
                    <div className="" onClick={this.updateFilter}>
                      All
                    </div>
                    <div className="Mon" onClick={this.updateFilter}>
                      Monday
                    </div>
                    <div className="Tue" onClick={this.updateFilter}>
                      Tuesday
                    </div>
                    <div className="Wed" onClick={this.updateFilter}>
                      Wednesday
                    </div>
                    <div className="Thu" onClick={this.updateFilter}>
                      Thursday
                    </div>
                    <div className="Fri" onClick={this.updateFilter}>
                      Friday
                    </div>
                    <div className="Sat" onClick={this.updateFilter}>
                      Saturday
                    </div>
                    <div className="Sun" onClick={this.updateFilter}>
                      Sunday
                    </div>
                  </div>
                </div>
                <div className="delivery_date">
                  {this.state.time === "" || !this.state.time
                    ? "Select Dispatch Time"
                    : this.convertTime(this.state.time, 1, false)}
                  {this.state.hiddenTwo ? (
                    <i
                      className="fas fa-angle-down"
                      onClick={this.showDropDownTwo}
                    ></i>
                  ) : (
                    <i
                      className="fas fa-angle-up"
                      onClick={this.hideDropDownTwo}
                    ></i>
                  )}
                  <div
                    className={
                      this.state.hiddenTwo ? "drop_down hidden" : "drop_down"
                    }
                  >
                    <div className="" onClick={this.updateFilterTwo}>
                      All
                    </div>
                    <div className="10" onClick={this.updateFilterTwo}>
                      10am - 11am
                    </div>
                    <div className="12" onClick={this.updateFilterTwo}>
                      12pm - 1pm
                    </div>
                    <div className="14" onClick={this.updateFilterTwo}>
                      2pm - 3pm
                    </div>
                    <div className="16" onClick={this.updateFilterTwo}>
                      4pm - 5pm
                    </div>
                    <div className="18" onClick={this.updateFilterTwo}>
                      6pm - 7pm
                    </div>
                    <div className="20" onClick={this.updateFilterTwo}>
                      8pm - 9pm
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* START OF ORDER LISTINGS */}
            <p className="note dashboard_note" style={{ fontSize: "10px" }}>
              *Best viewed in landscape if using smartphone <br />
              *refresh periodically and check phone SMSs for new orders.
              Eventually orders will be automatically updated.
            </p>
            {/* TEST DEV NEW UI*/}

            {this.state.view ? (
              <div className="gray_bg">
                <h1 className="pending_title">Pending Orders</h1>
                <div className="flex_cont">
                  <div
                    className="btn_dash"
                    onClick={async () => {
                      await this.setState({ sort: !this.state.sort });
                      this.axiosPendingOrders();
                    }}
                  >
                    {this.state.sort ? "Normal Sorting" : "Sort by Zip Code"}
                  </div>
                  <div
                    className="btn_dash"
                    onClick={() => {
                      this.setState({ view: !this.state.view });
                    }}
                  >
                    Switch View
                  </div>
                </div>
                <div className="flex_cont flex_wrap space_evenly">
                  {this.state.pendingOrders.map((order, index) => {
                    return (
                      <div
                        className={
                          order.confirmed
                            ? "order_cont green_b"
                            : order.confimed === false
                            ? "order_cont red_b"
                            : "order_cont"
                        }
                        style={{ padding: "20px" }}
                      >
                        <div
                          className="group_order top_override_100"
                          onClick={() => {
                            if (!this.state.group)
                              this.setState({ group: order });
                            if (this.state.group && !this.state.groupModal) {
                              this.addToGroup(order, index);
                            }
                          }}
                        >
                          {!this.state.groupLoading ? (
                            this.state.group ? (
                              "GROUP"
                            ) : (
                              "ADD TO GROUP"
                            )
                          ) : (
                            <Loader
                              type="Puff"
                              color="#FFA500"
                              height={20}
                              width={20}
                              timeout={3000} //3 secs
                            />
                          )}
                        </div>
                        <p
                          style={{
                            fontSize: "15px",
                            fontFamily: "landingFontTwo",
                          }}
                        >
                          <b>Order #{order._id}</b>
                          <p>ZIP CODE: {order.zip ? order.zip : "N/A"}</p>
                        </p>
                        <div
                          className="flex_cont align-top"
                          style={{
                            fontFamily: "landingFontTwo",
                            fontSize: "15px",
                            color: "gray",
                          }}
                        >
                          <div className="info_gen">
                            <p style={{ marginTop: "10px" }}>
                              <b style={{ color: "black" }}>
                                {" "}
                                {order.actualDate}{" "}
                              </b>{" "}
                              at{" "}
                              <b style={{ color: "black" }}>
                                {this.convertTime(
                                  order.selectedTime,
                                  1,
                                  order.deliveryMode === "delivery"
                                )}
                              </b>
                            </p>
                            <p style={{ marginTop: "10px" }}>
                              <b style={{ color: "black" }}>
                                {order.deliveryMode.toUpperCase()}
                              </b>
                            </p>
                          </div>
                          <div
                            className="customer_det"
                            style={{ marginBottom: "20px" }}
                          >
                            {order.name ? (
                              <p style={{ marginTop: "10px" }}>
                                Name: {this.capitalizeAllLetter(order.name)}
                              </p>
                            ) : null}
                            {order.deliveryMode === "pickup" ? (
                              <p style={{ marginTop: "10px" }}>{order.phone}</p>
                            ) : null}
                          </div>
                        </div>
                        {order.dishList.map((dish) => {
                          return (
                            <div className="dish_listing_cont flex_cont">
                              <img
                                src={dish.dish.photo}
                                alt={dish.dish.name}
                                className="small_image"
                              />
                              <div
                                className="text_wrapper_order"
                                style={{
                                  fontFamily: "landingFontTwo",
                                  minWidth: "170px",
                                  fontSize: "20px",
                                }}
                              >
                                {dish.dish.name ? (
                                  <p style={{ marginBottom: "8px" }}>
                                    <b>
                                      {this.capitalizeAllLetter(dish.dish.name)}
                                    </b>
                                  </p>
                                ) : null}
                                {dish.note ? (
                                  <p
                                    style={{
                                      fontSize: "15px",
                                    }}
                                  >
                                    <b style={{ textDecoration: "underline" }}>
                                      {" "}
                                      ASSORTMENT:{" "}
                                    </b>
                                    <i style={{ fontSize: "13px" }}>
                                      {dish.note}
                                    </i>
                                  </p>
                                ) : null}
                                {dish.spiceLevel ? (
                                  <p style={{ fontSize: "15px" }}>
                                    <b style={{ textDecoration: "underline" }}>
                                      Spice Level:{" "}
                                    </b>
                                    {dish.spiceLevel} / 4
                                  </p>
                                ) : null}
                                <div
                                  className="flex_cont"
                                  style={{ fontFamily: "landingFontTwo" }}
                                >
                                  <p
                                    className="small_text"
                                    style={{
                                      fontSize: "15px",
                                      marginBottom: "8px",
                                    }}
                                  >
                                    <b>{dish.dish.price / dish.quantity}P</b>
                                  </p>
                                  <p
                                    className="small_text"
                                    style={{ fontSize: "15px" }}
                                  >
                                    <b>Qty: {dish.quantity}</b>
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <hr
                          style={{
                            width: "80%",
                            marginLeft: "20%",
                            color: "gray",
                            borderWidth: "1px",
                          }}
                        ></hr>
                        {order.note ? (
                          <div
                            style={{
                              fontFamily: "landingFontTwo",
                              paddingTop: "10px",
                              textAlign: "right",
                              width: "80%",
                              left: "20%",
                              position: "relative",
                            }}
                          >
                            {" "}
                            <b>Customer Note: {order.note}</b>
                          </div>
                        ) : null}
                        {this.state.confirmLoading === index ? (
                          <Loader
                            type="Puff"
                            color="#FFFF00"
                            height={20}
                            width={20}
                            timeout={3000} //3 secs
                          />
                        ) : order.confirmed === undefined ? (
                          <div
                            className="flex_cont"
                            style={{ padding: "20px" }}
                          >
                            <Modal
                              set={this.set}
                              confirm={this.confirmOrder}
                              status={true}
                              index={index}
                              // deliveryError={this.state.deliveryError === index}
                              confirmed={order.confirmed}
                            />
                            <Modal
                              set={this.set}
                              reject={this.rejectOrder}
                              status={false}
                              index={index}
                              confirmed={order.confirmed}
                            />
                          </div>
                        ) : (
                          <div
                            className="order_status"
                            style={{ padding: "20px" }}
                          >
                            <Button
                              color={order.confirmed ? "primary" : "danger"}
                            >
                              {order.confirmed ? "Confirmed" : "Rejected"}
                            </Button>
                          </div>
                        )}
                        {/* <div className="flex_cont flex_wrap">
                      <div className="bubble flex good_bubble">
                        <div>
                          <i class="fas fa-check"></i>
                          <p style={{ color: "yellowgreen" }}>CONFIRM</p>
                        </div>
                      </div>
                      <div className="bubble flex bad_bubble">
                        <div>
                          <i class="fas fa-times"></i>
                          <p style={{ color: "red" }}> REJECT</p>
                        </div>
                      </div>
                    </div> */}
                        {/* <div className="finish_bubble flex align-center">
                      {this.state.deliveryMode
                        ? `Finished: Request ${order.deliveryMode}`
                        : `Finished: Notify for ${order.deliveryMode}`}
                    </div> */}
                        <div className="finish_bubble flex align-center">
                          {!order.finished ? (
                            this.state.loadingFinished === index ? (
                              <Loader
                                type="Puff"
                                color="#FFFF00"
                                height={20}
                                width={20}
                                timeout={3000} //3 secs
                              />
                            ) : (
                              <div
                                // className="finished_listing"
                                onClick={() => this.finishedCooking(index)}
                              >
                                {this.state.deliveryMode
                                  ? `Finished: Request ${order.deliveryMode}`
                                  : `Finished: Notify for ${order.deliveryMode}`}
                              </div>
                            )
                          ) : (
                            <div>
                              {this.state.deliveryMode
                                ? "Notification & Delivery Complete"
                                : "Notification Complete"}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="pending">
                <div
                  className="btn_dash"
                  onClick={() => {
                    this.setState({ view: !this.state.view });
                  }}
                >
                  Switch View
                </div>
                <div
                  className="btn_dash"
                  onClick={async () => {
                    await this.setState({ sort: !this.state.sort });
                    this.axiosPendingOrders();
                  }}
                >
                  {this.state.sort ? "Sort by Oldest" : "Sort by Zip Code"}
                </div>
                <div className="fixed_pos">
                  <h1 className="pending_title">Pending Orders</h1>
                  <div className="pending_fields scroll">
                    <p className="field">Order ID</p>
                    <p className="field">
                      Dish & <b>(Quantity)</b>
                    </p>
                    <p className="field deliv_field">
                      Expected Completion Date
                    </p>
                    <p className="field cust_dets">Customer Details</p>
                    <p className="field conf_ord">Confirm Order</p>
                    <p className="field not_comp">Notify On Completion</p>
                    <p className="field">Type of Delivery & Payment</p>
                    <p className="field payed_field">ZIP CODE</p>
                  </div>
                </div>
                {/* LISTINGS FUNCTIONALITY HERE */}
                {this.state.pendingOrders.map((order, index) => {
                  {
                    // // console.log(order.orderId);
                  }
                  return (
                    <div
                      className={
                        order.confirmed === true
                          ? "pending_row green_b"
                          : order.confirmed === false
                          ? "pending_row red_b"
                          : "pending_row"
                      }
                      key={index}
                    >
                      <div
                        className="group_order"
                        onClick={() => {
                          if (!this.state.group)
                            this.setState({ group: order });
                          if (this.state.group && !this.state.groupModal) {
                            this.addToGroup(order, index);
                          }
                        }}
                      >
                        {!this.state.groupLoading ? (
                          this.state.group ? (
                            "GROUP"
                          ) : (
                            "ADD TO GROUP"
                          )
                        ) : (
                          <Loader
                            type="Puff"
                            color="#FFA500"
                            height={20}
                            width={20}
                            timeout={3000} //3 secs
                          />
                        )}
                      </div>
                      <p className="fields order_id">{order._id}</p>{" "}
                      {/*{order.orderId}*/}
                      <div className="fields dish_wrapper">
                        {order.dishList.map((dish, i) => {
                          return (
                            <div
                              className=" single_dish"
                              key={`${dish._id}${index}${i}`}
                            >
                              <p className="dish_listing">
                                {this.capitalizeFirstLetter(dish.dish.name)}
                              </p>
                              <p className="quantity_listing">
                                <b>({dish.quantity})</b>
                              </p>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        className="fields eta_listing"
                        style={{ fontFamily: "landingFontTwo" }}
                      >
                        {order.actualDate.toUpperCase()} at{" "}
                        <b>
                          {this.convertTime(
                            order.selectedTime,
                            1,
                            order.deliveryMode === "delivery"
                          )}
                        </b>
                      </div>
                      <div
                        className="fields customer_details"
                        style={{ fontFamily: "landingFontTwo" }}
                        onClick={() => this.setState({ showPopup: order })}
                      >
                        See Details
                      </div>
                      {/* {// console.log(order.confirmed)} */}
                      {this.state.confirmLoading === index ? (
                        <Loader
                          type="Puff"
                          color="#FFFF00"
                          height={20}
                          width={20}
                          timeout={3000} //3 secs
                        />
                      ) : order.confirmed === undefined ? (
                        <div className="fields order_status flex_cont">
                          <Modal
                            set={this.set}
                            confirm={this.confirmOrder}
                            status={true}
                            index={index}
                            // deliveryError={this.state.deliveryError === index}
                            confirmed={order.confirmed}
                          />
                          <Modal
                            set={this.set}
                            reject={this.rejectOrder}
                            status={false}
                            index={index}
                            confirmed={order.confirmed}
                          />
                        </div>
                      ) : (
                        <div className="fields order_status">
                          <Button
                            color={order.confirmed ? "primary" : "danger"}
                          >
                            {order.confirmed ? "Confirmed" : "Rejected"}
                          </Button>
                        </div>
                      )}
                      <div className="fields order_status">
                        {!order.finished ? (
                          this.state.loadingFinished === index ? (
                            <Loader
                              type="Puff"
                              color="#FFFF00"
                              height={20}
                              width={20}
                              timeout={3000} //3 secs
                            />
                          ) : (
                            <div
                              className="finished_listing"
                              onClick={() => this.finishedCooking(index)}
                            >
                              {this.state.deliveryMode
                                ? `Finished: Request ${order.deliveryMode}`
                                : `Finished: Notify for ${order.deliveryMode}`}
                            </div>
                          )
                        ) : (
                          <div className="finished_listing">
                            {this.state.deliveryMode
                              ? "Notification & Delivery Complete"
                              : "Notification Complete"}
                          </div>
                        )}
                      </div>
                      <div
                        className="fields"
                        style={{ fontFamily: "landingFontTwo" }}
                      >
                        {order.deliveryMode}
                        {/* <b> ({order.digital ? "Digital" : "COD"})</b> */}
                      </div>
                      <div className="fields order_status">
                        <div className="cooking_listing">
                          {order.zip ? <p>{order.zip}</p> : <p>N/A</p>}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {/* COMPLETED ORDERS */}
            {/* {// console.log(this.state.loadingDelivery)} */}
            {this.state.loadingDelivery ? (
              <Loader
                type="Puff"
                color="#FFFF00"
                height={20}
                width={20}
                // timeout={3000} //3 secs
              />
            ) : (
              <div className="completed pending">
                <div className="flex_cont cont_button_dash">
                  <h1 className="checkout_title">
                    Orders for Checkout (last 30)
                  </h1>
                  <Dropdown
                    isOpen={this.state.dropdownOpen}
                    toggle={() => {
                      this.setState({ dropdownOpen: !this.state.dropdownOpen });
                    }}
                  >
                    <DropdownToggle caret>
                      {`ORDER STATUS: ${type}`.toUpperCase()}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={async () => {
                          await this.setState({ pending: false });
                          this.axiosCheckoutOrders();
                        }}
                      >
                        <i class="fas fa-money-bill-wave-alt"></i>{" "}
                        <span>ANY</span>
                      </DropdownItem>
                      <DropdownItem
                        onClick={async () => {
                          await this.setState({ pending: true });
                          this.getOrderList();
                        }}
                      >
                        <i class="fas fa-motorcycle"></i>{" "}
                        <span>Awaiting Confirmation</span>
                      </DropdownItem>
                      {/* <DropdownItem
                      onClick={async () => {
                        await this.setState({ searchType: null });
                        this.axiosCheckoutOrders();
                      }}
                    >
                      <i className="fas fa-wallet bootstrap_icon"></i>
                      <span>Both</span>
                    </DropdownItem> */}
                    </DropdownMenu>
                  </Dropdown>
                </div>
                {this.state.pending ? (
                  <p className="special_note_dash">
                    If your delivery order was created greater than 15min in the
                    past and hasn't been confirmed, or you need to message your
                    customer urgently, message me at masterchef@tizaar.com or at
                    +639952521699 with the deliveryId, and tell me what the
                    issue was! I'll sort it out within a few minutes notice :)
                    If you feel the issue can be solved through simply
                    re-creating the delivery order, feel free to do that by
                    clicking the button to the very right!
                  </p>
                ) : null}
                <div className="pending_fields scroll">
                  <p className="field order_id_field">Order ID</p>
                  <p className="field">Type of Delivery</p>
                  <p className="field">Customer Details</p>
                  {this.state.pending ? (
                    <p className="field">Timestamp</p>
                  ) : null}
                  {this.state.pending ? (
                    <p className="field customer_eta_time">Customer ETA</p>
                  ) : null}
                  <p className="field">Delivery ID</p>{" "}
                  <div className="field_dish_wrapper">
                    <p className="field dishhh">Dish</p>
                    <p className="field">Quantity</p>
                    <p className="field">Total Revenue</p>
                  </div>
                  {/* TEST THIS */}
                  {/* <p className="field">Re-create Delivery</p> */}
                </div>
                {/* LISTINGS FUNCTIONALITY HERE */}

                {this.state.checkoutOrders.map((order, i) => {
                  return (
                    <div className="order_section scroll" key={i}>
                      <p className="order_id_comp fields order_checkout_tab">
                        {order._id}
                      </p>
                      <p className="total_price fields align-left">
                        {order.deliveryMode}{" "}
                        {/* <b> ({order.digital ? "digital" : "COD"})</b> */}
                      </p>
                      <div
                        className="fields customer_details"
                        style={{ fontFamily: "landingFontTwo" }}
                        onClick={() => this.setState({ showPopup: order })}
                      >
                        See Details
                      </div>
                      {this.state.pending ? (
                        <p className="fields">{order.duration}</p>
                      ) : null}
                      {this.state.pending ? (
                        <p className="fields">
                          {this.convertTime(
                            order.selectedTime,
                            2,
                            order.deliveryMode === "delivery"
                          )}
                        </p>
                      ) : null}
                      <p className="fields">
                        {order.deliveryMode === "pickup"
                          ? "N/A"
                          : order.deliveryId}
                      </p>
                      {/* make this occupt like 10% and then justify content rest */}
                      <div className="wrapper_dish">
                        {order.dishList.map((dish, i) => {
                          return (
                            <div
                              className="single_dish"
                              key={`${dish._id}${i}${i}`}
                            >
                              <p className="dish_listing fields align-left">
                                {this.capitalizeFirstLetter(dish.dish.name)}
                              </p>
                              <p className="quantity_listing_complete fields align-left">
                                {dish.quantity}{" "}
                                {dish.quantity > 1 ? "Orders" : "Order"}
                              </p>
                              <p className="total_price fields align-left">
                                {dish.dish.price}P
                              </p>
                            </div>
                          );
                        })}
                      </div>
                      {/* TEST THIS */}
                      {/* {order.deliveryMode === "pickup" ? (
                        <p className="fields">N/A</p>
                      ) : (
                        <div
                          className="fields recreate_button_deliv"
                          onClick={() => {
                            if (!this.state.loadingFinishedC)
                              this.recreateOrder(order, i);
                          }}
                        >
                          {this.state.loadingFinishedC ? (
                            <Loader
                              type="Puff"
                              color="#FFA500"
                              height={20}
                              width={20}
                            />
                          ) : (
                            <p>Recreate Delivery</p>
                          )}
                        </div>
                      )} */}
                    </div>
                  );
                })}
              </div>
            )}
            <div className="TOTAL_PURCHASE">
              {/* <h1 style={{ fontFamily: "landingFontTwo", padding: "40px" }}>
                Monthly Revenue So Far: {this.state.monthlyR} PHP
              </h1> */}
              {/* <h1 style={{ fontFamily: "landingFontTwo", padding: "40px" }}>
                Digital Payments Due: {this.state.digitalDue} PHP
              </h1> */}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="fullheight">
          <div
            className="chef_float"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/landing/chef.png)`,
            }}
          ></div>
        </div>
      );
    }
  }
}

export default dashboard;
