import React, { Component } from "react";
// DON'T FORGET ADD AND DELETE DISH Ordertoday bubble.
import "./dishInput.css";
import FormCreate from "./formCreate";
import axios from "axios";
import DateLogic from "../../util/dateFunctions";

const monthMap = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

const dateIndexMapRe = {
  0: "Sun",
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
};

const dateMap = {
  Sun: 0,
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
};

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return `${monthMap[date.getMonth()]} ${date.getUTCDate()}`;
};

export class dishInput extends Component {
  state = {
    showEditPopup: false,
    dish: { ...this.props.dish },
    selectedDate: null,
    todayClicked: false,
    loadingPhoto: Date.now(),
  };
  //if we don't want changes to persist still update state but put on display props. Then on submit send message saying props updated. Then update state in parent.
  iconMap = {
    spice: (
      <i
        className="fas fa-pepper-hot"
        title="spicy"
        style={{ color: "red" }}
        title="spicy"
        key="spicy"
      ></i>
    ),
    vegetarian: (
      <i
        className="fas fa-leaf"
        title="vegetarian"
        style={{ color: "green" }}
        title="vegetarian"
        key="vegetarian"
      ></i>
    ),
    group: (
      <i
        className="fas fa-users"
        style={{ color: "#fcdb03" }}
        title="group promo"
        key="group promo"
      ></i>
    ),
    nuts: (
      <img
        src={`${process.env.PUBLIC_URL}/menu/nuts.png`}
        style={{ width: "20px", height: "20px" }}
        alt=""
        title="nuts"
        key="nuts"
      />
    ),
    glutenFree: (
      <img
        src={`${process.env.PUBLIC_URL}/menu/wheat.png`}
        style={{ width: "20px", height: "20px" }}
        alt=""
        key="gluten"
        data-tooltip="GLUTEN FREE"
      />
    ),
    "sugar-free": (
      <img
        src={`${process.env.PUBLIC_URL}/menu/sugar-free.png`}
        style={{ width: "16px", height: "16px" }}
        alt=""
        key="sugar-free"
        data-tooltip="SUGAR-FREE"
      />
    ),
    vegan: (
      <img
        src={`${process.env.PUBLIC_URL}/menu/vegan.svg`}
        style={{ width: "20px", height: "20px" }}
        alt=""
        title="vegan"
        key="vegan"
      />
    ),
    dairy: (
      <img
        src={`${process.env.PUBLIC_URL}/menu/dairy.svg`}
        style={{ width: "20px", height: "20px" }}
        title="dairy"
        alt=""
        key="dairy"
      />
    ),
  };

  dateIndexMap = {
    Mon: 0,
    Tue: 1,
    Wed: 2,
    Thu: 3,
    Fri: 4,
    Sat: 5,
    Sun: 6,
  };

  dateIndexMapR = {
    0: "Sun",
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat",
  };

  componentDidUpdate(prevProps) {
    if (this.props.dish != prevProps.dish) {
      this.setState({ dish: { ...this.props.dish } });
    }
  }

  removeDish = () => {
    var confirm = window.confirm(
      "Are you sure you want to remove this dish? If possible please try and remove a dish exactly one week in advance. People who have this dish in their cart may get confused when their order does not go through"
    );
    if (confirm) {
      axios({
        method: "delete",
        url: `${
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_URL
            : "http://localhost:8080"
        }/api/v1/dishes/${this.state.dish.id}`,
        withCredentials: true,
      }).then((response) => {
        if (response.data.status === "success") {
          // console.log("Dish was succesfully deleted");
          this.props.remove(this.props.index);
        } else {
          alert(
            "There was an error in deleting the dish, please contact masterchef@tizaar.com"
          );
        }
      });
    } else {
      return;
    }
  };

  updateDish = async (dish) => {
    //close popup after save!
    await this.setState({ dish });
    //send patch request and update props?
    await this.props.updateDish(dish, this.props.index);
    await this.setState({ showEditPopup: false, loadingPhoto: Date.now() });
  };

  setEdit = () => {
    this.setState({ showEditPopup: false });
  };

  selectedDate = (date) => {
    if (date === this.state.selectedDate) {
      this.setState({ selectedDate: null });
      return;
    }
    this.setState({ selectedDate: date, todayClicked: false });
  };

  getLastTimeBefore = () => {
    var time = 10;
    // console.log(this.state.selectedDate);
    var date = this.state.selectedDate;
    if (this.state.todayClicked) date = dateIndexMapRe[new Date().getDay()];
    this.props[`deliveryTimes${date}`].forEach((t) => {
      if (t != null) {
        time = t;
      }
    });
    // console.log(new Date().getDay());
    // console.log("TUE TIME: " + time);
    // console.log(new Date().getHours());
    return time;
  };

  capitalizeFirstLetter = (string) => {
    let str = string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
    return str;
  };

  render() {
    // // console.log(this.props.dish, this.props.index);
    // // console.log("STATE");
    // // console.log(this.state.dish);
    return (
      <div className="menu_dish_card">
        {(!this.state.selectedDate && !this.state.todayClicked) ||
        (this.state.todayClicked &&
          this.props.dish.todayStock &&
          this.getLastTimeBefore() > new Date().getHours()) || //what if today stock! NOTE
        (this.state.dish.stockQuantity[
          this.dateIndexMap[this.state.selectedDate]
        ] &&
          !(
            this.state.dish.dailyPrep &&
            this.state.selectedDate != "Tod" &&
            DateLogic.returnNewDate(
              this.state.selectedDate,
              false,
              this.state.dish.dailyPrep
            ) !==
              DateLogic.returnNewDate(
                this.state.selectedDate,
                false,
                this.props.dayPrep
              )
          )) ? (
          <div></div>
        ) : (
          <div className="out_of_stock">OUT OF STOCK</div>
        )}
        {!this.state.showEditPopup ? (
          <div
            className="edit_dish_button"
            onClick={() => {
              this.setState({ showEditPopup: true });
            }}
          >
            <i className="far fa-edit"></i>
          </div>
        ) : (
          <FormCreate
            today={this.props.today}
            menuDates={this.props.menuDates}
            dish={this.state.dish}
            showEditPopup={true}
            updateDish={this.updateDish}
            cancelForm={this.setEdit}
            edit={true}
            submissionLoad={this.props.submissionLoad}
            pantry={this.props.pantry}
          />
        )}
        {/* <div className="delete_dish_edit">
          <i className="fas fa-times" onClick={this.removeDish}></i>
          Remove Dish
          {/* Add are you sure popup here. Confirm() method https://www.w3schools.com/jsref/met_win_confirm.asp}
        </div> */}
        {/* GROUP PROMO */}
        {this.state.dish.groupPromo ? (
          <div className="group_dish_banner">
            <b>MEAL KIT/GROUP PROMO</b>
            {/* Add are you sure popup here. Confirm() method https://www.w3schools.com/jsref/met_win_confirm.asp*/}
          </div>
        ) : (
          <div></div>
        )}
        {/* UI FOR DISH IN MENU */}
        <div
          className="menu_dish_photo image"
          style={{
            backgroundImage: `url(${this.state.dish.photo}?${this.state.loadingPhoto})`,
          }}
        >
          <div className="menu_dish_dates">
            {this.state.dish.deliveryDates.map((date) => {
              if (
                date !== null &&
                date.length !== 0 &&
                Array.from(this.props.menuDates).includes(date)
              ) {
                return (
                  <div
                    className={
                      this.state.selectedDate === date &&
                      !this.state.todayClicked
                        ? "date_bubble_menu active_bubble"
                        : "date_bubble_menu"
                    }
                    onClick={() => this.selectedDate(date)}
                    key={date}
                  >
                    <b>{date}|</b>{" "}
                    {DateLogic.returnNewDate(date, false, this.props.dayPrep)}
                  </div>
                );
              } else {
                return;
              }
            })}
          </div>
          {this.props.dish.now && this.props.today ? ( //have to enable now, prices, and ready user field
            <div
              className={
                this.state.todayClicked
                  ? `today_bubble date_bubble_menu active_bubble`
                  : `today_bubble date_bubble_menu`
              }
              onClick={() => {
                this.setState({
                  todayClicked: !this.state.todayClicked,
                  selectedDate: false,
                });
              }}
            >
              TODAY
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="flex_cont_header">
          <h1 className="dish_name">
            {this.capitalizeFirstLetter(this.state.dish.name)}
          </h1>
          <div className="tag_logos">
            {this.state.dish.tags.map((tag) => {
              return this.iconMap[tag];
            })}
            {this.state.dish.groupPromo ? this.iconMap["group"] : <div></div>}
          </div>
        </div>
        <p className="menu_dish_description">{this.state.dish.description}</p>
        {/* {this.state.selectedDate || this.state.todayClicked ? (
          <span className="stock_quantity">
            Stock left:{" "}
            <b>
              {!this.state.todayClicked
                ? this.state.dish.stockQuantity[
                    this.dateIndexMap[this.state.selectedDate]
                  ]
                : this.props.dish.todayStock}
            </b>
          </span>
        ) : (
          <span className="stock_quantity">
            Available stock will appear after date selection{" "}
          </span>
        )} */}
        <p className="serving_size_menu stock_quantity">
          {`${this.state.dish.servingSize} ${this.state.dish.unit}`}
        </p>
        {this.state.dish.weeklyStock ? (
          <p className="serving_size_menu stock_quantity">
            {`WEEKLY STOCK ${this.state.dish.weeklyStock}`}
          </p>
        ) : null}
        {this.state.dish.weeklyStock ? (
          <p className="serving_size_menu stock_quantity">
            {`WEEKLY QTY SO FAR:  ${this.state.dish.weeklyQuantity}`}
          </p>
        ) : null}
        <div className="menu_dish_bottom">
          <div className="flex">
            PRICE:
            <div
              className={
                this.props.promo
                  ? "price_tag_menu strikethrough gray"
                  : "price_tag_menu"
              }
            >
              {this.state.dish.price}.00P
            </div>
            {this.props.promo ? (
              <div className="price_tag_menu" style={{ marginLeft: "10px" }}>
                {Math.round(this.state.dish.price * (1 - this.props.promo))}.00P
              </div>
            ) : null}
          </div>
          <div className="cart edit_cart">
            <i className="fas fa-shopping-cart"></i>
          </div>
        </div>
      </div>
    );
  }
}

export default dishInput;
