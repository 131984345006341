import React, { Component } from "react";
import CuisineFilters, { cuisineFilters } from "./cuisineFilters";
import FeatureFilters from "./featureFilters.jsx";
import MainFilters from "./mainFilters";
// import {NavigationEvents} from 'react-navigation';
import Store from "../../Store";

import $ from "jquery";
// import "../general_bazaar.css"; //ORDER MATTERS!
import "./filtering.css";
// import "../media_bazaar.css";

export class filtering extends Component {
  state = {
    cuisines: [
      {
        fileName: "All.png",
        cuisineName: "All",
      },
      {
        fileName: "dosa.png",
        cuisineName: "South Asian",
      },
      {
        fileName: "hamburger.png",
        cuisineName: "American",
      },
      {
        fileName: "paella.png",
        cuisineName: "Spanish",
      },
      {
        fileName: "Thai.png",
        cuisineName: "Thai",
      },
      {
        fileName: "italian.png",
        cuisineName: "Italian",
      },
      {
        fileName: "Japanese.png",
        cuisineName: "Japanese",
      },
      {
        fileName: "Chinese .png",
        cuisineName: "Chinese",
      },
      {
        fileName: "hummus.png",
        cuisineName: "Middle Eastern",
      },
      {
        fileName: "pig.svg",
        cuisineName: "Filipino",
      },
      {
        fileName: "Bibimbap.svg",
        cuisineName: "Korean",
      },
      {
        fileName: "Mexican.png",
        cuisineName: "Mexican",
      },
      {
        fileName: "instant-noodles.png",
        cuisineName: "Vietnamese",
      },
    ],
    baker: false,
    deserts: [
      {
        fileName: "All.png",
        cuisineName: "All",
      },
      {
        fileName: "cookies.png",
        cuisineName: "Cookies",
      },
      {
        fileName: "cake.png",
        cuisineName: "Cakes",
      },
      {
        fileName: "bread.png",
        cuisineName: "Breads",
      },
      {
        fileName: "donut.png",
        cuisineName: "Donuts",
      },
      {
        fileName: "pie.png",
        cuisineName: "Pie",
      },
      {
        fileName: "sushi.png",
        cuisineName: "Baked Sushi",
      },
      {
        fileName: "macaroons.png",
        cuisineName: "Macaroons",
      },
      {
        fileName: "hat.png",
        cuisineName: "Creative",
      },
    ],
    pantryA: [
      {
        fileName: "All.png",
        cuisineName: "All",
      },
      {
        fileName: "sauces.png",
        cuisineName: "Sauces and Spreads",
      },
      {
        fileName: "dairy.png",
        cuisineName: "Dairy",
      },
      {
        fileName: "vegetable.png",
        cuisineName: "Fresh",
      },
      {
        fileName: "baking.png",
        cuisineName: "Batters and Doughs",
      },
      {
        fileName: "drink.png",
        cuisineName: "Drinks",
      },
    ],
    mainFeatures: [
      // { fileName: "chef.png", featureName: "Any" },
      { fileName: "chef_b.png", featureName: "All" },
      { fileName: "cooking.png", featureName: "The Cooks" },
      { fileName: "Bakers.png", featureName: "The Bakers" },
      { fileName: "carrot.svg", featureName: "The Pantry" },
    ],
    features: [
      // { fileName: null, featureName: "None" },

      { fileName: "now.svg", featureName: "Today" },
      { fileName: "price.svg", featureName: "price-one" },
      { fileName: "price.svg", featureName: "price-two" },
      { fileName: "price.svg", featureName: "price-three" },

      // { fileName: "immediate.png", featureName: "Immediate Orders" },
      { fileName: null, featureName: "Vegetarian" },
      { fileName: null, featureName: "Vegan" },
      { fileName: null, featureName: "Promo" },
      { fileName: "cloche.png", featureName: "Caterers" },

      // { fileName: null, featureName: "Party Trays" },
    ],
    filterState: this.props.filterState,
    forceRender: false, //whenever toggled render forced
    type: "chefs",
    priceLevel: [],
    pantry: false,
    main: "all",
    cook: false,
  };

  componentDidMount() {
    // const filterSection = $("#filtering_fixed").offset();
    // window.addEventListener("scroll", this.fixMeUp(filterSection));
    this.setState({ main: Store.getMain() });
    console.log("@mount");
    if (Store.getMain() !== this.state.main) {
      this.setState({ main: Store.getMain() });
      this.setBool(Store.getMain());
    }
  }

  componentDidUpdate() {
    // // console.log(this.state.filterState);
    console.log("@update");
    if (this.state.baker || this.state.pantry) {
      document.querySelector(".cuisine_filtering").classList.add("bakers");
    } else {
      document.querySelector(".cuisine_filtering").classList.remove("bakers");
    }
  }

  // fixMeUp = (filterSection) => {
  //   const element = document.getElementById("filtering_fixed");
  //   const foodgrid = document.querySelector(".food-grid");
  //   const note = document.querySelector(".immediate_message");

  //   window.onscroll = () => {
  //     if ($(window).scrollTop() >= filterSection.top) {
  //       note.classList.add("d_none");
  //     } else {
  //       note.classList.remove("d_none");
  //     }
  //   };
  // };
  // remove = (array) ={

  // }

  addFeature = (filterName, filterState) => {
    filterName = filterName.toLowerCase();
    let type = this.state.type === "dishes";
    let priceLevelArray = [...this.state.priceLevel];
    if (filterName === "price-one") {
      if (type) {
        filterState["price[lte]"] = 250;
      } else {
        // filterState["averagePrice[lte]"] = 250;
        priceLevelArray.push(1);
        filterState.priceLevel = priceLevelArray;
        this.setState({ priceLevel: priceLevelArray });
      }
    } else if (filterName === "price-two") {
      if (type) {
        filterState["price[lte]"] = 400;
      } else {
        priceLevelArray.push(2);
        filterState.priceLevel = priceLevelArray;
        this.setState({ priceLevel: priceLevelArray });
      }
    } else if (filterName === "price-three") {
      if (type) {
        filterState["price[gte]"] = 401;
      } else {
        priceLevelArray.push(3);
        filterState.priceLevel = priceLevelArray;
        this.setState({ priceLevel: priceLevelArray });
      }
      // } else if (filterName === "any") {
      //   delete filterState.pantry;
      //   delete filterState.baker;
      //   delete filterState.cook;
    } else if (filterName === "the cooks") {
      delete filterState.pantry;
      delete filterState.baker;
      filterState.cook = true;
      // filterState.baker = false; //can either be baker or chef
    } else if (filterName === "all") {
      delete filterState.pantry;
      delete filterState.baker;
      delete filterState.cook;
      // filterState.baker = false; //can either be baker or chef
    } else if (filterName === "the bakers") {
      filterState.baker = true;
      delete filterState.cook;
      delete filterState.pantry;
    } else if (filterName === "the pantry") {
      filterState.pantry = true;
      delete filterState.cook;
      delete filterState.baker;
    } else if (filterName === "today") {
      filterState.today = true;
    } else if (filterName === "meal trays") {
      filterState.meal = true;
    } else if (filterName === "party tray") {
      filterState["servingSize[gte]"] = 5;
    } else {
      filterState[filterName] = true;
    }
    return filterState;
  };

  removeFromArray = (array, val) => {
    let arr = [];
    array.every((v) => {
      if (v === val) {
        return true;
      } else {
        arr.push(v);
      }
    });
    return arr;
  };

  deleteFeature = (filterName, filterState) => {
    console.log("prior to delete", filterName, filterState, Store.getQuery());
    filterName = filterName.toLowerCase();
    let priceLevelArray = [...this.state.priceLevel];
    if (filterName === "price-one") {
      // delete filterState["price[lte]"]; //have to fix this later too
      // delete filterState["price[gte]"];
      let priceLevel = this.removeFromArray(priceLevelArray, 1);
      filterState.priceLevel = priceLevel;
      this.setState({ priceLevel });
      if (priceLevel.length === 0) delete filterState.priceLevel; //doesn't work for or need to take out from ot and make or query
    } else if (filterName === "price-two") {
      let priceLevel = this.removeFromArray(priceLevelArray, 2);
      filterState.priceLevel = priceLevel;
      this.setState({ priceLevel });
      if (priceLevel.length === 0) delete filterState.priceLevel;
    } else if (filterName === "price-three") {
      let priceLevel = this.removeFromArray(priceLevelArray, 3);
      filterState.priceLevel = priceLevel;
      this.setState({ priceLevel });
      if (priceLevel.length === 0) delete filterState.priceLevel;
    } else if (filterName === "today") {
      delete filterState.today;
    } else if (filterName === "meal trays" && this.state.type === "dishes") {
      delete filterState.meal;
    } else if (filterName === "party tray" && this.state.type === "dishes") {
      delete filterState["servingSize[gte]"];
    } else {
      delete filterState[filterName];
    }
    return filterState;
  };

  onFeatureChangeMain = async (featureChange) => {
    // console.log(featureChange);
    this.setBool(featureChange);
    // console.log(" IAM HERE1 ");

    if (featureChange !== this.state.main) {
      // console.log(" IAM HERE");
      let filterState = { ...this.state.filterState };
      delete filterState.cuisine;
      await this.setState({ filterState });
    }
    Store.setMain(featureChange);
    await this.onFeatureChange(true, featureChange);
  };

  onFeatureChange = async (active, featureChange) => {
    //always active for main
    let filterState = { ...this.state.filterState };
    if (Object.keys(Store.getQuery()).length > 0) {
      filterState = Store.getQuery();
    }
    // console.log(active, featureChange);
    if (active) {
      filterState = this.addFeature(featureChange, filterState);
      // filterState.features.push(featureChange);
    } else {
      // if (filterState.features.length === 1) {
      //   filterState.features = [];
      // } else {
      // filterState.features = Array.from(filterState.features).filter(
      //   (e) => e !== featureChange
      // );
      filterState = this.deleteFeature(featureChange, filterState);
    }

    //GENERAL MODIFICATIONS
    if (this.state.type === "chefs") {
      delete filterState["servingSize[gte]"];
      delete filterState["price[lte]"];
      delete filterState.meal;
    }
    if (filterState.cuisine === "All") {
      delete filterState.cuisine;
    }

    if (filterState.pantry) {
      // console.log("I am here");
      delete filterState.baker;
      delete filterState.cook;
    }
    console.log("on feature change", filterState);

    await this.setState({ filterState });
    //  console.log(
    //   "At featureChange, last step before priting final state after clicking bakery. "
    // );
    // console.log("FEATURE CHANGE FILTER STATE: ", filterState);
    this.props.axiosCall(this.state.filterState);
    return;
  };

  onCuisineChange = async (cuisineFilter) => {
    let filterState = { ...this.state.filterState };
    filterState.cuisine = cuisineFilter;
    if (cuisineFilter === "All") delete filterState.cuisine;
    await this.setState({ filterState });
    //  console.log(
    //   "The following hello2 should appear before the print of featureChange."
    // );
    // console.log(`HELLO 2${cuisineFilters.selected_cuisine} & ${cuisineFilter}`);
    console.log("mainnnn", filterState);
    this.props.axiosCall(this.state.filterState);
    return;
  };

  setBool = (featureName) => {
    let feature = featureName.toLowerCase();
    if (feature === "all")
      this.setState({
        main: "All",
        baker: false,
        pantry: false,
        cook: false,
      });
    if (feature === "the bakers")
      this.setState({
        main: "The Bakers",
        baker: true,
        pantry: false,
        cook: false,
      });
    if (feature === "the cooks")
      this.setState({
        main: "The Cooks",
        cook: true,
        pantry: false,
        baker: false,
      });
    if (feature === "the pantry")
      this.setState({
        main: "The Pantry",
        pantry: true,
        baker: false,
        cook: false,
      });
  };

  sendFilter = () => {
    let filterState = { ...this.state.filterState };
    if (filterState.cuisine === "All") {
      delete filterState.cuisine;
    }
    // console.log("PRIOR to SENDING: ", filterState);
    if (!filterState.baker) filterState.baker = false;
    if (!filterState.cook) filterState.cook = false;
    this.props.setFilterState(filterState);
  };

  render() {
    let cuisine1 = [...this.state.cuisines];
    let deserts = [...this.state.deserts];
    deserts.shift();
    let cuisinesMerged = cuisine1.concat(deserts);
    {
      console.log(
        this.state.main.toLowerCase(),
        this.state.baker,
        this.state.pantry,
        this.state.cook
      );
    }
    return (
      <section className="filtering_fixed" id="filtering_fixed">
        <div className="immediate_message">
          *Note that <b>immediate orders</b> are orders to be cooked within 1
          hour
        </div>
        <section
          className="search_filtering_main"
          style={{ marginBottom: "10px" }}
        >
          {this.state.mainFeatures.map((feature, i) => {
            return (
              <MainFilters
                fileName={feature.fileName}
                featureName={feature.featureName}
                onClick={this.onFeatureChangeMain}
                main={this.state.main}
                key={i}
                setMain={this.props.setMain}
              />
            );
          })}
        </section>
        <section className="search_filtering">
          {this.state.features.map((feature, i) => {
            return (
              <FeatureFilters
                fileName={feature.fileName}
                featureName={feature.featureName}
                onChange={this.onFeatureChange}
                setBool={this.setBool}
                key={i}
              />
            );
          })}
        </section>
        <section className="cuisine_filtering">
          {this.state.main.toLowerCase() !== "the bakers"
            ? this.state.main.toLowerCase() !== "the pantry"
              ? this.state.main.toLowerCase() !== "the cooks"
                ? cuisinesMerged.map((cuisine, i) => {
                    // {
                    //   // console.log(cuisine);
                    // }
                    return (
                      <CuisineFilters
                        key={cuisine.fileName}
                        fileName={cuisine.fileName}
                        cuisineName={cuisine.cuisineName}
                        onChange={this.onCuisineChange}
                        key={i}
                        // cuisine={this.props.cuisine}
                      />
                    );
                  })
                : this.state.cuisines.map((cuisine, i) => {
                    // {
                    //   // console.log(cuisine);
                    // }
                    return (
                      <CuisineFilters
                        key={cuisine.fileName}
                        fileName={cuisine.fileName}
                        cuisineName={cuisine.cuisineName}
                        onChange={this.onCuisineChange}
                        key={i}
                        // cuisine={this.props.cuisine}
                      />
                    );
                  })
              : this.state.pantryA.map((cuisine, i) => {
                  // {
                  //   // console.log(cuisine);
                  // }
                  return (
                    <CuisineFilters
                      key={cuisine.fileName}
                      fileName={cuisine.fileName}
                      cuisineName={cuisine.cuisineName}
                      onChange={this.onCuisineChange}
                      // cuisine={this.props.cuisine}
                      key={i}
                    />
                  );
                })
            : this.state.deserts.map((cuisine, i) => {
                // {
                //   // console.log(cuisine);
                // }
                return (
                  <CuisineFilters
                    fileName={cuisine.fileName}
                    cuisineName={cuisine.cuisineName}
                    onChange={this.onCuisineChange}
                    // cuisine={this.props.cuisine}
                    key={i}
                  />
                );
              })}
        </section>
        {/* <div className="dish_homechef">
          <p
            style={{ fontFamily: "landingFontTwo" }}
            className={this.state.type === "chefs" ? "active_select para" : "para"}
            onClick={() => {
              this.setState({ type: "chefs" });
              this.props.setType("chefs");
            }}
          >
            View&Search by Homechef
          </p>
          <p
            style={{ fontFamily: "landingFontTwo" }}
            className={this.state.type === "dishes" ? "active_select para" : "para"}
            onClick={() => {
              this.setState({ type: "dishes" });
              this.props.setType("dishes");
            }}
          >
            View&Search by Dish
          </p>
        </div> */}
        {/* <div
          className="btn_btn apply_filter actively_searching"
          onClick={this.sendFilter}
        >
          Apply Filters
        </div> */}
      </section>
    );
  }
}

export default filtering;
