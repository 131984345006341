import React, { Component } from "react";
import "./radioButtons.css";

export class radioButtons extends Component {
  render() {
    return (
      <div>
        {Array.from(this.props.selected).includes(this.props.name) ? (
          <input
            type="button"
            className="bubble_input_radio active_radios"
            value={this.props.name}
            onClick={() =>
              this.props.clicked(this.props.name, true, this.props.cap)
            }
          />
        ) : (
          <input
            type="button"
            className="bubble_input_radio"
            value={this.props.name}
            onClick={() =>
              this.props.clicked(this.props.name, false, this.props.cap)
            }
          />
        )}
      </div>
    );
  }
}

export default radioButtons;
