import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";

import "../general_bazaar.css";
import "./foodItem.css";
import "../media_bazaar.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StarRatings from "react-star-ratings";
import background1 from "../../images/b1.jpg";
import background2 from "../../images/b2.jpg";
import background3 from "../../images/b3.jpg";
import background4 from "../../images/b4.jpg";
import background5 from "../../images/b5.jpg";
import Carousell from "./carousell";
import Slider from "react-slick";

// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";

export class foodItem extends Component {
  static counter = -1;
  state = {
    index: 0,
    cuisine: [],
    userPhoto: null,
  };

  componentDidMount() {
    if (foodItem.counter == 4) {
      foodItem.counter = 0;
      this.setState({ index: 0 });
    } else {
      foodItem.counter = foodItem.counter + 1;
      this.setState({ index: foodItem.counter });
    }
    // console.log(foodItem.counter);
  }
  state = {
    background: [
      background1,
      background2,
      background3,
      background4,
      background5,
    ],
  };

  capitalizeFirstLetter = (string) => {
    // // console.log("name", string);
    if (string) {
      let str = string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
      let arr = str.split(" ");
      return arr[0];
    } else return null;
  };

  settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  render() {
    let ratingsQuantity = this.props.data.ratingsQuantity;
    // console.log(
    //   this.props.data.name,
    //   ratingsQuantity,
    //   this.props.data.ratingsAverage,
    //   this.props.data.visible,
    //   this.props.data.finishedUpdating
    // );
    if (
      (ratingsQuantity < 4 ||
        (ratingsQuantity >= 4 && this.props.data.ratingsAverage >= 3.2) || //mainly to ensure well-being // (this.props.type === "dishes"
        this.props.data.visible) &&
      this.props.data.finishedUpdating //ONLY if they allow us to show not their for dishes but doesn't matter
    ) {
      return (
        <div className="food_card">
          {/* <i className="fas fa-chevron-left"></i>
          <i className="fas fa-chevron-right"></i> */}
          <NavLink className="navItem_fix" to={`/menu/${this.props.data.id}`}>
            {this.props.data.promo && this.props.data.promo !== 0 ? (
              <p className="banner_discount">
                <b>{this.props.data.promo * 100}% DISCOUNT</b>
              </p>
            ) : null}
            {/* {this.props.type === "chefs" ? ( */}
            {/* <Carousell dishes={this.props.data.dishes} date={new Date()} /> */}
            <Slider {...this.settings} className="encompass">
              {this.props.data.dishes.length != 0 ? (
                this.props.data.dishes.map((dish) => {
                  return (
                    <img
                      src={dish.photo}
                      alt={dish.name}
                      className="food_card_img_background image"
                      key={dish._id}
                    />
                  );
                })
              ) : (
                <div
                  className="food_card_img_background image"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/landing/chef.png)`,
                    backgroundSize: "contain",
                  }}
                ></div>
              )}
            </Slider>

            {/* ) : (
              <div
                className="food_card_img_background image"
                style={{
                  backgroundImage: `url(${this.props.data.photo})`,
                }}
              ></div>
            )} */}
            {/*  */}
            <div style={{ padding: "7px 15px", marginTop: "8px" }}>
              <div className="flex" style={{ marginTop: "5px" }}>
                <h1 className="title_chef_name">
                  {this.capitalizeFirstLetter(this.props.data.name)}
                </h1>
                {this.props.data.ratingsQuantity < 4 ? (
                  <div className="new_tag_fi">NEW</div>
                ) : null}
              </div>
              <div className="flex_cont">
                <p className="food_cuisine_name">
                  {this.props.data.cuisine.join(", ")}
                </p>
                {/* <div className="flex_cont column align-start"> */}
                <p className="food_cuisine_name">
                  <b>{this.props.data.miniAddress}</b>
                </p>
                {/* </div> */}
              </div>

              <div
                className="chef_image_photo image"
                style={{
                  backgroundImage: `url(${
                    // this.props.type === "chefs"
                    this.props.data.userPhoto
                    // : this.state.userPhoto
                  })`, //`url("../../images/${this.props.data.chefPhoto}")` --> might work in production
                }}
              ></div>
              {this.props.data.ratingsQuantity >= 4 ? (
                <div className="rating">
                  <StarRatings
                    rating={this.props.data.ratingsAverage}
                    starRatedColor="red"
                    numberOfStars={5}
                    name="rating"
                    starDimension="20px"
                    className="rating"
                  />
                  <p className="rating_text">
                    {this.props.data.ratingsAverage} (
                    {this.props.data.ratingsQuantity})
                  </p>
                </div>
              ) : null}
              {/* <div className="rating">
          <i className="fa fa-star" aria-hidden="true"></i>
          <i className="fa fa-star" aria-hidden="true"></i>
          <i className="fa fa-star" aria-hidden="true"></i>
          <i className="fa fa-star" aria-hidden="true"></i>
          <i className="fa fa-star" aria-hidden="true"></i>
        </div> */}
            </div>
          </NavLink>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default foodItem;
