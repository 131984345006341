import React, { Component } from "react";
import "./cuisineFilters.css";
// import "../media_bazaar.css";

export class cuisineFilters extends Component {
  static selected_cuisine = "All";
  // state = {
  //   active_cuisine: "All",
  // };
  componentDidMount() {
    this.setState({ active_cuisine: cuisineFilters.selected_cuisine });
  }

  componentDidUpdate() {
    // console.log(cuisineFilters.selected_cuisine);
  }

  setNewCuisine = () => {
    cuisineFilters.selected_cuisine = this.props.cuisineName;
    // this.setState({ active_cuisine: cuisineFilters.selected_cuisine });
    this.props.onChange(cuisineFilters.selected_cuisine);
    //might need to pass data back to create and force refresh
  };

  render() {
    const fileName = this.props.fileName;
    const icon = require(`../../images/${fileName}`).default;
    // if (this.props.cuisineName === "Mexican") {
    //   style = {
    //     trans,
    //   };
    // }
    return (
      <div
        className={
          cuisineFilters.selected_cuisine === this.props.cuisineName
            ? "card image active_filter_cuisine"
            : "card image"
        }
        onClick={() => this.setNewCuisine()}
      >
        <img
          className={`${this.props.cuisineName}`}
          src={icon} //check for all!
          alt={`${this.props.cuisineName}`}
          style={
            this.props.cuisineName === "Mexican"
              ? {
                  transform: "rotate(45deg) translate(-70%, -10%)",
                }
              : {}
          }
        />
        <p className="cuisine_text wrapword">{this.props.cuisineName}</p>
      </div>
    );
  }
}

export default cuisineFilters;
